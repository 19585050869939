import { atom } from "recoil";

const collapsedState = atom({
  key: "collapsedState",
  default: false,
});

const objectTypesState = atom({
  key: "objectTypesState",
  default: undefined,
});

const teamIdState = atom({
  key: "teamIdState",
  default: undefined,
});

const teamMembersState = atom({
  key: "teamMembersState",
  default: undefined,
});

const profileState = atom({
  key: "profileState",
  default: undefined,
});

const teamsState = atom({
  key: "teamsState",
  default: undefined,
});

const teamState = atom({
  key: "teamState",
  default: undefined,
});

const objectTypeState = atom({
  key: "objectTypeState",
  default: undefined,
});

const wikisState = atom({
  key: "wikisState",
  default: [],
});

export {
  collapsedState,
  objectTypesState,
  teamIdState,
  teamMembersState,
  profileState,
  teamsState,
  teamState,
  objectTypeState,
  wikisState,
};
