import React, { useState } from "react";
import { Modal, Input, Button, message } from "antd";
import axios from "axios";
import AutomationCard from "../components/AutomationCard";
import { MailOutlined } from "@ant-design/icons";

const ConfigureSendGridCard = ({ team }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const [fromEmail, setFromEmail] = useState("");

  const handleSendGridClick = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    if (!apiKey) {
      message.error("Please enter an API key.");
      return;
    }

    if (!fromEmail) {
      message.error("Please enter a 'from' email address.");
      return;
    }

    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/teams/${team.teamId}/sendgrid-key?teamId=${team.teamId}`,
        { apiKey, fromEmail },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      message.success(
        "SendGrid API key and 'from' email address saved successfully!"
      );
      setIsModalVisible(false);
      setApiKey("");
      setFromEmail("");
    } catch (error) {
      console.error(
        "Error saving SendGrid API key and 'from' email address:",
        error
      );
      message.error(
        "Failed to save SendGrid API key and 'from' email address."
      );
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setApiKey("");
    setFromEmail("");
  };

  return (
    <>
      <AutomationCard
        title={
          team && team.sendgridApiKey ? "Update SendGrid" : "Setup SendGrid"
        }
        icon={
          <div
            style={{
              fontSize: 40,
            }}
          >
            ✉️
          </div>
        }
        onClick={handleSendGridClick}
      />

      <Modal
        title={
          team && team.sendgridApiKey
            ? "Update SendGrid API Key"
            : "Setup SendGrid API Key"
        }
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Input.Password
          placeholder="Enter SendGrid API Key"
          value={apiKey}
          onChange={(e) => setApiKey(e.target.value)}
          style={{ marginBottom: 16 }}
        />
        <Input
          placeholder="Enter 'From' Email Address"
          value={fromEmail}
          onChange={(e) => setFromEmail(e.target.value)}
        />
      </Modal>
    </>
  );
};

export default ConfigureSendGridCard;
