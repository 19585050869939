// components/FileManager.js
import React, { useEffect, useState } from "react";
import { Upload, Button, List, Modal, message, Typography } from "antd";
import {
  UploadOutlined,
  DeleteOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import axios from "axios";

const { Text } = Typography;
const { confirm } = Modal;

const FileManager = ({ objectId, teamId }) => {
  const [files, setFiles] = useState([]);

  // Fetch files when the component mounts or the objectId changes
  useEffect(() => {
    if (objectId) {
      fetchFiles();
    }
    // add event listener
    document.addEventListener("onActivityLogUpdate", fetchFiles);
  }, [objectId]);

  // Fetch files from the backend
  const fetchFiles = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/objects/${objectId}/files?teamId=${teamId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setFiles(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching files:", error);
      });
  };

  // Handle file upload
  const handleUpload = (info) => {
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
      fetchFiles();
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  // Confirm file deletion
  const confirmDelete = (fileId) => {
    confirm({
      title: "Are you sure you want to delete this file?",
      icon: <ExclamationCircleOutlined />,
      content: "This action cannot be undone.",
      onOk() {
        handleDelete(fileId);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  // Get signed URL for file download
  const getSignedUrl = (fileId) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/objects/${objectId}/files/${fileId}?teamId=${teamId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        window.open(response.data.url, "_blank");
      })
      .catch((error) => {
        console.error("Error getting signed URL:", error);
        message.error("Error getting signed URL");
      });
  };

  // Handle file deletion
  const handleDelete = (fileId) => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/objects/${objectId}/files/${fileId}?teamId=${teamId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        message.success("File deleted successfully");
        fetchFiles();
      })
      .catch((error) => {
        console.error("Error deleting file:", error);
        message.error("Error deleting file");
      });
  };

  return (
    <div style={{ marginTop: 20 }}>
      <Upload
        action={`${process.env.REACT_APP_API_URL}/objects/${objectId}/files/upload?teamId=${teamId}`}
        headers={{
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }}
        onChange={handleUpload}
        multiple
      >
        <Button icon={<UploadOutlined />}>Upload File</Button>
      </Upload>

      <List
        header={<div>Files</div>}
        bordered
        dataSource={files}
        renderItem={(file) => (
          <List.Item
            actions={[
              <Button
                icon={<DownloadOutlined />}
                type="link"
                onClick={() => {
                  getSignedUrl(file.fileId);
                }}
                target="_blank"
                rel="noopener noreferrer"
              ></Button>,
              <Button
                icon={<DeleteOutlined />}
                type="link"
                onClick={() => confirmDelete(file.fileId)}
                danger
              ></Button>,
            ]}
          >
            <Text>{file.fileName}</Text>
          </List.Item>
        )}
        style={{ marginTop: 10 }}
      />
    </div>
  );
};

export default FileManager;
