import React, { createContext, useContext, useEffect, useState } from "react";
import {
  GoogleOAuthProvider,
  useGoogleLogin,
  googleLogout,
} from "@react-oauth/google";

const AuthContext = createContext();

export const useGoogleAuth = () => useContext(AuthContext);

export const GoogleAuthProviderComponent = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log("Authorization Code:", tokenResponse.code);

      // Send the authorization code to the backend to exchange for tokens
      try {
        const response = await fetch("/auth/google/callback", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ code: tokenResponse.code }),
        });

        if (response.ok) {
          const data = await response.json();
          console.log("Tokens:", data);

          // Save tokens in localStorage or your state management
          localStorage.setItem("googleAuthToken", data.accessToken);
          localStorage.setItem("refreshToken", data.refreshToken);
          setUser(data.user);
        } else {
          console.error("Failed to exchange authorization code for tokens");
        }
      } catch (error) {
        console.error("Error exchanging authorization code:", error);
      }

      setIsAuthenticating(false);
    },
    onError: () => {
      console.error("Login Failed");
      setIsAuthenticating(false);
    },
    flow: "auth-code",
    redirectUri: "http://localhost:3001/auth/google/callback",
  });

  const logout = () => {
    googleLogout();
    setUser(null);
    localStorage.removeItem("googleAuthToken");
    localStorage.removeItem("refreshToken"); // Remove the refresh token
  };

  useEffect(() => {
    // Check if user is already authenticated
    const storedUser = localStorage.getItem("googleAuthToken");
    if (storedUser) {
      setUser(storedUser);
    }
    setIsAuthenticating(false);
  }, []);

  return (
    <AuthContext.Provider value={{ user, login, logout, isAuthenticating }}>
      {children}
    </AuthContext.Provider>
  );
};

export const App = ({ children }) => (
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <GoogleAuthProviderComponent>{children}</GoogleAuthProviderComponent>
  </GoogleOAuthProvider>
);
