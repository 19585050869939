import React, { useEffect, useRef, useState } from "react";
import EditorJS from "@editorjs/editorjs";
import axios from "axios";
import "./Wiki.css";
import { useNavigate, useParams } from "react-router-dom";
import Paragraph from "@editorjs/paragraph";
import { Button, Skeleton, Select } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useRecoilState } from "recoil";
import { wikisState } from "../state";

const { Option } = Select;

const Wiki = () => {
  const editorInstance = useRef(null);
  const [isEditing, setIsEditing] = useState(true);
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState();
  const [folders, setFolders] = useState([]); // State for folders
  const [availableFolders, setAvailableFolders] = useState([]); // State for available folders
  const [wikis, setWikis] = useRecoilState(wikisState);

  const { wikiId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Fetching content for wiki:", wikiId);
    // Clear the editor instance
    if (editorInstance.current) {
      editorInstance.current.destroy();
      editorInstance.current = null;
      setTitle(null);
    }

    setLoading(true);
    setTimeout(() => {
      loadContent();
    }, 100);
  }, [wikiId]);

  async function loadContent() {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/wikis/" + wikiId,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log(response.data.content);
      setContent(response.data.content);
      setTitle(response.data.title);
      setFolders(response.data.folders || []); // Set folders from the response
      setAvailableFolders(response.data.availableFolders || []); // Set available folders

      // get all wikis folders "/wikis/folders/team/:teamId"
      const responseFolders = await axios.get(
        process.env.REACT_APP_API_URL +
          "/wikis/folders/team/" +
          localStorage.getItem("teamId"),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setAvailableFolders(responseFolders.data.folders || []); // Set available folders

      if (isEditing && !editorInstance.current) {
        // Initialize Editor.js when in editing mode
        editorInstance.current = new EditorJS({
          holder: "editorjs",
          tools: {
            header: require("@editorjs/header"),
            list: require("@editorjs/list"),
            quote: require("@editorjs/quote"),
            warning: require("@editorjs/warning"),
            delimiter: require("@editorjs/delimiter"),
            checklist: require("@editorjs/checklist"),
            image: require("@editorjs/image"),
            link: require("@editorjs/link"),
            attaches: require("@editorjs/attaches"),
            embed: require("@editorjs/embed"),
            paragraph: {
              class: Paragraph,
              inlineToolbar: true,
              config: {
                preserveBlank: true,
              },
            },
          },
          readOnly: loading,
          data: response.data.content || {
            blocks: [
              {
                type: "paragraph",
                data: {
                  text: "Start writing...",
                },
              },
            ],
          }, // Use the content fetched from the database as initial data
          onChange: async () => {
            const editorData = await editorInstance.current.save();
            console.log("Changed Data:", editorData);
            setContent(editorData);
            handleSave();
          },
        });
      }
    } catch (error) {
      console.error("Error fetching content:", error);
    }

    setLoading(false);
  }

  const handleSave = async () => {
    const editorData = await editorInstance.current.save();
    console.log("Saving content:", editorData);

    try {
      await axios.put(
        process.env.REACT_APP_API_URL + `/wikis/${wikiId}`,
        {
          content: editorData,
          folders,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    } catch (error) {
      console.error("Error saving wiki:", error);
    }
  };

  const handleSaveTitle = async () => {
    // event to update the title in sidebar
    window.dispatchEvent(
      new CustomEvent("update-wiki-title", { detail: { wikiId, title } })
    );

    try {
      await axios.put(
        process.env.REACT_APP_API_URL + `/wikis/${wikiId}`,
        {
          title: title,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    } catch (error) {
      console.error("Error saving title:", error);
    }
  };

  const handelSaveFolders = async (selectedFolders) => {
    try {
      await axios.put(
        process.env.REACT_APP_API_URL + `/wikis/${wikiId}`,
        {
          folders: selectedFolders,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    } catch (error) {
      console.error("Error saving folders:", error);
    }
  };

  const handleFoldersChange = (selectedFolders) => {
    console.log("Selected Folders:", selectedFolders);
    setFolders(selectedFolders);
    handelSaveFolders(selectedFolders);
  };

  return (
    <div
      style={{
        width: "calc(100%)",
        backgroundColor: "white",
        height: "calc(100vh - 64px)",
        overflow: loading ? "hidden" : "auto",
        borderRadius: 8,
      }}
    >
      <>
        {loading && (
          <div
            style={{
              height: "100vh",
              padding: "20px",
            }}
          >
            <Skeleton active />
          </div>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              flex: 1,
            }}
          >
            <input
              type="text"
              placeholder="Enter title"
              style={{
                width: "100%",
                padding: "10px",
                marginBottom: "10px",
                border: "0px solid #ccc",
                fontSize: "28px",
                paddingLeft: "60px",
                fontWeight: 900,
              }}
              value={title}
              onChange={(e) => {
                console.log("Title changed:", e.target.value);
                setTitle(e.target.value);
              }}
              onBlur={handleSaveTitle}
            />
          </div>

          <div
            style={{ padding: "10px", display: "flex", alignItems: "center" }}
          >
            <Select
              mode="tags"
              style={{ width: 200, marginRight: "10px" }}
              placeholder="Select or add folders"
              value={folders}
              onChange={handleFoldersChange}
            >
              {availableFolders.map((folder) => (
                <Option key={folder} value={folder}>
                  {folder}
                </Option>
              ))}
            </Select>

            <Button
              icon={<DeleteOutlined />}
              onClick={() => {
                axios.delete(
                  process.env.REACT_APP_API_URL + `/wikis/${wikiId}`,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                  }
                );

                window.location.href = "/";
              }}
            ></Button>
          </div>
        </div>

        <div
          id="editorjs"
          style={{
            backgroundColor: "#fff",
            border: "0px solid #ccc",
            padding: "10px",
            minHeight: "400px",
            height: "calc(100vh - 140px)",
            width: "100%",
            marginTop: -8,
            overflow: "auto",
          }}
        ></div>
      </>
    </div>
  );
};

export default Wiki;
