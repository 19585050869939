import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Upload,
  message,
  Select,
  Table,
  Spin,
  Avatar,
  List,
  Collapse,
  Divider,
} from "antd";
import {
  PlusOutlined,
  UploadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { profileState, teamIdState } from "../state";
import AvatarPicker from "../components/AvatarPicker";
import Markdown from "react-markdown";

const { Option } = Select;

const AgentTeamPage = () => {
  const { agentTeamId } = useParams();
  const [loading, setLoading] = useState(false);
  const [manager, setManager] = useState(null);
  const [user, setUser] = useRecoilState(profileState);
  const [messageLoading, setMessageLoading] = useState(false);
  const [teamData, setTeamData] = useState({
    teamName: "",
    avatar: null,
    maxTasks: 1,
    agents: [],
  });
  const [availableAgents, setAvailableAgents] = useState([]);
  const [teamId, setTeamId] = useRecoilState(teamIdState);
  const [messages, setMessages] = useState([]); // Chat history
  const [currentMessage, setCurrentMessage] = useState(""); // Current input message
  const navigate = useNavigate();

  useEffect(() => {
    fetchTeamData();
    fetchAvailableAgents();
  }, []);

  const fetchTeamData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/agents/teams/${agentTeamId}?teamId=${teamId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setTeamData(response.data);
    } catch (error) {
      message.error("Error fetching team data.");
      console.error(error);
    }
    setLoading(false);
  };

  const fetchAvailableAgents = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/agents?teamId=${teamId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setAvailableAgents(response.data.agents);
    } catch (error) {
      message.error("Error fetching available agents.");
      console.error(error);
    }
  };

  const handleSaveChanges = async () => {
    setLoading(true);
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/agents/teams/${teamData.id}?teamId=${teamId}`,
        teamData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      message.success("Team updated successfully!");
      navigate("/agents");
    } catch (error) {
      message.error("Error updating team.");
      console.error(error);
    }
    setLoading(false);
  };

  const handleSendMessage = async () => {
    setMessageLoading(true);
    if (!currentMessage.trim()) return;

    // Add user's message to the chat
    const newMessages = [...messages, { sender: "user", text: currentMessage }];
    setMessages(newMessages);

    try {
      // Send message to the backend and get response
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/agents/teams/${agentTeamId}/interact?teamId=${teamId}`,
        {
          messages: [currentMessage],
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log(response);
      setMessageLoading(false);
      // Append team/agents' response to the chat
      setMessages([
        ...newMessages,
        {
          sender: "team",
          text: response.data.text,
          taskLogs: response.data.taskLogs,
        },
      ]);
    } catch (error) {
      message.error("Error interacting with the team.");
      console.error(error);
    }

    // Clear the input field
    setCurrentMessage("");
  };

  const handleAgentAdd = (agentId) => {
    let ag = [agentId];
    if (teamData && teamData.agents) {
      ag = [...teamData.agents, agentId];
    }

    setTeamData({
      ...teamData,
      agents: ag,
    });
  };

  const handleAgentRemove = (agentId) => {
    setTeamData({
      ...teamData,
      agents: teamData.agents.filter((id) => id !== agentId),
    });
  };

  const handleAvatarChange = (info) => {
    if (info.file.status === "done") {
      setTeamData({ ...teamData, avatar: info.file.response.url });
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const columns = [
    {
      title: "Agent Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Model Type",
      dataIndex: "modelType",
      key: "modelType",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, agent) => (
        <div style={{ display: "flex", gap: 16 }}>
          {teamData.manager === agent.agentId ? (
            <Button size="small" disabled>
              Manager
            </Button>
          ) : (
            <Button
              size="small"
              onClick={() =>
                setTeamData({ ...teamData, manager: agent.agentId })
              }
            >
              Set as Manager
            </Button>
          )}
          <Button
            size="small"
            danger
            onClick={() => handleAgentRemove(agent.agentId)}
          >
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div style={{ display: "flex", flexDirection: "row", gap: 32 }}>
      <div style={{ maxWidth: 600 }}>
        <h1>Manage Team</h1>
        {loading ? (
          <Spin />
        ) : (
          <div>
            Team Name
            <Input
              placeholder="Team Name"
              value={teamData.name}
              onChange={(e) =>
                setTeamData({ ...teamData, name: e.target.value })
              }
              style={{ marginBottom: 16 }}
            />
            Max Tasks
            <Input
              placeholder="Max Tasks"
              type="number"
              value={teamData.maxTasks}
              onChange={(e) =>
                setTeamData({ ...teamData, maxTasks: e.target.value })
              }
              style={{ marginBottom: 16 }}
            />
            <AvatarPicker
              team={true}
              value={teamData.avatar}
              onAvatarSelect={(value) => {
                setTeamData({ ...teamData, avatar: value });
              }}
            />
            <h2>Assigned Agents</h2>
            <Table
              columns={columns}
              dataSource={availableAgents.filter(
                (agent) =>
                  teamData.agents && teamData.agents.includes(agent.agentId)
              )}
              rowKey="agentId"
            />
            <h2>Add New Agent to Team</h2>
            <Select
              placeholder="Select an Agent"
              onChange={handleAgentAdd}
              style={{ width: "100%", marginBottom: 16 }}
            >
              {availableAgents.map((agent) => (
                <Option key={agent.agentId} value={agent.agentId}>
                  {agent.name} ({agent.modelType})
                </Option>
              ))}
            </Select>
            <Button type="primary" onClick={handleSaveChanges}>
              Save Changes
            </Button>
          </div>
        )}
      </div>

      {/* Chat Box Section */}
      <div style={{ flex: 1 }}>
        <h2>Chat with Team</h2>
        <div
          style={{
            border: "1px solid #eaeaea",
            borderRadius: 8,
            padding: 16,
            height: "calc(100vh - 300px)",
            overflowY: "auto",
            marginBottom: 16,
            flex: 1,
            width: "100%",
          }}
        >
          <List
            dataSource={messages}
            renderItem={(item) => (
              <List.Item>
                <div style={{ display: "flex", gap: 16 }}>
                  <div
                    style={{
                      minWidth: 50,
                      height: 50,
                      borderRadius: 8,
                      border: "3px solid #eaeaea",
                      backgroundImage:
                        item.sender === "team"
                          ? `url(/team-avatars/${teamData.avatar}.png)`
                          : `url(${user?.picture})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      marginRight: 16,
                    }}
                  ></div>
                  <div style={{}}>
                    <b>{item.sender === "team" ? "Team: " : "You: "} </b>

                    {item.sender === "team" ? (
                      <div
                        style={{
                          marginTop: 6,
                        }}
                      >
                        {item &&
                          item.taskLogs &&
                          item.taskLogs.length > 0 &&
                          item.taskLogs.map((log) => (
                            <Collapse
                              style={{
                                marginTop: 6,
                              }}
                              items={[
                                {
                                  key: "1",
                                  label:
                                    "🤖 " + log.agent + " - " + log.task.task,
                                  children: <Markdown>{log.text}</Markdown>,
                                },
                              ]}
                            />
                          ))}
                      </div>
                    ) : (
                      <Markdown>{item.text}</Markdown>
                    )}
                  </div>
                </div>
              </List.Item>
            )}
          />
        </div>

        <Input
          placeholder="Type your message here..."
          value={currentMessage}
          onChange={(e) => setCurrentMessage(e.target.value)}
          onPressEnter={handleSendMessage}
          style={{ marginBottom: 16 }}
        />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            loading={messageLoading}
            type="primary"
            onClick={handleSendMessage}
          >
            Send Message
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AgentTeamPage;
