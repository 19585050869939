import React, { useState } from "react";
import { Form, Input, Button, Checkbox, notification } from "antd";
import axios from "axios";

const { TextArea } = Input;

const RegisterAppPage = () => {
  const [loading, setLoading] = useState(false);
  const [configFields, setConfigFields] = useState([
    { name: "", label: "", type: "text", placeholder: "" },
  ]);

  const handleAddField = () => {
    setConfigFields([
      ...configFields,
      { name: "", label: "", type: "text", placeholder: "" },
    ]);
  };

  const handleRemoveField = (index) => {
    const fields = [...configFields];
    fields.splice(index, 1);
    setConfigFields(fields);
  };

  const handleFieldChange = (index, key, value) => {
    const fields = [...configFields];
    fields[index][key] = value;
    setConfigFields(fields);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const payload = {
        ...values,
        configurationFields: configFields,
      };

      await axios.post(
        process.env.REACT_APP_API_URL + "/apps/register",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      notification.success({ message: "App registered successfully!" });
    } catch (error) {
      notification.error({
        message: "Error registering app",
        description: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        padding: "24px",

        borderRadius: "4px",
      }}
    >
      <h2>Register a New App</h2>
      <Form layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          name="name"
          label="App Name"
          rules={[{ required: true, message: "Please enter the app name" }]}
        >
          <Input placeholder="Enter app name" />
        </Form.Item>

        <Form.Item
          name="description"
          label="App Description"
          rules={[
            { required: true, message: "Please enter the app description" },
          ]}
        >
          <TextArea placeholder="Enter app description" rows={4} />
        </Form.Item>

        <Form.Item
          name="apiEndpoint"
          label="API Endpoint"
          rules={[{ required: true, message: "Please enter the API endpoint" }]}
        >
          <Input placeholder="Enter the API endpoint for the app" />
        </Form.Item>

        <Form.Item
          name="teamId"
          label="Team ID"
          rules={[{ required: true, message: "Please enter the Team ID" }]}
        >
          <Input placeholder="Enter the Team ID" />
        </Form.Item>

        <h3>Configuration Fields</h3>
        {configFields.map((field, index) => (
          <div
            key={index}
            style={{
              marginBottom: "16px",
              border: "1px solid #d9d9d9",
              padding: "16px",
              borderRadius: "4px",
            }}
          >
            <Form.Item label="Field Name" required>
              <Input
                placeholder="Field name"
                value={field.name}
                onChange={(e) =>
                  handleFieldChange(index, "name", e.target.value)
                }
              />
            </Form.Item>
            <Form.Item label="Field Label" required>
              <Input
                placeholder="Field label"
                value={field.label}
                onChange={(e) =>
                  handleFieldChange(index, "label", e.target.value)
                }
              />
            </Form.Item>
            <Form.Item label="Field Type" required>
              <Input
                placeholder="Field type (text, checkbox, etc.)"
                value={field.type}
                onChange={(e) =>
                  handleFieldChange(index, "type", e.target.value)
                }
              />
            </Form.Item>
            <Form.Item label="Field Placeholder">
              <Input
                placeholder="Field placeholder"
                value={field.placeholder}
                onChange={(e) =>
                  handleFieldChange(index, "placeholder", e.target.value)
                }
              />
            </Form.Item>
            <Button type="danger" onClick={() => handleRemoveField(index)}>
              Remove Field
            </Button>
          </div>
        ))}
        <Button type="dashed" onClick={handleAddField}>
          Add Configuration Field
        </Button>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Register App
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default RegisterAppPage;
