import React, { useState } from "react";
import {
  CognitoUserPool,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";
import config from "../config";

const SignUp = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [preferredUsername, setPreferredUsername] = useState("");
  const [picture, setPicture] = useState("");
  const [name, setName] = useState("");
  const [nickname, setNickname] = useState("");

  const signUp = () => {
    const userPool = new CognitoUserPool({
      UserPoolId: config.UserPoolId,
      ClientId: config.ClientId,
    });

    const attributeList = [
      new CognitoUserAttribute({ Name: "email", Value: email }),
      new CognitoUserAttribute({
        Name: "preferred_username",
        Value: preferredUsername,
      }),
      new CognitoUserAttribute({ Name: "picture", Value: picture }),
      new CognitoUserAttribute({ Name: "name", Value: name }),
      new CognitoUserAttribute({ Name: "nickname", Value: nickname }),
    ];

    userPool.signUp(username, password, attributeList, null, (err, result) => {
      if (err) {
        console.error(err.message || JSON.stringify(err));
        return;
      }
      console.log("User name is " + result.user.getUsername());
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "300px",
        margin: "auto",
        gap: 10,
      }}
    >
      <h2>Sign Up</h2>
      <input
        type="text"
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />

      <button onClick={signUp}>Sign Up</button>
    </div>
  );
};

export default SignUp;
