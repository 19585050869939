// pages/JoinTeam.js
import React, { useState } from "react";
import { Form, Input, Button, notification } from "antd";
import axios from "axios";

const JoinTeam = ({ onTeamJoined }) => {
  const [loading, setLoading] = useState(false);

  const handleJoinTeam = async (values) => {
    setLoading(true);
    try {
      await axios.post(process.env.REACT_APP_API_URL + "/teams/join", values, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      notification.success({ message: "Joined team successfully!" });
      onTeamJoined(values.teamId);
    } catch (error) {
      notification.error({
        message: "Error joining team",
        description: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form onFinish={handleJoinTeam} layout="vertical">
      <Form.Item
        name="teamId"
        label="Team ID"
        rules={[{ required: true, message: "Please enter the team ID" }]}
      >
        <Input placeholder="Enter team ID" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Join Team
        </Button>
      </Form.Item>
    </Form>
  );
};

export default JoinTeam;
