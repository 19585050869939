import React, { useState } from "react";
import { Button, Input, Select, Space, Form, message, Tag, Modal } from "antd";
import { PlusOutlined, SaveOutlined, CloseOutlined } from "@ant-design/icons";
import axios from "axios";

const { Option } = Select;

const CreateFilter = ({
  fields,
  objectTypeId,
  onAddFilter,
  onRemoveFilter,
  selectedFilters,
}) => {
  const [filters, setFilters] = useState([]);
  const [currentField, setCurrentField] = useState(null);
  const [currentValue, setCurrentValue] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [filterName, setFilterName] = useState("");

  const handleAddFilter = async () => {
    if (!currentField || !currentValue) {
      message.error("Please select a field and enter a value");
      return;
    }

    const newFilter = { field: currentField, value: currentValue };

    await setFilters([...filters, newFilter]);

    setCurrentField(null);
    setCurrentValue("");
    setTimeout(() => {
      onAddFilter([...filters, newFilter]);
    }, 110);
  };

  const handleSaveFilter = async () => {
    if (filters.length === 0) {
      message.error("No filters to save. Please add at least one filter.");
      return;
    }

    setIsModalVisible(true); // Show modal for filter name input
  };

  const handleModalOk = async () => {
    if (!filterName) {
      message.error("Please enter a name for the filter.");
      return;
    }

    try {
      const response = await axios.put(
        `${
          process.env.REACT_APP_API_URL
        }/object-type/${objectTypeId}/filter?teamId=${localStorage.getItem(
          "teamId"
        )}`,
        { filter: { name: filterName, conditions: filters } },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      message.success("Filter saved successfully!");
      setIsModalVisible(false);
      setFilterName(""); // Reset filter name
    } catch (error) {
      console.error("Error saving filter:", error);
      message.error("Failed to save the filter.");
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleRemoveFilter = (indexToRemove) => {
    setFilters([]);
    setTimeout(() => {
      onRemoveFilter([]); // Update the parent component with the new filters list
    }, 110);
  };

  return (
    <div>
      {!selectedFilters && filters.length > 0 && (
        <div style={{ marginTop: 16, marginBottom: 16, display: "flex" }}>
          <div
            style={{
              fontWeight: "bold",
            }}
          >
            Contains:{" "}
          </div>
          {filters.map((filter, index) => (
            <Tag key={index} style={{ marginLeft: 8 }}>
              <strong>{filter.field}:</strong> {filter.value}
            </Tag>
          ))}
          <Button
            size="small"
            icon={<CloseOutlined />}
            onClick={handleRemoveFilter}
            style={{ marginLeft: 16 }}
            type="light"
          >
            Clear Filter
          </Button>
          <Button
            size="small"
            icon={<SaveOutlined />}
            onClick={handleSaveFilter}
            style={{ marginLeft: 16 }}
          ></Button>
        </div>
      )}

      <Form>
        <Space>
          <Select
            placeholder="Select a field"
            style={{ width: 200 }}
            value={currentField}
            onChange={(value) => setCurrentField(value)}
          >
            {fields.map((field) => (
              <Option key={field.name} value={field.name}>
                {field.label || field.name}
              </Option>
            ))}
          </Select>
          <Input
            placeholder="Contains"
            value={currentValue}
            onChange={(e) => setCurrentValue(e.target.value)}
          />
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleAddFilter}
          >
            Add Filter
          </Button>
        </Space>
      </Form>

      {/* Modal for filter name input */}
      <Modal
        title="Save Filter"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <Input
          placeholder="Enter filter name"
          value={filterName}
          onChange={(e) => setFilterName(e.target.value)}
        />
      </Modal>
    </div>
  );
};

export default CreateFilter;
