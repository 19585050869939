import React, { useState, useEffect } from "react";
import { Card, Button, Modal, Form, Input, Checkbox, notification } from "antd";
import axios from "axios";

const AppsPage = () => {
  const [apps, setApps] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedApp, setSelectedApp] = useState(null);
  const [isConfigModalVisible, setIsConfigModalVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchApps();
  }, []);

  const fetchApps = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/apps",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setApps(response.data);
    } catch (error) {
      notification.error({
        message: "Error fetching apps",
        description: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleEnableApp = async (appId) => {
    try {
      await axios.post(
        process.env.REACT_APP_API_URL + `/apps/${appId}/enable`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      notification.success({ message: "App enabled successfully!" });
      fetchApps(); // Refresh the app list
    } catch (error) {
      notification.error({
        message: "Error enabling app",
        description: error.message,
      });
    }
  };

  const handleDisableApp = async (appId) => {
    try {
      await axios.post(
        process.env.REACT_APP_API_URL + `/apps/${appId}/disable`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      notification.success({ message: "App disabled successfully!" });
      fetchApps(); // Refresh the app list
    } catch (error) {
      notification.error({
        message: "Error disabling app",
        description: error.message,
      });
    }
  };

  const handleConfigureApp = (app) => {
    setSelectedApp(app);
    setIsConfigModalVisible(true);
    form.setFieldsValue(app.configuration || {}); // Populate the form with existing config
  };

  const handleConfigSubmit = async () => {
    try {
      const values = form.getFieldsValue();
      await axios.post(
        process.env.REACT_APP_API_URL + `/apps/${selectedApp.appId}/configure`,
        values,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      notification.success({ message: "App configured successfully!" });
      setIsConfigModalVisible(false);
      fetchApps(); // Refresh the app list
    } catch (error) {
      notification.error({
        message: "Error configuring app",
        description: error.message,
      });
    }
  };

  return (
    <div>
      <h2>Marketplace</h2>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
        {apps.map((app) => (
          <Card
            key={app.appId}
            title={app.name}
            style={{ width: 300 }}
            actions={[
              <Button onClick={() => handleEnableApp(app.appId)}>
                Enable
              </Button>,
              <Button onClick={() => handleDisableApp(app.appId)}>
                Disable
              </Button>,
              <Button onClick={() => handleConfigureApp(app)}>
                Configure
              </Button>,
            ]}
          >
            <p>{app.description}</p>
          </Card>
        ))}

        <Card
          title="Register a New App"
          style={{ width: 300 }}
          actions={[
            <Button
              type="primary"
              onClick={() => {
                window.location.href = "/apps-register";
              }}
            >
              Register
            </Button>,
          ]}
        >
          <p>Register a new app to the marketplace</p>
        </Card>
      </div>

      <Modal
        title={`Configure ${selectedApp?.name}`}
        visible={isConfigModalVisible}
        onCancel={() => setIsConfigModalVisible(false)}
        onOk={handleConfigSubmit}
      >
        <Form form={form} layout="vertical">
          {selectedApp?.configurationFields?.map((field) => (
            <Form.Item
              key={field.name}
              name={field.name}
              label={field.label}
              valuePropName={field.type === "checkbox" ? "checked" : "value"}
            >
              {field.type === "checkbox" ? (
                <Checkbox />
              ) : (
                <Input placeholder={field.placeholder} />
              )}
            </Form.Item>
          ))}
        </Form>
      </Modal>
    </div>
  );
};

export default AppsPage;
