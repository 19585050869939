import React, { useState, useEffect, useRef } from "react";
import { Form, Button, List, Modal, message, Avatar, Tag } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  PlusCircleFilled,
  SaveFilled,
  PlusSquareFilled,
  MailFilled,
  MessageFilled,
  RobotFilled, // Import the robot icon for OpenAI
  OpenAIOutlined,
  RobotOutlined,
} from "@ant-design/icons";
import ActivityItem from "./ActivityItem";
import AgentButton from "./AgentButton";

const ActivityLog = ({
  objectId,
  activityLog,
  fetchActivityLog,
  users,
  teamMembers,
  team,
  agents,
  agentTeams,
  selectedTask,
  closeSelectedTask,
  tasks,
}) => {
  const [form] = Form.useForm();
  const [editingLog, setEditingLog] = useState(null);
  const logContainerRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [subject, setSubject] = useState();
  const [openAIProcessing, setOpenAIProcessing] = useState(false);
  const [agentLoading, setAgentLoading] = useState(false);
  const [body, setBody] = useState();

  const teamId = localStorage.getItem("teamId");

  useEffect(() => {
    scrollToBottom();
    const eventSource = new EventSource(
      process.env.REACT_APP_API_URL + `/agents/${teamId}/stream`
    );
    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === "activity") {
        fetchActivityLog(objectId);
        setAgentLoading(false);
      }
    };
    return () => {
      eventSource.close();
    };
  }, [activityLog]);

  const scrollToBottom = () => {
    if (logContainerRef.current) {
      logContainerRef.current.scrollTop = logContainerRef.current.scrollHeight;
    }
  };

  const handleAddActivity = (values) => {
    const activity = {
      userId: localStorage.getItem("userId"), // Replace with actual user ID
      ...values,
    };

    if (selectedTask) {
      activity.taskId = selectedTask.taskId;
      activity.taskHumanReadableId = selectedTask.humanReadableId;
    }

    axios
      .post(
        process.env.REACT_APP_API_URL +
          `/object/${objectId}/activity?teamId=${teamId}`,
        { ...activity, type: "message" },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        fetchActivityLog(objectId);
        form.resetFields();
      })
      .catch((error) => {
        console.error("There was an error adding the activity log!", error);
      });
  };

  const handleEditActivity = (log) => {
    setEditingLog(log);
    form.setFieldsValue(log);
  };

  const handleUpdateActivity = (values) => {
    axios
      .put(
        process.env.REACT_APP_API_URL +
          `/object/${objectId}/activity/${editingLog.logId}?teamId=${teamId}`,
        values,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        setEditingLog(null);
        fetchActivityLog(objectId);
        form.resetFields();
      })
      .catch((error) => {
        console.error("There was an error updating the activity log!", error);
      });
  };

  const handleAIRequest = async (agent) => {
    console.log("Selected agent: ", agent);
    setOpenAIProcessing(true);
    try {
      const messageBody = form.getFieldValue("body");

      console.log("Sending to: ", agent);

      const requestData = {
        objectId,
        messages: [messageBody],
        taskId: selectedTask.taskId || null,
        taskHumanReadableId: selectedTask.humanReadableId || null,
      };

      const response = await axios.post(
        process.env.REACT_APP_API_URL +
          `/agent/${agent.agentId}/interact?teamId=${teamId}`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      fetchActivityLog(objectId);
      form.resetFields();
      message.success("OpenAI response added to activity log.");
    } catch (error) {
      console.error("There was an error querying OpenAI!", error);
      message.error("Error querying OpenAI.");
    } finally {
      setOpenAIProcessing(false);
    }
  };

  const handelAITeamRequest = async (team) => {
    setOpenAIProcessing(true);
    try {
      const messageBody = form.getFieldValue("body");

      console.log("Sending to: ", team);

      const requestData = {
        objectId,
        messages: [messageBody],
      };

      const response = await axios.post(
        process.env.REACT_APP_API_URL +
          `/agents/teams/${team.id}/interact?teamId=${teamId}`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      fetchActivityLog(objectId);
      form.resetFields();
      message.success("OpenAI response added to activity log.");
    } catch (error) {
      console.error("There was an error querying OpenAI!", error);
      message.error("Error querying OpenAI.");
    } finally {
      setOpenAIProcessing(false);
    }
  };

  const handleDeleteActivity = (logId) => {
    axios
      .delete(
        process.env.REACT_APP_API_URL +
          `/object/${objectId}/activity/${logId}?teamId=${teamId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        fetchActivityLog(objectId);
      })
      .catch((error) => {
        console.error("There was an error deleting the activity log!", error);
      });
  };

  function sentMail() {
    axios
      .post(
        process.env.REACT_APP_API_URL + `/emails/send?teamId=${teamId}`,
        {
          to: objectId,
          subject: form.getFieldValue("subject") || "New Message",
          body: form.getFieldValue("body"),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        fetchActivityLog(objectId);
        form.resetFields();
      })
      .catch((error) => {
        console.error("There was an error sending the mail!", error);
      });
  }

  function Buttons() {
    return (
      <div
        style={{
          position: "absolute",
          top: 12,
          right: 12,
          fontWeight: 600,
          zIndex: 1,
          fontSize: 22,
          display: "flex",
          gap: 6,
          flexDirection: "row-reverse",
        }}
      >
        <Button
          type="primary"
          disabled={openAIProcessing || !body}
          style={{
            padding: "4px 12px",
          }}
          onClick={() => {
            if (
              selectedTask &&
              selectedTask.assignedTo &&
              selectedTask.assignedTo.includes("agent:")
            ) {
              let agent = agents.filter((x) => {
                return x.agentId === selectedTask.assignedTo.split(":")[1];
              })[0];
              if (agent) {
                handleAIRequest(agent);
              } else {
                // form.submit();
              }
            } else {
              form.submit();
            }
          }}
        >
          {editingLog ? <SaveFilled /> : <MessageFilled />}
          {selectedTask && (
            <div
              style={{
                fontSize: 11,
                fontWeight: 600,
              }}
            >
              {selectedTask?.humanReadableId}
            </div>
          )}
        </Button>

        <>
          {/* <Button
              type=""
              style={{
                padding: "4px 12px",
              }}
              onClick={() => {
                setShowModal(true);
              }}
            >
              {<MailFilled />}
            </Button> */}
          {agents && agents.length > 0 && (
            <AgentButton
              disabled={openAIProcessing || !body}
              team={team}
              agents={agents}
              loading={agentLoading}
              onAgent={handleAIRequest}
              agentTeams={agentTeams}
              onTeam={handelAITeamRequest}
            />
          )}
        </>
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          borderRadius: 8,
          overflow: "hidden",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
        }}
      >
        {selectedTask && (
          <div
            style={{
              position: "absolute",
              top: 4,
              left: 16,
              zIndex: 1,
              fontWeight: "bold",
              backgroundColor: "white",
            }}
          >
            Filtered: &nbsp;
            <Tag
              color="blue"
              closable
              size-="large"
              onClose={() => closeSelectedTask()}
            >
              {selectedTask?.humanReadableId}
            </Tag>
          </div>
        )}
        <div
          ref={logContainerRef}
          className="activity-log-container"
          style={{
            flex: 1,
            padding: 8,
            maxHeight: "calc(100% - 80px)",
            overflowY: "auto",
            paddingBottom: 40,
            paddingTop: selectedTask ? 20 : 0,
          }}
        >
          <List
            itemLayout="horizontal"
            dataSource={activityLog.filter((log) =>
              selectedTask ? log.taskId === selectedTask.taskId : true
            )}
            renderItem={(log) => (
              <ActivityItem
                log={log}
                handleEditActivity={handleEditActivity}
                handleDeleteActivity={handleDeleteActivity}
                user={users && users.filter((u) => u.userId === log.userId)[0]}
                teamMembers={teamMembers}
                agent={
                  log.userId.includes("agent:")
                    ? agents.filter((x) => {
                        return x.agentId === log.userId.split(":")[1];
                      })[0]
                    : null
                }
              />
            )}
          />
        </div>
        <div style={{}}>
          <div
            style={{
              display: "flex",
              position: "relative",
              borderRadius: 8,
              overflow: "hidden",
            }}
          >
            <div
              style={{
                flex: 1,
                padding: "8px 8px 8px 8px", // Extra bottom padding to accommodate button
              }}
            >
              <Form
                layout="vertical"
                onFinish={editingLog ? handleUpdateActivity : handleAddActivity}
                form={form}
              >
                <Form.Item
                  style={{
                    marginBottom: 0,
                    borderRadius: 8,
                    border: "2px solid #f0f0f0",
                    overflow: "hidden",
                  }}
                  name="body"
                  rules={[{ required: true, message: "Please enter the body" }]}
                >
                  <ReactQuill
                    theme="snow"
                    style={{
                      border: "0px solid #f0f0f0 ",
                      resize: "none",
                      minHeight: 100,
                      borderRadius: 8,
                    }}
                    onChange={(value) => setBody(value)}
                    placeholder="Enter message here..."
                  />
                </Form.Item>

                <Buttons />
                <Modal
                  title="Send Email"
                  visible={showModal}
                  onOk={() => {
                    setShowModal(false);
                    sentMail();
                  }}
                  onCancel={() => setShowModal(false)}
                  okButtonProps={{
                    disabled: !subject || !form.getFieldValue("body"),
                  }}
                >
                  Subject*
                  <Form.Item
                    name="subject"
                    rules={[
                      { required: true, message: "Please enter the subject" },
                    ]}
                  >
                    <input
                      style={{
                        border: "2px solid #f0f0f0",
                        borderRadius: 8,
                        padding: "4px 8px",
                        width: "100%",
                        marginBottom: 8,
                      }}
                      placeholder="Enter subject"
                      onChange={(e) => setSubject(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item
                    name="body"
                    rules={[
                      { required: true, message: "Please enter the body" },
                    ]}
                  >
                    <ReactQuill
                      theme="snow"
                      style={{
                        border: "0px solid #f0f0f0 ",
                        resize: "none",
                        minHeight: 100,
                        borderRadius: 8,
                      }}
                      placeholder="Enter message here..."
                    />
                  </Form.Item>
                </Modal>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .activity-log-container::-webkit-scrollbar {
          width: 6px;
        }
        .activity-log-container::-webkit-scrollbar-thumb {
          background-color: transparent;
          border-radius: 6px;
        }
        .activity-log-container:hover::-webkit-scrollbar-thumb {
          background-color: #c1c1c1;
        }
        .activity-log-container::-webkit-scrollbar-track {
          background-color: transparent;
        }
      `}</style>
    </>
  );
};

export default ActivityLog;
