import React, { useEffect, useState } from "react";
import { Button, Table, Spin, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AgentTeamsPage from "./AgentTeamsPage";
import { render } from "@testing-library/react";

const AgentsPage = () => {
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const teamId = localStorage.getItem("teamId");

  useEffect(() => {
    fetchAgents();
  }, []);

  const fetchAgents = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/teams/${teamId}/agents?teamId=${teamId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setAgents(response.data.agents);
    } catch (error) {
      message.error("Error fetching agents");
      console.error(error);
    }
    setLoading(false);
  };

  const columns = [
    {
      title: "Agent Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Model",
      dataIndex: "modelType",
      key: "modelType",
    },
    {
      title: "isVisible",
      dataIndex: "isVisible",
      key: "isVisible",
      render: (text, record) =>
        record.isVisible != false ? "Visible" : "Not Visible",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, agent) => (
        <Button onClick={() => navigate(`/agents/manage/${agent.agentId}`)}>
          Manage
        </Button>
      ),
    },
  ];

  return (
    <div>
      <h1>AI Agents</h1>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => navigate("/agents/create")}
      >
        Create New Agent
      </Button>

      {loading ? (
        <Spin />
      ) : (
        <Table columns={columns} dataSource={agents} rowKey="agentId" />
      )}
      <AgentTeamsPage />
    </div>
  );
};

export default AgentsPage;
