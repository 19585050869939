import React, { useState } from "react";
import { Modal, Button } from "antd";
import { MailOutlined } from "@ant-design/icons";
import EmailBuilder from "./EmailBuilder";

const SendGridCard = ({ objectType, team, teamMembers }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {team && team.sendgridApiKey && (
        <>
          <div
            style={{
              width: "fit-content",
              padding: "10px 20px",
              backgroundColor: "#fff",
              borderRadius: 8,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: 10,
              fontWeight: "bold",
              color: "black",
              cursor: "pointer",
              textAlign: "center",
              boxShadow: "0px 0px 10px 0px #00000013",
              margin: 10,
            }}
            onClick={showModal}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 10,
              }}
            >
              <MailOutlined style={{ fontSize: 20, marginBottom: 0 }} />
              <div> Bulk Email</div>
            </div>
          </div>
          <Modal
            title="Email Builder"
            visible={isModalVisible}
            footer={null}
            width={"calc(100vw - 100px)"}
            closable={false}
          >
            <EmailBuilder
              objectType={objectType}
              onClose={handleCancel}
              teamMembers={teamMembers}
            />
          </Modal>
        </>
      )}
    </>
  );
};

export default SendGridCard;
