import React, { useState, useEffect, useRef } from "react";
import {
  List,
  Avatar,
  Skeleton,
  Typography,
  notification,
  Card,
  Divider,
  Tag,
  Input,
  Modal,
  Select,
  DatePicker,
  Button,
  Collapse,
} from "antd";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { objectTypesState, teamMembersState } from "../state";
import Markdown from "react-markdown";
import TextArea from "antd/es/input/TextArea";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Option } from "antd/es/mentions";
import GrowthLevers from "../components/GrowthLevers";
import moment from "moment";
import ReactQuill from "react-quill"; // Import the rich-text editor
import "react-quill/dist/quill.snow.css"; // Import the editor's styles
import { marked } from "marked"; // Import marked for Markdown conversion
import _ from "lodash";

const { Panel } = Collapse;

const { Title } = Typography;

function Team() {
  const [activityLog, setActivityLog] = useState([]);
  const [loadingLogs, setLoadingLogs] = useState(false);

  const [teamMembers, setTeamMembers] = useRecoilState(teamMembersState);
  const [objectTypes, setObjectTypes] = useRecoilState(objectTypesState);
  const [showNorthStarHint, setShowNorthStarHint] = useState(false);
  const [northStarMetric, setNorthStarMetric] = useState("");
  const [growthLevers, setGrowthLevers] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedLever, setSelectedLever] = useState(null);
  const [rockTitle, setRockTitle] = useState("");
  const [rockStatus, setRockStatus] = useState("todo");
  const [rockNotes, setRockNotes] = useState("");
  const [addLeverModalVisible, setAddLeverModalVisible] = useState(false);
  const [newLeverTitle, setNewLeverTitle] = useState("");
  const [northStarNumber, setNorthStarNumber] = useState(0);
  const [editNorthStar, setEditNorthStar] = useState(false);
  const [editNorthStarNumber, setEditNorthStarNumber] = useState(false);
  const [editRunwayDate, setEditRunwayDate] = useState(false);
  const [runwayDate, setRunwayDate] = useState(null);
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [loadingYSummary, setLoadingYSummary] = useState(false);
  const [loading7Summary, setLoading7Summary] = useState(false);
  const [loading30Summary, setLoading30Summary] = useState(false);
  const [yesterdaySummary, setYesterdaySummary] = useState(""); // State for yesterday's summary
  const [last7DaysSummary, setLast7DaysSummary] = useState(""); // State for last 7 days summary
  const [last30DaysSummary, setLast30DaysSummary] = useState(""); // State for last 30 days summary
  const [isEditingYesterday, setIsEditingYesterday] = useState(false); // Toggle for yesterday's summary
  const [isEditingLast7Days, setIsEditingLast7Days] = useState(false); // Toggle for last 7 days summary
  const [isEditingLast30Days, setIsEditingLast30Days] = useState(false); // Toggle for last 30 days summary

  const navigate = useNavigate();

  const { teamId } = useParams();
  // useRef to track if the fetch has already been called

  // Fetch activity logs

  // Empty dependency array ensures it only runs once after the initial render

  useEffect(() => {
    setLoadingLogs(true);
    fetchActivityLogs();
    fetchTeamData();
  }, [teamId]);

  const fetchTeamData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/teams/${teamId}?teamId=${teamId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setNorthStarMetric(
        (response.data && response.data.northStarMetric) || ""
      );

      setNorthStarNumber((response.data && response.data.northStarNumber) || 0);
      setRunwayDate((response.data && response.data.runwayDate) || new Date());
      setGrowthLevers(response.data.growthLevers || []);
      setYesterdaySummary(
        (response.data &&
          response.data.activitySummary &&
          response.data.activitySummary.yesterday) ||
          ""
      );
      setLast7DaysSummary(
        (response.data &&
          response.data.activitySummary &&
          response.data.activitySummary.last7Days) ||
          ""
      );
      setLast30DaysSummary(
        (response.data &&
          response.data.activitySummary &&
          response.data.activitySummary.last30Days) ||
          ""
      );
    } catch (error) {
      console.log("error", error.response.data.message);
      if (error.response.data.message === "User is not a member of the team") {
        localStorage.removeItem("teamId");
        window.location.href = "/";
      }
      notification.error({
        message: "Error fetching team data",
        description: error.message,
      });
    }
  };

  const updateNorthStarMetric = async ({ date }) => {
    setEditNorthStarNumber(false);
    setEditNorthStar(false);
    setEditRunwayDate(false);

    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/teams/${teamId}/north-star?teamId=${teamId}`,
        {
          northStarMetric,
          northStarNumber,
          runwayDate: date || runwayDate,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      // notification.success({ message: "North Star updated successfully" });
    } catch (error) {
      notification.error({
        message: "Error updating North Star",

        description: error.message,
      });
    }
  };

  function RunwayLeft({ date }) {
    var a = moment(date);
    var b = moment();

    var years = a.diff(b, "year");
    b.add(years, "years");

    var months = a.diff(b, "months");
    b.add(months, "months");

    var days = a.diff(b, "days");

    return (
      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          gap: 8,
          paddingBottom: 4,
          textAlign: "center",
        }}
      >
        {years > 0 && (
          <div>
            <div>{years}</div>
            <div style={{ fontSize: 10, marginTop: -4 }}>years</div>{" "}
          </div>
        )}
        {months > 0 && (
          <div>
            <div>{months}</div>
            <div style={{ fontSize: 10, marginTop: -4 }}>months</div>{" "}
          </div>
        )}
        {days > 0 && (
          <div>
            <div>{days}</div>
            <div style={{ fontSize: 10, marginTop: -4 }}>days</div>{" "}
          </div>
        )}
      </div>
    );
  }

  //"/activity-summary/team/:teamId
  const updateSummary = async (type) => {
    if (type === "yesterday") setLoadingYSummary(true);
    if (type === "last7Days") setLoading7Summary(true);
    if (type === "last30Days") setLoading30Summary(true);
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/activity-summary/manual/team/${teamId}?teamId=${teamId}`,
        {
          summary: {
            yesterday: yesterdaySummary,
            last7Days: last7DaysSummary,
            last30Days: last30DaysSummary,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setLoadingSummary(false);
      notification.success({
        message: "Summary updated successfully for " + _.startCase(type),
      });
      if (type === "yesterday") setIsEditingYesterday(false);
      if (type === "last7Days") setIsEditingLast7Days(false);
      if (type === "last30Days") setIsEditingLast30Days(false);
      setLoadingYSummary(false);
      setLoading7Summary(false);
      setLoading30Summary(false);
    } catch (error) {
      setLoadingSummary(false);
      setLoadingYSummary(false);
      setLoading7Summary(false);
      setLoading30Summary(false);
      notification.error({
        message: "Error updating summary",
        description: error.message,
      });
    }
  };

  const fetchActivityLogs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/activity-logs/team/${teamId}?teamId=${teamId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      let logData = response?.data?.data || [];

      setActivityLog(logData);

      console.log("logData", logData);
    } catch (error) {
      notification.error({
        message: "Error fetching activity logs",
        description: error.message,
      });
    } finally {
      // Add a delay to the loading state

      setLoadingLogs(false);
    }
  };

  return (
    <div style={{ padding: 24 }}>
      {/* North Star Section */}
      <div>
        <div style={{ marginTop: -20, display: "flex" }}></div>

        <div style={{ display: "flex", gap: 24, alignItems: "center" }}>
          {editNorthStarNumber ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "lightgray",
                borderRadius: 20,
                fontSize: 32,
                fontWeight: "bold",
                padding: 16,
                cursor: "pointer",
              }}
            >
              <Input
                value={northStarNumber}
                onChange={(e) => setNorthStarNumber(e.target.value)}
                onBlur={updateNorthStarMetric}
                style={{ fontSize: 32, width: 100 }}
                placeholder="0"
                type="number"
              />
            </div>
          ) : (
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "lightgray",
                borderRadius: 20,
                fontSize: 42,
                fontWeight: "bold",
                padding: 12,
                cursor: "pointer",
                textAlign: "center",
              }}
              onClick={() => setEditNorthStarNumber(true)}
            >
              <div
                style={{
                  fontSize: 10,
                  color: "gray",
                }}
              >
                North Star
              </div>
              <div>{northStarNumber || 0}</div>
              <div
                style={{
                  fontSize: 10,
                  color: "gray",
                }}
              >
                (total)
              </div>
            </div>
          )}
          <div style={{ flex: 1 }}>
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  fontWeight: 900,
                }}
              >
                North Star
              </div>
              <div
                style={{ paddingTop: 2, paddingLeft: 8, cursor: "pointer" }}
                onClick={() => setShowNorthStarHint(!showNorthStarHint)}
              >
                <InfoCircleOutlined />
              </div>
            </div>
            {showNorthStarHint && (
              <div
                style={{
                  padding: 10,
                  backgroundColor: "white",
                  borderRadius: 10,
                  marginBottom: 10,
                  marginTop: -10,
                  width: "fit-content",
                }}
              >
                The North Star metric is the single metric that best captures
                the core value that your product delivers to customers. It
                should be reevaluated annually but not changed frequently.
              </div>
            )}
            {editNorthStar ? (
              <>
                <TextArea
                  value={northStarMetric}
                  onChange={(e) => setNorthStarMetric(e.target.value)}
                  onBlur={updateNorthStarMetric}
                  style={{ fontSize: 22 }}
                  placeholder="North Star Metric"
                />
              </>
            ) : (
              <div
                style={{
                  fontSize: 22,
                  cursor: "pointer",
                  marginTop: 2,
                  minWidth: 300,
                  height: 78,
                }}
                onClick={() => setEditNorthStar(true)}
              >
                {northStarMetric || "Enter North Star Metric"}
              </div>
            )}
          </div>

          {editRunwayDate ? (
            <div>
              <DatePicker
                onChange={(date) => {
                  setRunwayDate(date);
                  updateNorthStarMetric({ date });
                }}
              />
            </div>
          ) : (
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "lightgray",
                borderRadius: 20,
                fontSize: 42,
                fontWeight: "bold",
                padding: 12,
                cursor: "pointer",
                textAlign: "center",
              }}
              onClick={() => setEditRunwayDate(true)}
            >
              <div
                style={{
                  fontSize: 10,
                  color: "gray",
                }}
              >
                Runway
              </div>
              <div>
                <RunwayLeft date={runwayDate} />
              </div>
            </div>
          )}
        </div>
      </div>

      <div
        style={{
          flex: 1,
          marginTop: 8,
        }}
      >
        <GrowthLevers
          growthLevers={growthLevers}
          setGrowthLevers={setGrowthLevers}
          teamId={teamId}
          teamMembers={teamMembers}
        />
      </div>
      <div>
        <Title level={2}> Summary</Title>
        <div
          style={{
            display: "flex",
            gap: 24,
          }}
        >
          <div
            style={{
              flex: 1,
            }}
          >
            {/* Yesterday's Summary */}
            <div
              style={{
                fontSize: 18,
                fontWeight: 900,
                marginTop: 16,
              }}
            >
              What happened yesterday?
            </div>
            <div
              style={{
                borderRadius: 10,
                padding: 10,
              }}
            >
              <ReactQuill
                value={marked(yesterdaySummary)}
                onChange={setYesterdaySummary}
                style={{
                  backgroundColor: "white",
                  borderRadius: 10,
                  marginBottom: 10,
                }}
              />
              <Button
                loading={loadingYSummary}
                type="primary"
                onClick={() => updateSummary("yesterday")}
              >
                Save
              </Button>
            </div>
            <div
              style={{
                fontSize: 18,
                fontWeight: 900,
                marginTop: 36,
              }}
            >
              What happened in the last 7 days?
            </div>
            <div>
              <ReactQuill
                value={marked(last7DaysSummary)}
                onChange={setLast7DaysSummary}
                style={{
                  backgroundColor: "white",
                  borderRadius: 10,
                  marginBottom: 10,
                }}
              />
              <Button
                loading={loading7Summary}
                type="primary"
                onClick={() => updateSummary("last7Days")}
              >
                Save
              </Button>
            </div>
            <div
              style={{
                fontSize: 18,
                fontWeight: 900,
                marginTop: 36,
              }}
            >
              What happened in the last 30 days?
            </div>
            <div>
              <ReactQuill
                value={marked(last30DaysSummary)}
                onChange={setLast30DaysSummary}
                style={{
                  backgroundColor: "white",
                  borderRadius: 10,
                  marginBottom: 10,
                }}
              />
              <Button
                loading={loading30Summary}
                type="primary"
                onClick={() => updateSummary("last30Days")}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
