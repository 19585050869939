import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Space,
  Typography,
  Select,
  Checkbox,
  Modal,
} from "antd";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import * as Icons from "@ant-design/icons";
import "./EditObjectType.css";
import _ from "lodash";
import { useRecoilState } from "recoil";
import { teamIdState } from "../state";

const { Title } = Typography;
const { Option } = Select;

const EditObjectType = () => {
  const { objectType } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({});
  const [objectTypes, setObjectTypes] = useState([]);
  const [teamId, setTeamId] = useRecoilState(teamIdState);
  const [isPublic, setIsPublic] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + `/object-types?teamId=${teamId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setObjectTypes(response.data.data);
        const objectTypeData = response.data.data.find(
          (item) => item.name === objectType
        );
        if (objectTypeData) {
          setInitialValues(objectTypeData);
          setIsPublic(objectTypeData.public);
          form.setFieldsValue(objectTypeData);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the object types!", error);
      });
  }, [objectType, form]);

  const handleLabelChange = (key, e) => {
    const fields = form.getFieldValue("fields") || [];
    const updatedFields = fields.map((field, index) =>
      index === key ? { ...field, name: _.snakeCase(e.target.value) } : field
    );
    form.setFieldsValue({ fields: updatedFields });
  };

  const handleSave = (values) => {
    axios
      .put(
        process.env.REACT_APP_API_URL +
          `/object-type/${initialValues.id}?teamId=${teamId}`,
        values,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.error("There was an error updating the object type!", error);
      });
  };

  const handleAddField = () => {
    const fields = form.getFieldValue("fields") || [];
    const nextFields = [
      ...fields,
      { name: "", label: "", type: "text", required: false, showOnTable: true },
    ];
    form.setFieldsValue({ fields: nextFields });
  };

  const moveField = (index, direction) => {
    const fields = form.getFieldValue("fields") || [];
    const newFields = [...fields];

    const targetIndex = index + direction;
    if (targetIndex >= 0 && targetIndex < fields.length) {
      const temp = newFields[targetIndex];
      newFields[targetIndex] = newFields[index];
      newFields[index] = temp;
      form.setFieldsValue({ fields: newFields });
    }
  };

  const handleDelete = () => {
    Modal.confirm({
      title: "Are you sure you want to delete this object type?",
      okButtonProps: { danger: true },
      okText: "Delete",
      onOk: () => {
        axios
          .delete(
            process.env.REACT_APP_API_URL +
              `/object-type/${initialValues.id}?teamId=${teamId}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then(() => {
            navigate("/");
            window.location.reload();
          })
          .catch((error) => {
            console.error(
              "There was an error deleting the object type!",
              error
            );
          });
      },
    });
  };

  const iconOptions = Object.keys(Icons)
    .filter((key) => key.endsWith("Outlined"))
    .map((key) => (
      <Option key={key} value={key}>
        {React.createElement(Icons[key])} {key}
      </Option>
    ));

  return (
    <div
      className="edit-object-type-page"
      style={{
        borderRadius: 8,
        backgroundColor: "white",
        padding: 16,
      }}
    >
      <Title level={2}>
        {objectType != "new" ? "Edit " + objectType : "Create"}
      </Title>

      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onFinish={handleSave}
      >
        <Form.Item
          name="name"
          label="Object Type Name"
          rules={[
            { required: true, message: "Please enter the object type name" },
          ]}
        >
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item
          name="icon"
          label="Icon"
          rules={[{ required: true, message: "Please select an icon" }]}
        >
          <Select placeholder="Select an icon" showSearch>
            {iconOptions}
          </Select>
        </Form.Item>
        <Form.List name="fields">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                <Space
                  key={key}
                  style={{ display: "flex", marginBottom: 8 }}
                  align="baseline"
                >
                  <Space>
                    <Button
                      icon={<Icons.ArrowUpOutlined />}
                      onClick={() => moveField(index, -1)}
                      disabled={index === 0}
                    />
                    <Button
                      icon={<Icons.ArrowDownOutlined />}
                      onClick={() => moveField(index, 1)}
                      disabled={index === fields.length - 1}
                    />
                  </Space>

                  <Form.Item
                    {...restField}
                    name={[name, "label"]}
                    fieldKey={[fieldKey, "label"]}
                    rules={[{ required: true, message: "Missing field label" }]}
                  >
                    <Input
                      placeholder="Field Label"
                      onChange={(e) => handleLabelChange(index, e)}
                    />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    name={[name, "name"]}
                    fieldKey={[fieldKey, "name"]}
                    rules={[{ required: true, message: "Missing field name" }]}
                  >
                    <Input placeholder="Field Name" />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    name={[name, "type"]}
                    fieldKey={[fieldKey, "type"]}
                    rules={[{ required: true, message: "Missing field type" }]}
                  >
                    <Select
                      placeholder="Field Type"
                      style={{
                        width: 150,
                      }}
                    >
                      <Option value="text">Text</Option>
                      <Option value="textarea">Large Text</Option>
                      <Option value="email">Email</Option>
                      <Option value="date">Date</Option>
                      <Option value="tags">Tags</Option>
                      <Option value="file">File</Option>
                      <Option value="yesno">Yes/No</Option>
                      <Option value="relationship">Relationship</Option>
                    </Select>
                  </Form.Item>

                  {form.getFieldValue(["fields", name, "type"]) ===
                    "relationship" && (
                    <Form.Item
                      {...restField}
                      name={[name, "relatedObjectType"]}
                      fieldKey={[fieldKey, "relatedObjectType"]}
                      rules={[
                        { required: true, message: "Select object type" },
                      ]}
                    >
                      <Select placeholder="Select related object type">
                        {objectTypes.map((type) => (
                          <Option key={type.id} value={type.id}>
                            {type.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}

                  <Form.Item
                    {...restField}
                    name={[name, "required"]}
                    valuePropName="checked"
                    fieldKey={[fieldKey, "required"]}
                  >
                    <Checkbox>Required</Checkbox>
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    name={[name, "showOnTable"]}
                    valuePropName="checked"
                    fieldKey={[fieldKey, "showOnTable"]}
                  >
                    <Checkbox>Show on Table</Checkbox>
                  </Form.Item>

                  <Button type="dashed" onClick={() => remove(name)}>
                    Remove
                  </Button>
                </Space>
              ))}

              <Form.Item>
                <Button type="dashed" onClick={handleAddField}>
                  Add Field
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item name="public" valuePropName="checked">
          <Checkbox
            disabled={form.getFieldValue("private")}
            name="public"
            onChange={(e) => {
              setIsPublic(e.target.checked);
              console.log(
                "Public facing read only API available:",
                e.target.checked
              );
            }}
          >
            Public facing read only API available
          </Checkbox>
        </Form.Item>
        <Form.Item name="private" valuePropName="checked">
          <Checkbox
            name="public"
            onChange={(e) => {
              setIsPrivate(e.target.checked);
              console.log(
                "Private facing read only API available:",
                e.target.checked
              );
            }}
          >
            Public facing read only API available requires an api key
          </Checkbox>
        </Form.Item>

        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              {objectType == "new" ? "Create" : "Save"}
            </Button>
            <Button onClick={() => navigate("/")}>Cancel</Button>
            {objectType != "new" && (
              <Button type="danger" onClick={handleDelete}>
                Delete
              </Button>
            )}
          </Space>
        </Form.Item>
      </Form>
      {(isPublic || isPrivate) && (
        <div>
          <div
            style={{
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            Public API Endpoint
          </div>
          <div
            style={{
              backgroundColor: "#f0f0f0",
              padding: "10px",
              borderRadius: "5px",
              marginTop: "10px",
            }}
          >
            {isPrivate && (
              <div
                style={{
                  color: "red",
                  marginBottom: "10px",
                }}
              >
                <b>⚠️Warning:</b> The user API key is sensitive information. Do
                not share it or make public. ⚠️ <br />
                <br />
                <i>
                  It is recommended to store your key securely and access with
                  server side applications only.
                </i>
              </div>
            )}
            <code>List all {initialValues.name}</code>
            <br />
            <code style={{}}>GET</code>
            <br />
            <code>
              {process.env.REACT_APP_API_URL +
                `/api/public/list/${initialValues.id}`}
              {isPrivate && "?key=USER_API_KEY"}
            </code>
            <br />
            <br />
            <code>Get individual {initialValues.name}</code>
            <br />
            <code style={{}}>GET</code>
            <br />
            <code>
              {process.env.REACT_APP_API_URL + `/api/public/item/:itemid`}
              {isPrivate && "?key=USER_API_KEY"}
            </code>
            <br />
          </div>
        </div>
      )}
    </div>
  );
};

export default EditObjectType;
