import React, { useState } from "react";
import { Modal, Input, Button, message } from "antd";
import { CreditCardOutlined } from "@ant-design/icons";
import axios from "axios";
import AutomationCard from "../components/AutomationCard";
import { RobotOutlined } from "@ant-design/icons";

const StripeConfigCard = ({ team }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [apiKey, setApiKey] = useState("");

  const teamId = localStorage.getItem("teamId");

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setApiKey(""); // Reset API key input
  };

  const handleSave = async () => {
    if (!apiKey) {
      message.error("Please enter a Stripe API key.");
      return;
    }

    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/teams/${teamId}/stripe-key`,
        { apiKey },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      message.success("Stripe API key saved successfully!");
      setIsModalVisible(false);
      setApiKey(""); // Clear input after successful save
    } catch (error) {
      console.error("Error saving Stripe API key:", error);
      message.error("Failed to save Stripe API key.");
    }
  };

  return (
    <>
      {team && (
        <>
          <AutomationCard
            title={team.anthropicApiKey ? "Update Stripe" : "Setup Stripe"}
            icon={<CreditCardOutlined style={{ fontSize: 40 }} />}
            onClick={showModal}
          />

          <Modal
            title={
              team.stripeApiKey
                ? "Update Stripe API Key"
                : "Setup Stripe API Key"
            }
            visible={isModalVisible}
            onCancel={handleCancel}
            footer={[
              <Button key="cancel" onClick={handleCancel}>
                Cancel
              </Button>,
              <Button key="save" type="primary" onClick={handleSave}>
                Save
              </Button>,
            ]}
          >
            <div>
              <p>
                To use the Stripe integration, you need to enter your Stripe API
                key below.
              </p>
              <p>
                Don't have an API key? You can find it in your Stripe dashboard.
              </p>
              <p>
                Add your API key below and click "Save" to enable support for
                Customers and Payments in your team.
                <br /> <br /> Teams must also have SendGrid enabled for
                authentication emails.
              </p>
            </div>
            <Input.Password
              placeholder="Enter Stripe API Key"
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
              style={{ marginBottom: 16 }}
            />
          </Modal>
        </>
      )}
    </>
  );
};

export default StripeConfigCard;
