import React from "react";

const AutomationCard = ({ title, onClick, icon }) => {
  // Add your component logic here

  return (
    <div
      style={{
        width: "fit-content",
        padding: "10px 20px",
        backgroundColor: "#fff",
        borderRadius: 8,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        fontWeight: "bold",
        color: "black",
        cursor: "pointer",
        textAlign: "center",
        boxShadow: "0px 0px 10px 0px #00000013",
      }}
      onClick={onClick}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 10,
          flexDirection: "column",
        }}
      >
        <div>{icon}</div>
        <div> {title}</div>
      </div>
    </div>
  );
};

export default AutomationCard;
