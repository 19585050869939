const apiDocs = {
  title: "API Documentation",
  description:
    "This API allows you to interact with external resources in a flexible and efficient way. The API includes several endpoints to manage objects, look them up by ID or properties, log activities, and retrieve activity logs.",
  details: [
    {
      title: "Requesting an API Key",
      description:
        "To use the API, you must request an API key.\n\n1. Navigate to the **profile page**.\n2. Select a team.\n3. Click the 'Generate API Key' button.\n\nThe generated API key will be associated with the selected team and should be included in the request headers like this:\n\n```\nAuthorization: Bearer <your-api-key>\n```",
    },
    {
      title: "Limitations",
      description:
        "All responses have a 1MB limit to ensure performance. Please ensure your queries and data submissions respect this limitation.",
    },
  ],
  endpoints: [
    {
      title: "Get Object by ID",
      method: "GET",
      path: "/api/thing/:id",
      description:
        "This endpoint retrieves an object by its ID. You must be authenticated and a member of the team to access the object. You can optionally include the `includeActivity` query parameter to retrieve the associated activity logs.",
      requestParams: [
        { name: "id", description: "The ID of the object to retrieve." },
        {
          name: "teamId",
          description:
            "The team ID of the object (required for authorization).",
        },
        {
          name: "includeActivity",
          description:
            "(optional) - If set to `true`, returns the activity logs associated with the object.",
        },
      ],
      responseExample: `
      {
        "data": {
          "id": "1234-5678-90ab",
          "name": "Sample Object",
          "field1": "value1",
          "field2": "value2",
          "teamId": "team-1234",
          ...
        },
        "activityLogs": [
          {
            "logId": "log-1234",
            "objectId": "1234-5678-90ab",
            "teamId": "team-123",
            "title": "Follow-up Call",
            "body": "Had a productive call with Sample Object",
            "timestamp": "2023-08-13T12:00:00Z",
            ...
          }
        ]
      }
            `,
    },
    {
      title: "Look up Object by ObjectType and Field",
      method: "GET",
      path: "/api/thing",
      description:
        "Look up an object based on its objectType and an arbitrary field name and value. You must be authenticated and a member of the team to access the object. You can optionally include the `includeActivity` query parameter to retrieve the associated activity logs.",
      requestParams: [
        {
          name: "objectType",
          description: "(required) - The type of object you want to look up.",
        },
        {
          name: "fieldName",
          description: "(required) - The name of the field to search by.",
        },
        {
          name: "fieldValue",
          description: "(required) - The value of the field to search for.",
        },
        {
          name: "teamId",
          description:
            "(query param) - The team ID of the object (required for authorization).",
        },
        {
          name: "includeActivity",
          description:
            "(optional) - If set to `true`, returns the activity logs associated with the object(s).",
        },
      ],
      responseExample: `
      {
        "data": [
          {
            "id": "1234-5678-90ab",
            "objectType": "Contact",
            "field1": "value1",
            "field2": "value2",
            "teamId": "team-123",
            ...
          }
        ],
        "activityLogs": [
          {
            "logId": "log-1234",
            "objectId": "1234-5678-90ab",
            "teamId": "team-123",
            "title": "Follow-up Call",
            "body": "Had a productive call with John Doe",
            "timestamp": "2023-08-13T12:00:00Z",
            ...
          }
        ]
      }
            `,
    },
    {
      title: "Add an Object",
      method: "POST",
      path: "/api/thing",
      description:
        "This endpoint allows you to add a new object by specifying the objectType and the relevant fields.",
      requestBody: `
      {
        "objectType": "Contact",
        "fields": {
          "name": "John Doe",
          "email": "john@example.com",
          "phone": "123-456-7890"
        }
      }
            `,
      responseExample: `
      {
        "message": "Object created successfully",
        "data": {
          "id": "abcd-efgh-ijkl",
          "objectTypeId": "objectType-123",
          "teamId": "team-123",
          "name": "John Doe",
          "email": "john@example.com",
          ...
        }
      }
            `,
    },
    {
      title: "Add Activity Log",
      method: "POST",
      path: "/api/thing/activity",
      description:
        "Add an activity log entry to an object. You can either provide an objectId directly or specify the objectType and an arbitrary field to look up the object.",
      requestBody: `
      {
        "objectId": "1234-5678-90ab", // Optional if objectType and field are provided
        "objectType": "Contact",      // Optional if objectId is provided
        "fieldName": "email",         // Required if objectType is used
        "fieldValue": "john@example.com", // The value of the field to look up
        "title": "Follow-up Call",
        "body": "Had a productive call with John Doe",
        "type": "message"
      }
            `,
      responseExample: `
      {
        "message": "Activity logged successfully",
        "data": {
          "logId": "log-1234",
          "objectId": "1234-5678-90ab",
          "teamId": "team-123",
          "userId": "user-abc",
          "title": "Follow-up Call",
          "body": "Had a productive call with John Doe",
          ...
        }
      }
            `,
    },
  ],
};

export default apiDocs;
