import React, { useState } from "react";
import { Typography, Card, Col, Row } from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./Home.css";

const { Title } = Typography;

const initialTasks = {
  todo: [
    { id: "1", content: "Task 1" },
    { id: "2", content: "Task 2" },
    { id: "3", content: "Task 3" },
  ],
  inProgress: [
    { id: "4", content: "Task 4" },
    { id: "5", content: "Task 5" },
  ],
  done: [{ id: "6", content: "Task 6" }],
};

const Home = () => {
  const [tasks, setTasks] = useState(initialTasks);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const sourceColumn = Array.from(tasks[source.droppableId]);
    const destinationColumn = Array.from(tasks[destination.droppableId]);

    if (source.droppableId === destination.droppableId) {
      const [removed] = sourceColumn.splice(source.index, 1);
      sourceColumn.splice(destination.index, 0, removed);

      setTasks({
        ...tasks,
        [source.droppableId]: sourceColumn,
      });
    } else {
      const [removed] = sourceColumn.splice(source.index, 1);
      destinationColumn.splice(destination.index, 0, removed);

      setTasks({
        ...tasks,
        [source.droppableId]: sourceColumn,
        [destination.droppableId]: destinationColumn,
      });
    }
  };

  return (
    <div>
      <Title level={2}>Kanban Board</Title>
      <DragDropContext onDragEnd={onDragEnd}>
        <Row gutter={16}>
          {Object.keys(tasks).map((key) => (
            <Col span={8} key={key}>
              <Droppable droppableId={key}>
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={`kanban-column ${
                      snapshot.isDraggingOver ? "dragging-over" : ""
                    }`}
                  >
                    <Title level={3} className="kanban-title">
                      {key.toUpperCase()}
                    </Title>
                    {tasks[key].map((task, index) => (
                      <Draggable
                        key={task.id}
                        draggableId={task.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`kanban-item ${
                              snapshot.isDragging ? "dragging" : ""
                            }`}
                          >
                            <Card>{task.content}</Card>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </Col>
          ))}
        </Row>
      </DragDropContext>
    </div>
  );
};

export default Home;
