import React, { useState } from "react";
import { Form, Input, Button, Space, Typography, Select, Checkbox } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./AddObjectType.css";
import _ from "lodash";
import { useRecoilState } from "recoil";
import { teamIdState } from "../state";

const { Title } = Typography;
const { Option } = Select;

const AddObjectType = () => {
  const [teamId, setTeamId] = useRecoilState(teamIdState);

  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handleAddField = () => {
    const fields = form.getFieldValue("fields") || [];
    const nextFields = [
      ...fields,
      { label: "", name: "", type: "text", required: false },
    ];
    form.setFieldsValue({ fields: nextFields });
  };

  const handleLabelChange = (key, e) => {
    const fields = form.getFieldValue("fields") || [];
    const updatedFields = fields.map((field, index) =>
      index === key ? { ...field, name: _.snakeCase(e.target.value) } : field
    );
    form.setFieldsValue({ fields: updatedFields });
  };

  const handleSave = (values) => {
    axios
      .post(
        process.env.REACT_APP_API_URL + `/object-type?teamId=${teamId}`,
        values,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        // window.location.reload();
      })
      .catch((error) => {
        console.error("There was an error creating the object type!", error);
      });
  };

  return (
    <div
      className="add-object-type-page"
      style={{
        borderRadius: 8,
      }}
    >
      <h1>Add New Object Type</h1>
      <Form form={form} layout="vertical" onFinish={handleSave}>
        <Form.Item
          name="name"
          label="Name"
          rules={[
            { required: true, message: "Please enter the object type name" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.List name="fields">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                <Space
                  key={key}
                  style={{ display: "flex", marginBottom: 8 }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, "label"]}
                    fieldKey={[fieldKey, "label"]}
                    rules={[{ required: true, message: "Missing field label" }]}
                  >
                    <Input
                      placeholder="Field Label"
                      onChange={(e) => handleLabelChange(index, e)}
                    />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "name"]}
                    fieldKey={[fieldKey, "name"]}
                    rules={[{ required: true, message: "Missing field name" }]}
                  >
                    <Input placeholder="Field Name" />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    name={[name, "type"]}
                    fieldKey={[fieldKey, "type"]}
                    rules={[{ required: true, message: "Missing field type" }]}
                  >
                    <Select placeholder="Field Type">
                      <Option value="text">Text</Option>
                      <Option value="email">Email</Option>
                      <Option value="date">Date</Option>
                      <Option value="tags">Tags</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "required"]}
                    valuePropName="checked"
                    fieldKey={[fieldKey, "required"]}
                  >
                    <Checkbox>Required</Checkbox>
                  </Form.Item>
                  <Button type="dashed" onClick={() => remove(name)}>
                    Remove
                  </Button>
                </Space>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={add}>
                  Add Field
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "20px",
          }}
        >
          <Space>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
            <Button onClick={() => navigate("/")}>Cancel</Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddObjectType;
