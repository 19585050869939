import React, { useEffect, useState } from "react";
import { Input } from "antd";
import axios from "axios";
import _ from "lodash";

const ToolsConfiguration = ({ onChange, value }) => {
  const [tools, setTools] = useState([]);
  const [selectedTools, setSelectedTools] = useState(value || []);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    // Get tools from API
    axios
      .get(process.env.REACT_APP_API_URL + "/agents/tools", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setTools(response.data.tools);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Filter tools based on the search term
  const filteredTools = tools.filter(
    (tool) =>
      tool.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      tool.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <h2>Available Tools</h2>
      <Input
        placeholder="Search tools by name or description"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ marginBottom: 16 }}
      />
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: 16,
        }}
      >
        {filteredTools.length > 0 ? (
          filteredTools.map((tool) => (
            <div
              style={{
                padding: 0,
                minWidth: "100%",
                backgroundColor: "#fff",
                borderRadius: 8,
                flex: 1,
                textAlign: "left",
                cursor: "pointer",
                border: selectedTools.includes(tool.id)
                  ? "3px solid blue"
                  : "3px solid #eee",
              }}
              key={tool.id}
              onClick={() => {
                if (selectedTools.includes(tool.id)) {
                  const updatedSelection = selectedTools.filter(
                    (id) => id !== tool.id
                  );
                  setSelectedTools(updatedSelection);
                  onChange(updatedSelection);
                } else {
                  const updatedSelection = [...selectedTools, tool.id];
                  setSelectedTools(updatedSelection);
                  onChange(updatedSelection);
                }
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: 16,
                  padding: 8,
                }}
              >
                {_.capitalize(tool.name)}
              </div>
              <div
                style={{
                  paddingLeft: 4,
                  paddingRight: 4,
                  paddingBottom: 8,
                  fontSize: 12,
                }}
              >
                {tool.description}
              </div>
            </div>
          ))
        ) : (
          <div>No tools match your search criteria.</div>
        )}
      </div>
    </div>
  );
};

export default ToolsConfiguration;
