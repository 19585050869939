import React, { useState, useEffect } from "react";
import {
  Tabs,
  Form,
  Input,
  Button,
  Avatar,
  Upload,
  message,
  Typography,
  Select,
  Popconfirm,
  List,
  notification,
  Badge,
} from "antd";
import {
  UploadOutlined,
  DeleteOutlined,
  EyeInvisibleFilled,
  EyeFilled,
} from "@ant-design/icons";
import axios from "axios";
import { useRecoilState } from "recoil";
import { profileState, teamsState } from "../state";
import moment from "moment";
import { useNavigate, useNavigation } from "react-router-dom";

const { Text } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

const UserProfile = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useRecoilState(profileState);
  const [imageUrl, setImageUrl] = useState("");
  const [apiKeys, setApiKeys] = useState([]); // Store multiple API keys
  const [generatingApiKey, setGeneratingApiKey] = useState(false);
  const [teams, setTeams] = useRecoilState(teamsState);
  const [selectedTeam, setSelectedTeam] = useState(""); // Track selected team
  const [showApiKey, setShowApiKey] = useState();
  const [invites, setInvites] = useState([]);
  const [tasks, setTasks] = useState({}); // Store tasks by team
  const [activeTab, setActiveTab] = useState("tasks");
  const [showingTeam, setShowingTeam] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the user's teams and set profile info
    if (profile) {
      profile.picture && setImageUrl(profile.picture);
      form.setFieldsValue(profile);
      setApiKeys(profile.apiKeys || []); // Assuming apiKeys is part of the user profile
    }

    // Fetch pending invitations
    axios
      .get(process.env.REACT_APP_API_URL + "/teams/invites", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setInvites(response.data.invites);
      })
      .catch((error) => {});

    // Fetch tasks for each team

    axios
      .get(`${process.env.REACT_APP_API_URL}/tasks/assigned`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        console.log("tasks", response.data.data);
        setTasks(response.data.data);
        setShowingTeam(localStorage.getItem("teamId"));
        setLoading(false);
      })
      .catch((error) => {
        console.error(`Error fetching tasks for `, error);
      });
  }, [profile, form, teams]);

  const handleSave = (values) => {
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/profile`,
        { ...values, picture: imageUrl },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        message.success("Profile updated successfully");
        setProfile(response.data.data); // Update the recoil state with new profile data
      })
      .catch((error) => {
        message.error("Error updating profile");
      });
  };

  const handleUploadChange = (info) => {
    if (info.file.status === "done") {
      setImageUrl(info.file.response.url);
    }
  };

  const handleGenerateApiKey = () => {
    if (!selectedTeam) {
      message.error("Please select a team.");
      return;
    }

    setGeneratingApiKey(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/generate-api-key`,
        { teamId: selectedTeam },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        message.success("API key generated successfully");
        setApiKeys([...apiKeys, response.data.apiKey]); // Store and display the new API key
      })
      .catch((error) => {
        message.error("Error generating API key");
      })
      .finally(() => {
        setGeneratingApiKey(false);
      });
  };

  const handleRevokeApiKey = (apiKey) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/revoke-api-key`, {
        data: { apiKey },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        message.success("API key revoked successfully");
        setApiKeys(apiKeys.filter((key) => key.apiKey !== apiKey)); // Remove the revoked API key from the state
      })
      .catch((error) => {
        message.error("Error revoking API key");
      });
  };

  const handleJoinTeam = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/teams/join",
        values,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      notification.success({ message: "Joined team successfully!" });
      // onTeamJoined(response.data.teamId);
      localStorage.setItem("teamId", values.teamId);
      window.location.href = "/";
    } catch (error) {
      notification.error({
        message: "Error joining team",
        description: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleTaskStatusChange = (teamId, taskId, newStatus) => {
    let updatedTasks = { ...tasks };
    updatedTasks[teamId].tasks = updatedTasks[teamId].tasks.map((task) =>
      task.taskId === taskId ? { ...task, status: newStatus } : task
    );
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/tasks/${taskId}?teamId=${teamId}`,
        { status: newStatus },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        setTasks(updatedTasks);
        message.success("Task status updated successfully");
      })
      .catch((error) => {
        message.error("Error updating task status");
      });
  };

  const renderTeams = () => (
    <div style={{ display: "flex", gap: "16px", marginBottom: "16px" }}>
      {teams?.map((team) => {
        let count =
          tasks && tasks[team.teamId] && tasks[team.teamId].tasks.length;
        return (
          <>
            <Badge
              key={team.teamId}
              count={count}
              offset={[0, 0]}
              onClick={() => {
                setShowingTeam(team.teamId);
              }}
            >
              <div
                style={{
                  padding: "8px 16px",
                  border: "1px solid #f0f0f0",
                  borderRadius: "4px",
                  fontSize: "16px",
                  cursor: "pointer",
                  fontWeight: "bold",
                  color: showingTeam === team.teamId ? "white" : "black",
                  background: showingTeam === team.teamId ? "#555" : "white",
                }}
              >
                {team.name}
              </div>
            </Badge>
          </>
        );
      })}
    </div>
  );

  return (
    <div style={{ paddingLeft: 24, paddingRight: 24, margin: "auto" }}>
      <h2>User Profile</h2>
      <Tabs activeKey={activeTab} onChange={setActiveTab}>
        <TabPane tab="Assigned Tasks" key="tasks">
          {renderTeams()}
          {loading && <p>Loading...</p>}
          {teams?.map((team) => (
            <>
              {showingTeam === team.teamId && (
                <div key={team.teamId} style={{ marginTop: "16px" }}>
                  <List
                    itemLayout="horizontal"
                    loading={loading}
                    dataSource={
                      (tasks &&
                        tasks[team.teamId] &&
                        tasks[team.teamId].tasks &&
                        tasks[team.teamId].tasks.length > 0 &&
                        tasks[team.teamId].tasks) ||
                      []
                    }
                    renderItem={(task) => (
                      <List.Item
                        style={{
                          backgroundColor: "white",
                          padding: 8,
                          borderRadius: 12,
                          cursor: "pointer",
                        }}
                        actions={[
                          <Select
                            value={task.status}
                            style={{ width: 120 }}
                            onChange={(value) =>
                              handleTaskStatusChange(
                                team.teamId,
                                task.taskId,
                                value
                              )
                            }
                          >
                            <Option value="todo">To Do</Option>
                            <Option value="doing">Doing</Option>
                            <Option value="done">Done</Option>
                          </Select>,
                        ]}
                      >
                        <List.Item.Meta
                          title={task.title}
                          description={task?.object?.name}
                          onClick={() => {
                            navigate(
                              `/${task.object.objectTypeName}/${task.object.id}?taskId=${task.taskId}`
                            );
                          }}
                        />
                      </List.Item>
                    )}
                  />
                </div>
              )}
            </>
          ))}
        </TabPane>

        <TabPane tab="Settings" key="settings">
          <Avatar size={100} src={imageUrl} />
          <Form form={form} layout="vertical" onFinish={handleSave}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: "Please enter your name" }]}
            >
              <Input />
            </Form.Item>
            {/* Include other profile fields if needed */}
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Save
              </Button>
            </Form.Item>
          </Form>

          {invites.length > 0 && (
            <div
              style={{
                marginTop: 44,
                borderTop: "3px solid #f0f0f0",
                paddingTop: 4,
              }}
            >
              <h2>Pending Invites</h2>
              <List
                itemLayout="horizontal"
                dataSource={invites}
                loading={loading}
                renderItem={(invite) => (
                  <List.Item>
                    <List.Item.Meta
                      title={invite.name}
                      description="You have been invited to join this team"
                    />
                    <Button
                      type="primary"
                      onClick={() => handleJoinTeam({ teamId: invite.teamId })}
                    >
                      Accept Invite
                    </Button>
                  </List.Item>
                )}
              />
            </div>
          )}

          <div
            style={{
              marginTop: 44,
              borderTop: "3px solid #f0f0f0",
              paddingTop: 4,
            }}
          >
            <h3>API Keys</h3>
            {apiKeys.length > 0 ? (
              <div>
                {apiKeys?.map((apiKey) => (
                  <div key={apiKey.apiKey}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: 12,
                        gap: 8,
                      }}
                    >
                      <div>
                        {showApiKey === apiKey.apiKey ? (
                          <Button
                            onClick={() => {
                              setShowApiKey(null);
                            }}
                          >
                            <EyeInvisibleFilled />
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              setShowApiKey(apiKey.apiKey);
                            }}
                          >
                            <EyeFilled />
                          </Button>
                        )}
                      </div>
                      <div
                        style={{
                          flex: 1,
                        }}
                      >
                        <Text strong>
                          {teams &&
                            teams.find((t) => t.teamId === apiKey.teamId)?.name}
                        </Text>
                      </div>
                      <div>
                        <Text>{moment(apiKey.createdAt).fromNow()}</Text>
                      </div>
                      <div>
                        <Popconfirm
                          title="Are you sure you want to revoke this API key?"
                          onConfirm={() => handleRevokeApiKey(apiKey.apiKey)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            type="danger"
                            icon={<DeleteOutlined />}
                            style={{ marginLeft: 8 }}
                          >
                            Revoke
                          </Button>
                        </Popconfirm>
                      </div>
                    </div>
                    {showApiKey === apiKey.apiKey && (
                      <Text copyable>{apiKey.apiKey}</Text>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <Text type="secondary">No API keys generated yet.</Text>
            )}

            <div style={{ marginTop: 12 }}>
              <Form.Item label="Select Team">
                <Select
                  value={selectedTeam}
                  onChange={(value) => setSelectedTeam(value)}
                  placeholder="Select a team"
                  style={{ width: "100%" }}
                >
                  {teams &&
                    teams.map((team) => (
                      <Option key={team.teamId} value={team.teamId}>
                        {team.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Button
                type="primary"
                icon={<UploadOutlined />}
                onClick={handleGenerateApiKey}
                loading={generatingApiKey}
                disabled={!selectedTeam}
              >
                Generate API Key
              </Button>
            </div>
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default UserProfile;
