import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Select,
  Space,
  Typography,
  Upload,
  Skeleton,
  Modal,
  Tag,
  Collapse,
} from "antd";
import {
  UploadOutlined,
  ExclamationCircleOutlined,
  DownloadOutlined,
  CaretRightOutlined,
  CaretDownOutlined,
  MessageOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import ActivityLog from "../components/ActivityChat";
import "./Thing.css";
import { useRecoilState } from "recoil";
import TodoList from "../components/TodoList";
import {
  objectTypesState,
  objectTypeState,
  teamIdState,
  teamMembersState,
  teamState,
} from "../state";
import FileManager from "../components/FileManager";

const { Option } = Select;
const { Title } = Typography;
const { confirm } = Modal;
const { Panel } = Collapse;

const Thing = () => {
  const { id } = useParams();
  const objectType = window.location.pathname.split("/")[1];
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [type, setType] = useState();
  const [activityLog, setActivityLog] = useState([]);
  const [relatedObjects, setRelatedObjects] = useState({});
  const [loading, setLoading] = useState(true);
  const [agents, setAgents] = useState([]);
  const [agentTeams, setAgentTeams] = useState([]);
  const [showBoard, setShowBoard] = useState(
    localStorage.getItem("showBoard-" + objectType) === "true"
  );

  const [teamMembers, setTeamMembers] = useRecoilState(teamMembersState);
  const [teamId, setTeamId] = useRecoilState(teamIdState);
  const [team, setTeam] = useRecoilState(teamState);
  const [showFileManager, setShowFileManager] = useState(true);

  // States for collapsing
  const [isDetailsCollapsed, setIsDetailsCollapsed] = useState(
    localStorage.getItem("showBoard-" + objectType) === "true"
  );
  const [isTaskCollapsed, setIsTaskCollapsed] = useState(false);

  // Toggle collapse state
  const toggleDetailsCollapse = () =>
    setIsDetailsCollapsed(!isDetailsCollapsed);
  const toggleTaskCollapse = () => {
    if (showBoard) setShowBoard(false);
    setIsTaskCollapsed(!isTaskCollapsed);
  };

  // Inside your Thing component
  const [selectedTask, setSelectedTask] = useState(null);

  const handleTaskSelect = (task) => {
    setSelectedTask(task);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/object-types?teamId=${teamId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        console.log("objectType", objectType);
        const objectTypeData = response.data.data.find(
          (item) => item.name === objectType
        );
        if (objectTypeData) {
          setType(objectTypeData);
          setFields(objectTypeData.fields);
          fetchRelatedObjects(objectTypeData.fields);
          if (teamId) {
            axios
              .get(`${process.env.REACT_APP_API_URL}/agents?teamId=${teamId}`, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              })
              .then((response) => {
                setAgents(response.data.agents);
                console.log("agents", response.data.agents);
              })
              .catch((error) => {
                console.error(
                  "There was an error fetching the team agents!",
                  error
                );
              });

            axios
              .get(
                `${process.env.REACT_APP_API_URL}/agents/teams?teamId=${teamId}`,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                }
              )
              .then((response) => {
                setAgentTeams(response.data.teams);
                console.log("agentTeams", response.data.teams);
              })
              .catch((error) => {
                console.error(
                  "There was an error fetching the team agents!",
                  error
                );
              });
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(
          "There was an error fetching the object type fields!",
          error
        );
      });

    if (id && id !== "new") {
      axios
        .get(`${process.env.REACT_APP_API_URL}/object/${id}?teamId=${teamId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          const data = response.data.data;
          // Convert date fields to moment objects
          const initialData = {
            ...data,
            ...Object.fromEntries(
              Object.entries(data).map(([key, value]) => [
                key,
                moment(value, moment.ISO_8601, true).isValid()
                  ? moment(value)
                  : value,
              ])
            ),
          };
          setLoading(false);
          setInitialValues(initialData);
          form.setFieldsValue(initialData);
          fetchActivityLog(id);
        })
        .catch((error) => {
          setLoading(false);
          console.error("There was an error fetching the object data!", error);
        });
    }
  }, []);

  useEffect(() => {
    // get team agents
  }, []);

  const fetchRelatedObjects = (fields) => {
    fields
      .filter((field) => field.type === "relationship")
      .forEach((field) => {
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/objects/${field.relatedObjectType}?teamId=${teamId}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response) => {
            setRelatedObjects((prev) => ({
              ...prev,
              [field.name]: response.data.data,
            }));
            setLoading(false);
          })
          .catch((error) => {
            console.error(
              `There was an error fetching related objects for field ${field.name}!`,
              error
            );
          });
      });

    setLoading(false);
  };

  const fetchActivityLog = (objectId) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/object/${objectId}/activity?teamId=${teamId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setActivityLog(response.data.data);
        // dispatch event
        //First, we initialize our event
        const event = new Event("onActivityLogUpdate");

        // Next, we dispatch the event.
        document.dispatchEvent(event);
      })
      .catch((error) => {
        console.error("There was an error fetching the activity log!", error);
      });
  };

  const handleSave = (values) => {
    // check if any of the value contain AWSAccessKeyId and remove it
    // this is a security measure to prevent the user from adding their own AWSAccessKeyId

    const formattedValues = {
      ...values,
      ...Object.fromEntries(
        Object.entries(values).map(([key, value]) => [
          key,
          moment.isMoment(value) ? value.toISOString() : value,
        ])
      ),
    };

    if (id && id !== "new") {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/object/${id}?teamId=${teamId}`,
          formattedValues,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then(() => {
          // navigate(`/${objectType}`);
          // window.location.reload();
        })
        .catch((error) => {
          console.error("There was an error updating the object!", error);
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/object/${type.id}?teamId=${teamId}`,
          formattedValues,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then(() => {
          navigate(`/${objectType}`);
        })
        .catch((error) => {
          console.error("There was an error creating the object!", error);
        });
    }
  };

  const showDeleteConfirm = () => {
    confirm({
      title: "Are you sure delete this object?",
      icon: <ExclamationCircleOutlined />,
      content: "This action cannot be undone",
      onOk() {
        handleDelete();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleDelete = () => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/object/${id}?teamId=${teamId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        navigate(`/${objectType}`);
      })
      .catch((error) => {
        console.error("There was an error deleting the object!", error);
      });
  };

  return (
    <div
      className="thing-page"
      style={{
        backgroundColor: "#f5f5f5",
        padding: 0,
      }}
    >
      <Title
        level={3}
        style={{
          textAlign: "left",
          position: "absolute",
          top: -10,
          left: 270,
          zIndex: 9999,
        }}
      >
        {initialValues.name ||
          initialValues.title ||
          initialValues.Name ||
          initialValues.Title}
      </Title>
      {loading ? (
        <Skeleton active />
      ) : (
        <div
          style={{
            display: "flex",
            gap: "20px",
            height: "calc(100% - 0px)",
          }}
        >
          {/* Collapsible Details Box */}

          <div
            style={{
              flex: isDetailsCollapsed ? 0.1 : 1,
              transition: "flex 0.3s",
              minWidth: isDetailsCollapsed ? "52px" : "300px",
              maxWidth: isDetailsCollapsed ? "52px" : "100%",
              marginLeft: "auto",
              marginRight: "auto",
              backgroundColor: "white",
              padding: isDetailsCollapsed ? 0 : 10,
              paddingBottom: 80,
              borderRadius: 8,
              overflowY: "auto",
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
              }}
            >
              {isDetailsCollapsed ? (
                <div
                  style={{
                    padding: 8,
                  }}
                >
                  <Button
                    type="text"
                    style={{
                      backgroundColor: "#F5F5F5",
                    }}
                    icon={<MenuOutlined />}
                    onClick={toggleDetailsCollapse}
                  />
                </div>
              ) : (
                <Button
                  style={{
                    marginTop: -2,
                    backgroundColor: "#fff",
                  }}
                  type="text"
                  icon={<MenuOutlined />}
                  onClick={toggleDetailsCollapse}
                />
              )}
              <div
                style={{
                  textAlign: "left",
                  fontSize: 18,
                  fontWeight: "bold",
                  marginBottom: 10,
                  paddingTop: 4,
                  display: isDetailsCollapsed ? "none" : "block",
                }}
                onClick={toggleDetailsCollapse}
              >
                &nbsp;
                {id === "new" ? `Add New ${objectType}` : "Details"}
              </div>
            </div>

            <div
              style={{
                display: isDetailsCollapsed ? "none" : "block",
              }}
            >
              <Form
                form={form}
                layout="vertical"
                initialValues={initialValues}
                onFinish={handleSave}
                onBlur={() => {
                  if (id && id !== "new") {
                    form.submit();
                  }
                }}
              >
                {fields &&
                  fields.map((field) => (
                    <Form.Item
                      key={field.name}
                      name={field.name}
                      label={field.label}
                      style={{
                        marginBottom: 2,
                      }}
                      rules={[
                        {
                          required: field.required,
                          message: `Please enter the ${field.label.toLowerCase()}`,
                        },
                      ]}
                    >
                      {field.type === "text" && <Input />}
                      {field.type === "email" && <Input type="email" />}
                      {field.type === "yesno" && (
                        <Select placeholder="Add tags">
                          <Option key={"yes"} value={"yes"}>
                            Yes
                          </Option>
                          <Option key={"no"} value={"no"}>
                            No
                          </Option>
                        </Select>
                      )}
                      {field.type === "date" && (
                        <DatePicker style={{ width: "100%" }} />
                      )}
                      {field.type === "tags" && (
                        <Select mode="tags" placeholder="Add tags">
                          {[].map((tag) => (
                            <Option key={tag} value={tag}>
                              {tag}
                            </Option>
                          ))}
                        </Select>
                      )}
                      {field.type === "textarea" && (
                        <Input.TextArea
                          autoSize={{ minRows: 3, maxRows: 10 }}
                        />
                      )}
                      {field.type === "file" && (
                        <div>
                          <Upload
                            name="file"
                            action={`${process.env.REACT_APP_API_URL}/upload`}
                            headers={{
                              Authorization: `Bearer ${localStorage.getItem(
                                "token"
                              )}`,
                            }}
                            onChange={(info) => {
                              if (info?.file?.status === "done") {
                                form.setFieldsValue({
                                  [field.name]: info.file.response.url,
                                });
                              }
                            }}
                          >
                            <Button icon={<UploadOutlined />}>
                              Click to upload
                            </Button>
                          </Upload>
                          {form.getFieldValue(field.name) && (
                            <div
                              style={{
                                marginTop: 10,

                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              {form
                                .getFieldValue(field.name)
                                .includes(".jpg") ? (
                                <div
                                  style={{
                                    width: "100%",
                                    height: 100,
                                    borderRadius: 8,
                                    backgroundColor: "lightgray",
                                    backgroundImage: `url(${
                                      initialValues[field.name + "_signed"]
                                    })`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                  }}
                                  onClick={() => {
                                    window.open(
                                      initialValues[field.name + "_signed"],
                                      "_blank"
                                    );
                                  }}
                                ></div>
                              ) : (
                                <DownloadOutlined />
                              )}
                              <a
                                href={initialValues[field.name + "_signed"]}
                                target="_blank"
                              >
                                Download file
                              </a>
                            </div>
                          )}
                        </div>
                      )}
                      {field.type === "relationship" && (
                        <Select
                          showSearch
                          placeholder="Select related object"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          {relatedObjects[field.name]?.map((relatedObject) => {
                            // Only show options for related objects that exist
                            if (!relatedObject) return null;
                            return (
                              <Option
                                key={relatedObject.id}
                                value={relatedObject.id}
                              >
                                {relatedObject.name ||
                                  relatedObject.title ||
                                  relatedObject.Name ||
                                  relatedObject.Title ||
                                  relatedObject.firstName ||
                                  relatedObject.FirstName ||
                                  relatedObject.lastName ||
                                  relatedObject.LastName ||
                                  relatedObject.email ||
                                  relatedObject.Email ||
                                  relatedObject.subject ||
                                  relatedObject.Subject ||
                                  relatedObject.description ||
                                  relatedObject.Description ||
                                  relatedObject.body ||
                                  relatedObject.Body ||
                                  relatedObject.content ||
                                  relatedObject.Content}
                              </Option>
                            );
                          })}
                        </Select>
                      )}
                    </Form.Item>
                  ))}
                <Form.Item>
                  {id === "new" ? (
                    <Space style={{ marginTop: 20 }}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        onClick={() => {
                          form.submit();
                          navigate(`/${objectType}`);
                        }}
                      >
                        Save
                      </Button>
                      <Button onClick={() => navigate(`/${objectType}`)}>
                        Cancel
                      </Button>
                    </Space>
                  ) : (
                    <Space style={{ marginTop: 20 }}>
                      <Button type="primary" htmlType="submit">
                        Save
                      </Button>
                      <Button danger onClick={showDeleteConfirm}>
                        Delete
                      </Button>
                    </Space>
                  )}
                </Form.Item>
              </Form>
              <div>
                {/* FileManager Component */}
                <FileManager
                  objectId={id}
                  teamId={teamId}
                  showFileManager={showFileManager}
                />
              </div>
            </div>
          </div>

          {id && id !== "new" && (
            <div
              style={{
                flex: 3,
                height: "calc(100% - 0px)",
                display: "flex",
                width: "calc(100% - 20px)",
                flexDirection: showBoard ? "row-reverse" : "row",
              }}
            >
              {/* Activity Box */}
              <div
                style={{
                  flex: showBoard ? (isDetailsCollapsed ? 1 : 0.01) : 2,
                  position: "relative",
                }}
              >
                {isDetailsCollapsed || !showBoard ? (
                  <ActivityLog
                    objectId={id}
                    activityLog={activityLog}
                    fetchActivityLog={fetchActivityLog}
                    teamMembers={teamMembers}
                    users={teamMembers}
                    team={team}
                    agents={agents}
                    agentTeams={agentTeams}
                    selectedTask={selectedTask}
                    closeSelectedTask={() => setSelectedTask(null)}
                  />
                ) : (
                  <>
                    <div
                      style={{
                        width: 60,
                        height: 60,
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "white",
                          padding: 10,
                          width: 40,
                          height: 40,
                          fontSize: 18,
                          borderRadius: 8,
                          marginTop: 4,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setIsDetailsCollapsed(true);
                        }}
                      >
                        <MessageOutlined />
                      </div>
                    </div>
                  </>
                )}
              </div>
              {/* Task Box */}
              <div
                style={{
                  flex: isTaskCollapsed
                    ? 0.1
                    : isDetailsCollapsed
                    ? showBoard
                      ? 2
                      : 1
                    : 1,
                }}
              >
                <div
                  style={{
                    padding: 10,
                    zIndex: 100,
                    paddingLeft: 20,
                    marginBottom: -52,
                  }}
                >
                  {isTaskCollapsed ? (
                    <div
                      style={{
                        padding: 10,
                        backgroundColor: "white",
                        borderRadius: 8,
                      }}
                    >
                      <Button
                        type="text"
                        style={{
                          backgroundColor: "#F5F5F5",
                        }}
                        icon={<CaretDownOutlined />}
                        onClick={toggleTaskCollapse}
                      />
                    </div>
                  ) : (
                    <Button
                      style={{
                        backgroundColor: "#F5F5F5",
                      }}
                      type="text"
                      icon={<CaretRightOutlined />}
                      onClick={toggleTaskCollapse}
                    />
                  )}
                </div>
                <div
                  style={{
                    display: isTaskCollapsed ? "none" : "block",
                  }}
                >
                  <TodoList
                    thingId={id}
                    agents={agents}
                    users={teamMembers}
                    onTaskSelect={handleTaskSelect}
                    selectedTaskId={selectedTask ? selectedTask.taskId : null}
                    updated={() => fetchActivityLog(id)}
                    showBoard={showBoard}
                    setShowBoard={(e) => {
                      setIsDetailsCollapsed(e);
                      setShowBoard(e);
                      localStorage.setItem("showBoard-" + objectType, e);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Thing;
