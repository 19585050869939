import { Card, notification, Skeleton, Tag, Button, Popconfirm } from "antd";
import Title from "antd/es/skeleton/Title";
import React, { useEffect, useState } from "react";
import Markdown from "react-markdown";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { objectTypesState, teamMembersState } from "../state";
import axios from "axios";

function ActivityFeed() {
  const [activityLog, setActivityLog] = useState([]);
  const [loadingLogs, setLoadingLogs] = useState(false);
  const [objectTypes, setObjectTypes] = useRecoilState(objectTypesState);
  const [teamMembers, setTeamMembers] = useRecoilState(teamMembersState);
  const [agents, setAgents] = useState([]);

  const { teamId } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    setLoadingLogs(true);
    fetchActivityLogs();
    fetchAgents();
  }, []);

  const fetchActivityLogs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/activity-logs/team/${teamId}?teamId=${teamId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      let logData = response?.data?.data || [];

      setActivityLog(logData);

      console.log("logData", logData);
    } catch (error) {
      notification.error({
        message: "Error fetching activity logs",
        description: error.message,
      });
    } finally {
      // Add a delay to the loading state
      setLoadingLogs(false);
    }
  };

  const fetchAgents = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/teams/${teamId}/agents?teamId=${teamId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setAgents(response?.data?.agents || []);
    } catch (error) {
      notification.error({
        message: "Error fetching team members",
        description: error.message,
      });
    }
  };

  // Delete activity log
  const deleteActivityLog = async (logId) => {
    try {
      await axios
        .delete(
          `${process.env.REACT_APP_API_URL}/activity-logs/${logId}?teamId=${teamId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          console.log("response", response);
        });

      // Remove the deleted log from the state
      setActivityLog((prevLogs) =>
        prevLogs?.filter((log) => log.logId !== logId)
      );

      notification.success({
        message: "Activity log deleted successfully",
      });
    } catch (error) {
      notification.error({
        message: "Error deleting activity log",
        description: error.message,
      });
    }
  };

  return (
    <div>
      <h1>Activity Feed</h1>

      <div
        style={{
          display: "flex",
          gap: 24,
        }}
      >
        <div
          style={{
            flex: 1,
          }}
        >
          <Title level={2}>Team Activity Logs</Title>
          <Skeleton loading={loadingLogs}>
            <div>
              {activityLog &&
                activityLog.length !== 0 &&
                activityLog.map((log) => {
                  let image = "";

                  if (log.userId && agents && log.userId.includes("agent")) {
                    let aId = log.userId.split(":")[1];
                    let agent = agents?.filter((x) => {
                      return x.agentId === aId;
                    })[0];
                    image = "/avatars/" + agent?.avatar + ".png";
                  }

                  if (log.userId && !log.userId.includes("agent")) {
                    let user = teamMembers?.filter((x) => {
                      return x.userId === log.userId;
                    })[0];
                    image = user?.picture;
                  }

                  return (
                    <Card
                      key={log.id}
                      style={{ marginBottom: 16 }}
                      bordered={false}
                      hoverable
                      onClick={() => {
                        if (log.object && log.object.id) {
                          navigate(
                            `/${
                              objectTypes &&
                              objectTypes.filter((x) => {
                                return x.id === log?.object?.objectTypeId;
                              })[0]?.name
                            }/${log.object?.id}`
                          );
                        }
                      }}
                    >
                      <div style={{ display: "flex", gap: 10 }}>
                        <div
                          style={{
                            backgroundImage: `url(${image})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            width: 60,
                            height: 60,
                            borderRadius: 12,
                            backgroundColor: "lightgray",
                          }}
                        ></div>
                        <div style={{ flex: 1 }}>
                          <div
                            style={{
                              fontWeight: "bold",
                              fontSize: "1.1em",
                            }}
                          >
                            {log.title}
                            <div>
                              {log.object?.name ||
                                log.object?.Name ||
                                log.object?.title ||
                                log.object?.Title ||
                                log.object?.company ||
                                log.object?.Company ||
                                log.object?.firm ||
                                log.object?.Firm ||
                                log.object?.email ||
                                log.object?.Email ||
                                objectTypes?.filter((x) => {
                                  return x.id === log?.object?.objectTypeId;
                                })[0]?.name}
                            </div>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              right: 0,
                              top: 10,
                            }}
                          >
                            <Tag color="blue">
                              {objectTypes &&
                                objectTypes.filter((x) => {
                                  return x.id === log.object?.objectTypeId;
                                })[0]?.name}
                            </Tag>
                          </div>
                          <div
                            style={{
                              color: "gray",
                              fontSize: "0.9em",
                              marginBottom: 8,
                            }}
                          >
                            <b>
                              {(log.userId &&
                                teamMembers &&
                                teamMembers.filter((x) => {
                                  return x.userId === log.userId;
                                })[0]?.name) ||
                                log.userId}
                            </b>{" "}
                            {" - "}
                            {new Date(log.timestamp).toLocaleString()}
                          </div>

                          <Markdown>{log.body || " "}</Markdown>

                          {/* Add delete button */}
                          <Popconfirm
                            title="Are you sure you want to delete this activity?"
                            onConfirm={() => deleteActivityLog(log.logId)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button type="link" danger>
                              Delete
                            </Button>
                          </Popconfirm>
                        </div>
                      </div>
                    </Card>
                  );
                })}
            </div>
          </Skeleton>
        </div>
      </div>
    </div>
  );
}

export default ActivityFeed;
