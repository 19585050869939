// src/pages/Automations.js

import React, { useEffect } from "react";
import AutomationCards from "../components/AutomationCards";
import { useRecoilState } from "recoil";
import { teamState } from "../state";

const Automations = () => {
  const [team, setTeam] = useRecoilState(teamState);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 20,
        padding: 10,
        flex: 1,
      }}
    >
      <div
        style={{
          display: "flex",
          fontSize: 30,
          fontWeight: "bold",
          marginBottom: 10,
          paddingLeft: 30,
        }}
      >
        App Settings
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          gap: 10,
          flexWrap: "wrap",
          paddingLeft: 30,
        }}
      >
        <AutomationCards team={team} />
      </div>
    </div>
  );
};

export default Automations;
