import React, { useState, useEffect } from "react";
import { Form, Input, Button, List, Avatar, notification } from "antd";
import axios from "axios";
import { useParams } from "react-router-dom";
import { profileState, teamMembersState, teamState } from "../state";
import { useRecoilState } from "recoil";

const TeamMembers = () => {
  const [members, setMembers] = useRecoilState(teamMembersState);
  const [loading, setLoading] = useState(false);
  const [inviting, setInviting] = useState(false);
  const [inviteEmail, setInviteEmail] = useState("");
  const [team, setTeam] = useRecoilState(teamState);
  const [profile, setProfile] = useRecoilState(profileState);

  const { teamId } = useParams();

  const handleInvite = async () => {
    if (!inviteEmail) {
      return;
    }

    setInviting(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/invite?teamId=" + teamId,

        { email: inviteEmail },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      notification.success({
        message: "Success",
        description: `Invite sent to ${inviteEmail}`,
      });
      setInviteEmail("");
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to send invite",
      });
    } finally {
      setInviting(false);
    }
  };

  const handelLeaveTeam = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL +
          "/teams/" +
          teamId +
          "/leave?teamId=" +
          teamId,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      localStorage.removeItem("teamId");
      window.location.href = "/";
      notification.success({
        message: "Success",
        description: `You have left the team`,
      });
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to leave team",
      });
    }
  };

  const handelRemoveMember = async (member) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL +
          "/teams/" +
          teamId +
          "/remove?teamId=" +
          teamId,
        { userId: member.userId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      notification.success({
        message: "Success",
        description: `Removed ${member.name} from the team`,
      });
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to remove member",
      });
    }
  };

  const handelDeleteTeam = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL +
          "/teams/" +
          teamId +
          "/delete?teamId=" +
          teamId,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      notification.success({
        message: "Success",
        description: `Team deleted`,
      });
      localStorage.removeItem("teamId");
      window.location.href = "/";
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to delete team",
      });
    }
  };

  return (
    <div
      style={{
        padding: 24,
      }}
    >
      <h1>{team?.name}</h1>
      <h1>Team Members</h1>
      <List
        itemLayout="horizontal"
        dataSource={members}
        loading={loading}
        renderItem={(member) => (
          <List.Item
            actions={
              team &&
              team.role &&
              team.role == "owner" &&
              member.userId !== profile.userId
                ? [
                    <Button
                      type="text"
                      danger
                      onClick={() => {
                        handelRemoveMember(member);
                      }}
                    >
                      Remove
                    </Button>,
                  ]
                : [
                    member.userId === profile.userId &&
                    team.role !== "owner" ? (
                      <Button
                        type="text"
                        danger
                        onClick={() => {
                          handelLeaveTeam();
                        }}
                      >
                        Leave
                      </Button>
                    ) : null,
                  ]
            }
          >
            <List.Item.Meta
              avatar={<Avatar src={member.picture} />}
              title={member.name}
              description={member.email}
            />
          </List.Item>
        )}
      />

      <Form layout="inline" onFinish={handleInvite}>
        <Form.Item>
          <Input
            placeholder="Invite by email"
            value={inviteEmail}
            onChange={(e) => setInviteEmail(e.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={inviting}
            onClick={handleInvite}
          >
            Invite
          </Button>
        </Form.Item>
      </Form>

      {team && team.role && team.role == "owner" && (
        <div
          style={{
            marginTop: 60,
          }}
        >
          <Button
            danger
            onClick={() => {
              handelDeleteTeam();
            }}
          >
            Delete Team
          </Button>
        </div>
      )}
    </div>
  );
};

export default TeamMembers;
