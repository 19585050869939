import React, { useEffect, useState } from "react";
import { Button, Input, message, Select, Spin, List } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import ToolsConfiguration from "../agents/ToolsConfiguration";
import AvatarPicker from "../components/AvatarPicker";
import { useRecoilState } from "recoil";
import { profileState, teamState } from "../state";
import Markdown from "react-markdown";

const { Option } = Select;

const ManageAgentPage = () => {
  const { agentId } = useParams();
  const [loading, setLoading] = useState(false);
  const [agentData, setAgentData] = useState({
    name: "",
    description: "",
    modelType: "", // Default model
    tools: [], // Initialize tools as an empty array
    instructions: "", // New field to capture instructions for the agent
    maxToolRoundtrips: 5, // Default max tool round trips
    avatar: "", // Default avatar
  });
  const [messages, setMessages] = useState([]); // Store conversation history
  const [currentMessage, setCurrentMessage] = useState(""); // Store current input message
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(profileState);

  const [teamData] = useRecoilState(teamState);

  useEffect(() => {
    fetchAgent();
    console.log("teamData", teamData);
    console.log("user", user);
  }, []);

  const fetchAgent = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_API_URL
        }/agent/${agentId}?teamId=${localStorage.getItem("teamId")}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      // Ensure the response contains the required fields
      setAgentData({
        ...response.data,
        tools: response.data.tools || [], // Fetch tools or default to an empty array
      });
    } catch (error) {
      message.error("Error fetching agent data.");
      console.error(error);
    }
    setLoading(false);
  };

  const handleSaveChanges = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/agent/${agentId}`,
        agentData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      message.success("Agent updated successfully!");
      // navigate("/agents");
    } catch (error) {
      message.error("Error updating agent.");
      console.error(error);
    }
  };

  const handleSaveTool = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/agent/${agentId}`,
        agentData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      message.success("Agent updated successfully!");
      // navigate("/agents");
    } catch (error) {
      message.error("Error updating agent.");
      console.error(error);
    }
  };

  const handleSendMessage = async () => {
    if (!currentMessage.trim()) return;

    // Add user's message to the chat
    const newMessages = [...messages, { sender: "user", text: currentMessage }];
    setMessages(newMessages);

    try {
      // Send message to the backend and get response
      const response = await axios.post(
        `${
          process.env.REACT_APP_API_URL
        }/agent/${agentId}/interact?teamId=${localStorage.getItem("teamId")}`,
        {
          messages: [currentMessage],
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      // Append agent's response to the chat
      setMessages([
        ...newMessages,
        { sender: "agent", text: response.data.data },
      ]);
    } catch (error) {
      message.error("Error interacting with the agent.");
      console.error(error);
    }

    // Clear the input field
    setCurrentMessage("");
  };

  if (loading) {
    return <Spin />;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: 32,
      }}
    >
      <div style={{ maxWidth: 600 }}>
        <h1>Manage Agent</h1>
        <AvatarPicker
          value={agentData.avatar}
          onAvatarSelect={(value) => {
            setAgentData({ ...agentData, avatar: value });
          }}
        />
        {/* Input field for Agent Name */}
        <Input
          placeholder="Agent Name"
          value={agentData.name}
          onChange={(e) => setAgentData({ ...agentData, name: e.target.value })}
          style={{ marginBottom: 16 }}
        />
        {/* Input field for Agent Description */}
        <Input
          placeholder="Description"
          value={agentData.description}
          onChange={(e) =>
            setAgentData({ ...agentData, description: e.target.value })
          }
          style={{ marginBottom: 16 }}
        />
        {/* Select field for agent is visible */}
        <Select
          placeholder="Is Visible"
          value={agentData.isVisible}
          onChange={(value) => setAgentData({ ...agentData, isVisible: value })}
          style={{ width: "100%", marginBottom: 16 }}
        >
          <Option value={true}>Yes</Option>
          <Option value={false}>No</Option>
        </Select>
        {/* Input field for Agent Instructions */}
        <Input.TextArea
          label="Agent Instructions"
          placeholder="Agent Instructions"
          value={agentData.instructions}
          onChange={(e) =>
            setAgentData({ ...agentData, instructions: e.target.value })
          }
          style={{ marginBottom: 16 }}
          rows={4}
        />
        {/* Input for selecting the model type */}
        <Select
          placeholder="Select Model"
          value={agentData.modelType}
          onChange={(value) => setAgentData({ ...agentData, modelType: value })}
          style={{ width: "100%", marginBottom: 16 }}
        >
          {teamData && teamData.openaiApiKey && (
            <>
              <Option value="gpt-4">GPT-4</Option>
              <Option value="gpt-4o">GPT-4o</Option>
              <Option value="gpt-4o-mini">GPT-4o-mini</Option>
              <Option value="gpt-3.5-turbo">GPT-3.5 Turbo</Option>
              <Option value="gpt-3.5">GPT-3.5</Option>
              <Option value="gpt-3">GPT-3</Option>
              <Option value="ollama">ollama</Option>
            </>
          )}
          {teamData && teamData.anthropticApiKey && (
            <>
              <Option value="claude-instant">Claude Instant</Option>
              <Option value="openhermes">OpenHermes</Option>
            </>
          )}
        </Select>
        {/* Input field for Max Tool Roundtrips */}
        Max Tool Roundtrips
        <Input
          placeholder="Max Tool Roundtrips"
          value={agentData.maxToolRoundtrips}
          onChange={(e) =>
            setAgentData({ ...agentData, maxToolRoundtrips: e.target.value })
          }
          style={{ marginBottom: 16 }}
        />
        {/* Save Changes */}
        <div style={{ marginTop: 16, marginBottom: 100 }}>
          <Button type="primary" onClick={handleSaveChanges}>
            Save Changes
          </Button>
          <Button style={{ marginLeft: 8 }} onClick={() => navigate("/agents")}>
            Cancel
          </Button>
          <Button
            style={{ marginLeft: 8 }}
            danger
            onClick={() => {
              axios.delete(
                `${
                  process.env.REACT_APP_API_URL
                }/agent/${agentId}?teamId=${localStorage.getItem("teamId")}`,
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                }
              );
              navigate("/agents");
            }}
          >
            Delete
          </Button>{" "}
          {/* Integrate Tools Configuration */}
          <ToolsConfiguration
            value={agentData.tools}
            onChange={(tools) => {
              setAgentData({ ...agentData, tools });
              setTimeout(() => {
                handleSaveTool();
              }, 1000);
            }}
          />
          <div
            style={{
              marginTop: 16,
              border: "1px solid #eaeaea",
              padding: 16,
              borderRadius: 8,
            }}
          >
            <div>
              <h3>Trigger</h3>

              <div>
                POST:
                <div>
                  https://{window.location.host}/webhook/agent-prompt/{agentId}
                </div>
                <br />
                HEADER:
                <div>
                  <code>Authorization</code> - Bearer &lt;user-api-key&gt;
                </div>
                <br />
                <div>
                  BODY:
                  <br />
                  <code>
                    {`
{
  "example": "Web Hook Body"
}
                    `}
                  </code>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Chat Box */}
      </div>
      <div
        style={{
          flex: 1,
        }}
      >
        <h2>Chat with Agent</h2>
        <div
          style={{
            border: "1px solid #eaeaea",
            borderRadius: 8,
            padding: 16,
            height: "calc(100vh - 300px)",
            overflowY: "auto",
            marginBottom: 16,
            flex: 1,
            width: "100%",
          }}
        >
          <List
            dataSource={messages}
            renderItem={(item) => (
              <List.Item>
                <div
                  style={{
                    display: "flex",
                    gap: 16,
                  }}
                >
                  <div
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: 8,
                      border: "3px solid #eaeaea",
                      backgroundImage:
                        item.sender === "agent"
                          ? `url(/avatars/${agentData.avatar}.png)`
                          : `url(${user.picture})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      marginRight: 16,
                    }}
                  ></div>
                  <div>
                    <b>{item.sender === "agent" ? "Agent: " : "You: "} </b>
                    <Markdown>{item.text}</Markdown>
                  </div>
                </div>
              </List.Item>
            )}
          />
        </div>

        <Input
          placeholder="Type your message here..."
          value={currentMessage}
          onChange={(e) => setCurrentMessage(e.target.value)}
          onPressEnter={handleSendMessage}
          style={{ marginBottom: 16 }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button type="primary" onClick={handleSendMessage}>
            Send Message
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ManageAgentPage;
