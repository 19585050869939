import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  Spin,
  message,
  Modal,
  Input,
  Upload,
  Avatar,
} from "antd";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AvatarPicker from "../components/AvatarPicker";

const AgentTeamsPage = () => {
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [teamName, setTeamName] = useState("");
  const [teamAvatar, setTeamAvatar] = useState("");
  const [maxTasks, setMaxTasks] = useState(5);
  const [agents, setAgents] = useState();
  const navigate = useNavigate();

  const teamId = localStorage.getItem("teamId");

  useEffect(() => {
    fetchTeams();
  }, []);

  const fetchTeams = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/agents/teams?teamId=${teamId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setTeams(response.data.teams);
    } catch (error) {
      message.error("Error fetching teams");
    }
    setLoading(false);
  };

  const createTeam = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/agents/teams/create?teamId=${teamId}`,
        {
          name: teamName,
          avatar: teamAvatar,
          maxTasks,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      message.success("Team created successfully!");
      setIsModalOpen(false);
      fetchTeams();
    } catch (error) {
      message.error("Error creating team");
    }
  };

  const columns = [
    {
      title: "Team Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Max Tasks",
      dataIndex: "maxTasks",
      key: "maxTasks",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, team) => (
        <Button onClick={() => navigate(`/agents/teams/${team.id}`)}>
          Manage
        </Button>
      ),
    },
  ];

  return (
    <div>
      <h1>AI Teams</h1>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => setIsModalOpen(true)}
      >
        Create New Team
      </Button>

      <Modal
        title="Create a New Team"
        visible={isModalOpen}
        onOk={createTeam}
        onCancel={() => setIsModalOpen(false)}
      >
        <Input
          placeholder="Team Name"
          value={teamName}
          onChange={(e) => setTeamName(e.target.value)}
          style={{ marginBottom: 16 }}
        />
        <AvatarPicker
          team={true}
          value={teamAvatar}
          onAvatarSelect={(value) => setTeamAvatar(value)}
        />
        Max Team Tasks
        <Input
          placeholder="Max Number of Tasks"
          type="number"
          value={maxTasks}
          onChange={(e) => setMaxTasks(e.target.value)}
          style={{ marginTop: 16 }}
        />
      </Modal>

      {loading ? (
        <Spin />
      ) : (
        <Table columns={columns} dataSource={teams} rowKey="teamId" />
      )}
    </div>
  );
};

export default AgentTeamsPage;
