import React from "react";
import { Route, Navigate } from "react-router-dom";
import { useGoogleAuth } from "./GoogleAuthProvider";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { user, isAuthenticating } = useGoogleAuth();

  if (isAuthenticating) {
    return <div>Loading...</div>;
  }

  return user ? <Component {...rest} /> : <Navigate to="/signin" />;
};

export default ProtectedRoute;
