import React from "react";
import { Typography, Collapse } from "antd";
import apiDocs from "../apiDocs";
import ReactMarkdown from "react-markdown";

const { Title, Paragraph } = Typography;
const { Panel } = Collapse;

const ApiDocumentation = () => {
  function RequestHeaders() {
    return (
      <div>
        <strong>Request Headers:</strong>
        <ul>
          <li>
            <code>Content-Type</code> - application/json
          </li>
          <li>
            <code>Authorization</code> - Bearer &lt;user-api-key&gt;
          </li>
        </ul>
      </div>
    );
  }

  function EndpointCard({
    title,
    method,
    path,
    description,
    requestParams = [],
    requestBody,
    responseExample,
  }) {
    return (
      <div>
        <Title level={4}>
          {method} {path}
        </Title>
        <ReactMarkdown>{description}</ReactMarkdown>
        <RequestHeaders />
        {requestParams.length > 0 && (
          <>
            <strong>Request Parameters:</strong>
            <ul>
              {requestParams.map((param, idx) => (
                <li key={idx}>
                  <code>{param.name}</code> - {param.description}
                </li>
              ))}
            </ul>
          </>
        )}
        {requestBody && (
          <>
            <strong>Request Body:</strong>
            <pre>{requestBody}</pre>
          </>
        )}
        {responseExample && (
          <>
            <strong>Response Example:</strong>
            <pre>{responseExample}</pre>
          </>
        )}
      </div>
    );
  }

  return (
    <div style={{ padding: "24px", maxWidth: "1200px", margin: "auto" }}>
      <Typography>
        <Title>{apiDocs.title}</Title>
        <ReactMarkdown>{apiDocs.description}</ReactMarkdown>
        <Collapse
          accordion
          style={{
            marginBottom: "20px",
          }}
        >
          {apiDocs.details.map((detail, idx) => (
            <Panel header={` ${detail.title}`} key={idx}>
              <ReactMarkdown>{detail.description}</ReactMarkdown>
            </Panel>
          ))}
        </Collapse>
        <Collapse accordion>
          {apiDocs.endpoints.map((endpoint, idx) => (
            <Panel header={` ${endpoint.title}`} key={idx}>
              <EndpointCard {...endpoint} />
            </Panel>
          ))}
        </Collapse>
      </Typography>
    </div>
  );
};

export default ApiDocumentation;
