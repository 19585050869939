import { useGoogleLogin } from "@react-oauth/google";
import { Button } from "antd";
import React from "react";
import { UserOutlined } from "@ant-design/icons";

const SignIn = () => {
  const login = useGoogleLogin({
    onSuccess: async (response) => {
      const authorizationCode = response.code;
      console.log("Authorization Code:", response);

      try {
        const res = await fetch(
          process.env.REACT_APP_API_URL + "/auth/google/callback",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ code: authorizationCode }),
          }
        );

        if (res.ok) {
          const data = await res.json();
          console.log("Tokens received:", data);
          localStorage.setItem("token", data.idToken);
          localStorage.setItem("googleAuthToken", data.accessToken);
          localStorage.setItem("refreshToken", data.refreshToken);
          window.location.href = "/";
        } else {
          console.error("Failed to exchange authorization code for tokens");
        }
      } catch (error) {
        console.error("Error exchanging authorization code:", error);
      }
    },
    onError: () => {
      console.error("Login Failed");
    },
    flow: "auth-code",
    redirectUri: process.env.REACT_APP_API_URL + "/auth/google/callback",
    scope:
      "email profile openid https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/gmail.compose https://www.googleapis.com/auth/gmail.labels https://www.googleapis.com/auth/calendar",
  });

  return (
    <div
      style={{
        padding: 24,
        marginLeft: "auto",
        marginRight: "auto",
        width: 340,
        justifyItems: "center",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        border: "1px solid #eaeaea",
        borderRadius: 8,
        backgroundColor: "white",
        paddingBottom: 60,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: 16,
          marginTop: 20,
          border: "1px solid #eaeaea",
          borderRadius: 8,
          width: "fit-content",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <UserOutlined style={{ fontSize: 44 }} />
      </div>
      <h2>Sign In </h2>
      <Button onClick={login}>Sign in with Google</Button>
      <div>
        <h3>Warning</h3>
        <p>
          By signing in you understand this is just demo and you are using at
          your own risk.{" "}
        </p>
        <br />
        <p>
          This app is still in development and not ready for production use.
        </p>
        <br />
        <p>
          We do not have approval from Google yet to use the app in production
          so you will be warned by Google that this app may be unsafe. Select
          Advanced and then Go to Qiiver (unsafe) to proceed.
        </p>
      </div>
    </div>
  );
};

export default SignIn;
