import React, { useEffect, useRef, useState } from "react";
import EditorJS from "@editorjs/editorjs";
import axios from "axios";
import "./Wiki.css";
import { useNavigate, useParams } from "react-router-dom";
import Paragraph from "@editorjs/paragraph";
import { Button, Skeleton } from "antd";
import { DeleteOutlined, SaveFilled } from "@ant-design/icons";

const WikiNew = () => {
  const editorInstance = useRef(null);
  const [isEditing, setIsEditing] = useState(true);
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState();
  const [resetting, setResetting] = useState(false);

  const { wikiId } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the saved content from the backend when the component mounts
    setResetting(true);
    setTimeout(() => {
      setResetting(false);
    }, 100);
  }, [wikiId]);

  const handleSave = async () => {
    try {
      console.log("Sending data to the backend...");
      await axios
        .post(
          process.env.REACT_APP_API_URL + "/wikis",
          {
            title: title,
            content: "[]",
            teamId: localStorage.getItem("teamId"),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          window.location.href = `/wiki/${response.data.wikiId}`;
        });

      setIsEditing(false);
    } catch (error) {
      console.error("Error saving content:", error);
    } finally {
    }
  };

  return (
    <div
      style={{
        width: "calc(100% )",
        backgroundColor: "white",

        overflow: loading ? "hidden" : "auto",
        borderRadius: 8,
      }}
    >
      <>
        {loading && (
          <div
            style={{
              height: "100vh",
              padding: "20px",
            }}
          >
            <Skeleton active />
          </div>
        )}
        <div
          style={{
            display: "flex",
          }}
        >
          <div
            style={{
              flex: 1,
            }}
          >
            <input
              type="text"
              placeholder="Enter New title"
              style={{
                width: "100%",
                padding: "10px",

                border: "0px solid #ccc",
                fontSize: "28px",
                paddingLeft: "60px",
                fontWeight: 900,
              }}
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  handleSave();
                }
              }}
            />
          </div>
          <div
            style={{
              padding: "10px",
            }}
          >
            <Button
              icon={<SaveFilled />}
              onClick={() => {
                handleSave();
              }}
            >
              Create
            </Button>
          </div>
        </div>
      </>
    </div>
  );
};

export default WikiNew;
