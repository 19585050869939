import React, { useState } from "react";

const avatars = [
  { id: "a", src: "/avatars/a.png" },
  { id: "b", src: "/avatars/b.png" },
  { id: "c", src: "/avatars/c.png" },
  { id: "d", src: "/avatars/d.png" },
  { id: "e", src: "/avatars/e.png" },
  { id: "f", src: "/avatars/f.png" },
  { id: "g", src: "/avatars/g.png" },
  { id: "h", src: "/avatars/h.png" },
  { id: "i", src: "/avatars/i.png" },
  { id: "j", src: "/avatars/j.png" },
  { id: "k", src: "/avatars/k.png" },
  { id: "l", src: "/avatars/l.png" },
  { id: "m", src: "/avatars/m.png" },
  { id: "n", src: "/avatars/n.png" },
  { id: "o", src: "/avatars/o.png" },
  { id: "p", src: "/avatars/p.png" },
  { id: "q", src: "/avatars/q.png" },
  { id: "r", src: "/avatars/r.png" },
  { id: "s", src: "/avatars/s.png" },
  { id: "t", src: "/avatars/t.png" },
  { id: "u", src: "/avatars/u.png" },
  { id: "v", src: "/avatars/v.png" },
  { id: "x", src: "/avatars/x.png" },
  { id: "y", src: "/avatars/y.png" },
  { id: "z", src: "/avatars/z.png" },
  { id: "aa", src: "/avatars/aa.png" },
  { id: "ab", src: "/avatars/ab.png" },
  { id: "ac", src: "/avatars/ac.png" },
  { id: "ad", src: "/avatars/ad.png" },
  { id: "ae", src: "/avatars/ae.png" },
];

const teamAvatars = [
  { id: "a", src: "/team-avatars/a.png" },
  { id: "b", src: "/team-avatars/b.png" },
  { id: "c", src: "/team-avatars/c.png" },
  { id: "d", src: "/team-avatars/d.png" },
  { id: "e", src: "/team-avatars/e.png" },
  { id: "f", src: "/team-avatars/f.png" },
  { id: "g", src: "/team-avatars/g.png" },
  { id: "h", src: "/team-avatars/h.png" },
  { id: "i", src: "/team-avatars/i.png" },
  { id: "j", src: "/team-avatars/j.png" },
  { id: "k", src: "/team-avatars/k.png" },
  { id: "l", src: "/team-avatars/l.png" },
  { id: "m", src: "/team-avatars/m.png" },
  { id: "n", src: "/team-avatars/n.png" },
  { id: "o", src: "/team-avatars/o.png" },
  { id: "p", src: "/team-avatars/p.png" },
  { id: "q", src: "/team-avatars/q.png" },
  { id: "r", src: "/team-avatars/r.png" },
  { id: "s", src: "/team-avatars/s.png" },
  { id: "t", src: "/team-avatars/t.png" },
  { id: "u", src: "/team-avatars/u.png" },
  { id: "v", src: "/team-avatars/v.png" },
  { id: "x", src: "/team-avatars/x.png" },
  { id: "y", src: "/team-avatars/y.png" },
  { id: "z", src: "/team-avatars/z.png" },
  { id: "aa", src: "/team-avatars/aa.png" },
  { id: "ab", src: "/team-avatars/ab.png" },
  { id: "ac", src: "/team-avatars/ac.png" },
  { id: "ad", src: "/team-avatars/ad.png" },
  { id: "ae", src: "/team-avatars/ae.png" },
];

const AvatarPicker = ({ onAvatarSelect, value, team }) => {
  const [selectedAvatar, setSelectedAvatar] = useState(value);

  const handleAvatarClick = (avatar) => {
    setSelectedAvatar(avatar.id);
    onAvatarSelect(avatar.id); // Returning the selected image src
  };

  return (
    <div style={styles.container}>
      {team ? (
        <>
          {teamAvatars.map((avatar) => (
            <img
              key={avatar.id}
              src={avatar.src}
              alt={avatar.id}
              onClick={() => handleAvatarClick(avatar)}
              style={{
                ...styles.avatar,
                border:
                  selectedAvatar === avatar.id
                    ? "3px solid blue"
                    : "3px solid #eee",
              }}
            />
          ))}
        </>
      ) : (
        <>
          {avatars.map((avatar) => (
            <img
              key={avatar.id}
              src={avatar.src}
              alt={avatar.id}
              onClick={() => handleAvatarClick(avatar)}
              style={{
                ...styles.avatar,
                border:
                  selectedAvatar === avatar.id
                    ? "3px solid blue"
                    : "3px solid #eee",
              }}
            />
          ))}
        </>
      )}
    </div>
  );
};

// CSS-in-JS styles
const styles = {
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(100px, 1fr))",
    gap: 2,
    justifyItems: "center",
    marginBottom: 16,
  },
  avatar: {
    width: "50px",
    height: "50px",
    cursor: "pointer",
    transition: "border 0.3s ease",
    borderRadius: 8,
    backgroundColor: "#fff",
  },
};

export default AvatarPicker;
