import { UserOutlined, TeamOutlined } from "@ant-design/icons";

export const config = {
  region: process.env.REACT_APP_AWS_REGION,
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  ClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
  GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  objectTypes: {
    person: {
      icon: UserOutlined,
      fields: [
        { name: "name", label: "Name", type: "text", required: true },
        { name: "email", label: "Email", type: "email", required: true },
        { name: "phone", label: "Phone", type: "text", required: true },
        { name: "address", label: "Address", type: "text" },
        { name: "company", label: "Company", type: "text" },
        { name: "birthday", label: "Birthday", type: "date" },
        { name: "tags", label: "Tags", type: "tags" },
      ],
      listFields: ["name", "email", "tags"],
    },
    company: {
      icon: TeamOutlined,
      fields: [
        { name: "name", label: "Name", type: "text", required: true },
        { name: "email", label: "Email", type: "email", required: true },
        { name: "phone", label: "Phone", type: "text", required: true },
        { name: "address", label: "Address", type: "text" },
        { name: "tags", label: "Tags", type: "tags" },
      ],
      listFields: ["name", "email", "tags"],
    },
    // Add more object types if needed
  },
};

export default config;
