// src/components/AutomationCards.js

import React from "react";
import ImportCSVCard from "../automations/ImportCSVCard";
import ConfigureOpenAICard from "../automations/ConfigureOpenAICard";
import ConfigureSendGridCard from "../automations/ConfigureSendGrid";
import DuplicateMatcherCard from "../automations/DuplicateMatcherCard";
import ApiDocsCard from "../automations/ApiDocsCard";
import AnthropicConfigCard from "../automations/AnthropicConfigCard";
import CanvaConfigCard from "../automations/CanvaConfigCard";
import StripeConfigCard from "../automations/StripeConfigCard";

const AutomationCards = ({ team }) => {
  return (
    <>
      {team && team.teamId && (
        <>
          <ImportCSVCard />
          <ConfigureOpenAICard team={team} />
          <ConfigureSendGridCard team={team} />
          <DuplicateMatcherCard teamId={team?.teamId} />
          <ApiDocsCard />
          <AnthropicConfigCard team={team} />
          <StripeConfigCard team={team} />
          {/* <CanvaConfigCard team={team} />   Still needs a lot of work */}
        </>
      )}
    </>
  );
};

export default AutomationCards;
