import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Input,
  Button,
  Space,
  Tag,
  Skeleton,
  Popconfirm,
  message,
  Select,
} from "antd";
import {
  SearchOutlined,
  PlusOutlined,
  EditOutlined,
  DownloadOutlined,
  DeleteOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Things.css";
import * as Icons from "@ant-design/icons";
import ActionButtons from "../components/ActionButtons";
import DataTable from "../components/DataTable";
import CreateFilter from "../components/CreateFilter";
import SaveFilterButton from "../components/SaveFilterButton";
import SendGridCard from "../automations/SendGridCard";
import { useRecoilState } from "recoil";
import { teamIdState, teamMembersState, teamState } from "../state";

const { Option } = Select;

const Things = ({ objectType }) => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [fields, setFields] = useState([]);
  const [type, setType] = useState();
  const [filters, setFilters] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [relatedObjects, setRelatedObjects] = useState({});
  const [initialData, setInitialData] = useState([]);
  const [objectTypes, setObjectTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const navigate = useNavigate();
  const [visibleColumns, setVisibleColumns] = useState([]);

  const [teamMembers, setTeamMembers] = useRecoilState(teamMembersState);
  const [team, setTeam] = useRecoilState(teamState);
  const [teamId, setTeamId] = useRecoilState(teamIdState);

  const hasFetchedObjectTypes = useRef(false);

  useEffect(() => {
    const cachedData = localStorage.getItem(`things_${teamId}_${objectType}`);

    if (cachedData) {
      const parsedData = JSON.parse(cachedData);
      setData(parsedData.data);
      setInitialData(parsedData.data);
      setFields(parsedData.fields);
      setVisibleColumns(
        parsedData.fields
          .filter((x) => x.showOnTable)
          .map((field) => field.name)
      );
      setFilters(parsedData.filters);
      setObjectTypes(parsedData.objectTypes);
      setLoading(false);
    }

    if (hasFetchedObjectTypes.current) return;

    const fetchObjectTypes = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/object-types?teamId=${teamId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setObjectTypes(response.data.data);
        const objectTypeData = response.data.data.find(
          (item) => item.name === objectType
        );
        if (objectTypeData) {
          setType(objectTypeData);
          setFields([
            {
              label: "ID",
              name: "id",
              type: "text",
              required: true,
            },
            ...objectTypeData.fields,
          ]);
          setVisibleColumns([
            {
              label: "ID",
              name: "id",
              type: "text",
              required: true,
            },
            ...objectTypeData.fields
              .filter((x) => x.showOnTable)
              .map((field) => field.name),
          ]);
          setFilters(objectTypeData.filters || []);

          const objectsResponse = await axios.get(
            `${process.env.REACT_APP_API_URL}/objects/${objectTypeData.id}?teamId=${teamId}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          setData(objectsResponse.data.data);
          setInitialData(objectsResponse.data.data);
          fetchRelatedObjects(objectTypeData.fields, objectsResponse.data.data);

          // Cache the data in localStorage
          localStorage.setItem(
            `things_${teamId}_${objectType}`,
            JSON.stringify({
              data: objectsResponse.data.data,
              fields: objectTypeData.fields,
              filters: objectTypeData.filters || [],
              objectTypes: response.data.data,
            })
          );
        }
      } catch (error) {
        console.error("Error fetching object types or objects!", error);
      } finally {
        setLoading(false);
        hasFetchedObjectTypes.current = true;
      }
    };

    fetchObjectTypes();
  }, [objectType, teamId]);

  const fetchRelatedObjects = async (fields, data) => {
    try {
      const relatedObjectFields = fields.filter(
        (field) => field.type === "relationship"
      );

      const relatedObjectIds = relatedObjectFields.reduce((acc, field) => {
        data.forEach((item) => {
          if (item[field.name]) {
            acc[field.relatedObjectType] =
              acc[field.relatedObjectType] || new Set();
            acc[field.relatedObjectType].add(item[field.name]);
          }
        });
        return acc;
      }, {});

      const relatedObjectPromises = Object.keys(relatedObjectIds).map(
        async (relatedObjectType) => {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/objects/${relatedObjectType}?teamId=${teamId}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          return { relatedObjectType, objects: response.data.data };
        }
      );

      const results = await Promise.all(relatedObjectPromises);
      const relatedObjects = results.reduce((acc, result) => {
        acc[result.relatedObjectType] = result.objects;
        return acc;
      }, {});
      setRelatedObjects(relatedObjects);
    } catch (error) {
      console.error("Error fetching related objects!", error);
    }
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchText(searchValue);

    // Search the filtered data
    const searchResults = initialData.filter((item) =>
      fields.some((field) =>
        item[field.name]?.toString().toLowerCase().includes(searchValue)
      )
    );

    setData(searchResults);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log("selectedRows", selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const handleSaveFilter = (newFilters) => {
    setFilters(newFilters);
  };

  const handleDeleteConfirm = async () => {
    if (selectedRowKeys.length === 0) {
      message.error("Please select at least one item to delete.");
      return;
    }

    setLoading(true);

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/objects/bulk-delete`,
        { ids: selectedRowKeys, teamId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      message.success("Items deleted successfully.");
      setData((prevData) =>
        prevData.filter((item) => !selectedRowKeys.includes(item.id))
      );
      setSelectedRowKeys([]);
    } catch (error) {
      message.error("There was an error deleting the items.");
      console.error("There was an error deleting the items:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteCancel = () => {
    setSelectedRowKeys([]);
  };

  const handleFilterChange = (value) => {
    let f = type.filters[value];
    console.log("Selected filter:", value);

    setSelectedFilter(f);
    f && f.conditions && applyFilter(f.conditions);
  };

  const applyFilter = (newFilters) => {
    console.log("Applying filters:", newFilters);

    if (newFilters.length === 0) {
      setData(initialData);
      return;
    }

    const filteredData = data.filter((item) => {
      return newFilters.every((filter) => {
        const fieldValue = item[filter.field];

        if (Array.isArray(fieldValue)) {
          // If fieldValue is an array, check if any element in the array matches the filter value
          return fieldValue.some((val) =>
            val.toLowerCase().includes(filter.value.toLowerCase())
          );
        } else if (typeof fieldValue === "string") {
          // If fieldValue is a string, check if it matches the filter value
          return fieldValue.toLowerCase().includes(filter.value.toLowerCase());
        } else {
          // If fieldValue is neither an array nor a string, just return false (or customize as needed)
          return false;
        }
      });
    });

    setData(filteredData);
  };

  const handleAddFilter = async (xfilter) => {
    setSelectedFilter(null);
    const newFilters = [...filters, xfilter];
    setFilters(newFilters);
    applyFilter(xfilter);
  };

  const handleRemoveFilter = async (xfilter) => {
    setSelectedFilter(null);
    setFilters(xfilter);
    setTimeout(() => {
      applyFilter(xfilter);
    }, 100);
  };

  const handleDeleteFilter = async (xfilter) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/object-type/${
          type.id
        }/filter?teamId=${localStorage.getItem("teamId")}&filterName=${
          xfilter.name
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      message.success(response.data.message);
    } catch (error) {
      message.error("Error deleting filter");
      console.error("Error deleting filter:", error);
    }
    setSelectedFilter(null);
    const newFilters = filters.filter((filter) => filter.name !== xfilter.name);
    const newType = { ...type, filters: newFilters };
    setType(newType);

    setFilters(newFilters);
    applyFilter(newFilters);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignContent: "flex-start",
          width: "100%",
          marginBottom: 12,

          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            gap: 10,
            alignItems: "flex-end",
          }}
        >
          <div>
            {selectedFilter && selectedFilter.conditions.length > 0 && (
              <div style={{ marginTop: 16, marginBottom: 16, display: "flex" }}>
                <div
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {selectedFilter.name || "filter"}:{" "}
                </div>
                {selectedFilter.conditions.map((filter, index) => (
                  <Tag key={index} style={{ marginLeft: 8 }}>
                    <strong>{filter.field}:</strong> {filter.value}
                  </Tag>
                ))}
                <Button
                  size="small"
                  icon={<CloseOutlined />}
                  onClick={() => {
                    setSelectedFilter(null);
                    setData(initialData);
                    handleRemoveFilter([]);
                  }}
                  style={{ marginLeft: 16 }}
                  type="light"
                >
                  Clear Filter
                </Button>
                <Button
                  size="small"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    handleDeleteFilter(selectedFilter);
                    setSelectedFilter(null);
                    setData(initialData);
                    handleRemoveFilter([]);
                  }}
                  style={{ marginLeft: 16 }}
                ></Button>
              </div>
            )}
            <CreateFilter
              fields={fields}
              objectTypeId={type?.id}
              onAddFilter={(e) => {
                console.log(e);
                handleAddFilter(e);
              }}
              onRemoveFilter={(e) => {
                setData(initialData);

                setTimeout(() => {
                  handleRemoveFilter(e);
                }, 100);
                console.log("removed", e);
              }}
            />
          </div>

          {type && type.filters && type.filters.length > 0 && (
            <>
              <div style={{ marginBottom: 10, fontWeight: "bold" }}>or</div>
              <Select
                placeholder="Select a filter"
                style={{ width: 200 }}
                onChange={handleFilterChange}
                value={selectedFilter && selectedFilter.name}
              >
                {type &&
                  type.filters &&
                  type.filters.map((filter, index) => (
                    <Option key={index}>{filter.name}</Option>
                  ))}
              </Select>
            </>
          )}
        </div>
        <div
          style={{
            marginBottom: 0,

            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                alignItems: "flex-end",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <SendGridCard
                team={team}
                objectType={type}
                teamMembers={teamMembers}
              />
            </div>
            <ActionButtons
              objectType={objectType}
              selectedRowKeys={selectedRowKeys}
              onDeleteConfirm={handleDeleteConfirm}
              onDeleteCancel={handleDeleteCancel}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
        }}
      >
        <div
          style={{
            flex: 1,
          }}
        >
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            onChange={handleSearch}
            style={{ width: 386, marginBottom: 10 }}
          />
        </div>
      </div>

      {!loading ? (
        <div
          style={{
            backgroundColor: "white",
            width: "calc(100% + 10px)",
            overflowX: "auto",
            marginLeft: -6,
          }}
        >
          <DataTable
            fields={fields}
            data={data}
            selectedFilters={selectedFilter}
            rowSelection={rowSelection}
            objectType={objectType}
            relatedObjects={relatedObjects}
            objectTypes={objectTypes}
            visibleColumns={visibleColumns}
          />
        </div>
      ) : (
        <Skeleton active />
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 4,
          flexWrap: "wrap",
          justifyItems: "flex-end",
          alignItems: "flex-end",
          width: "100%",
          padding: 6,
        }}
      >
        {type && type.fields && type.fields.length > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyItems: "flex-end",
              alignItems: "flex-end",
            }}
          >
            {fields.map((field) => (
              <Tag
                key={field.name}
                color={visibleColumns.includes(field.name) ? "blue" : "default"}
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setVisibleColumns((prev) => {
                    if (prev.includes(field.name)) {
                      return prev.filter((item) => item !== field.name);
                    } else {
                      return [...prev, field.name];
                    }
                  });
                }}
              >
                {field.label || field.name}
              </Tag>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Things;
