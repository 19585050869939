import React, { useEffect, useState } from "react";
import { Button, Popover, List, Spin } from "antd";
import { RobotOutlined } from "@ant-design/icons";
import { on } from "events";

const AgentButton = ({
  team,
  agents,
  loading,
  onAgent,
  agentTeams,
  onTeam,
  disabled,
}) => {
  const [openAIProcessing, setOpenAIProcessing] = useState(false);

  useEffect(() => {
    console.log("Agents loaded: ", agents);
  }, []);

  const handleAgentSelect = (agent) => {
    console.log(`Selected agent: ${agent.name}`);
    // Add your logic to handle agent selection here
    onAgent(agent);
  };

  const handleAgentTeamSelect = (team) => {
    console.log(`Selected team: ${team.name}`);
    // Add your logic to handle agent selection here
    onTeam(team);
  };

  const agentsContent = (
    <div>
      <div
        style={{
          fontWeight: "bold",
        }}
      >
        Teams
      </div>
      {loading ? (
        <Spin />
      ) : (
        <List
          dataSource={agentTeams.filter((t) => t.isVisible != false)}
          renderItem={(team) => (
            <div>
              <Button
                disabled={disabled}
                onClick={() => handleAgentTeamSelect(team)}
                style={{ padding: 0, border: "1px solid #f0f0f0" }}
              >
                <div
                  style={{
                    width: 30,
                    height: 30,
                    borderRadius: 4,
                    backgroundColor: "#f0f0f0",
                    backgroundImage:
                      "url(/team-avatars/" + team.avatar + ".png)",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    marginRight: 10,
                  }}
                ></div>
                <div
                  style={{
                    paddingRight: 10,
                  }}
                >
                  {team.name}
                </div>
              </Button>
            </div>
          )}
        />
      )}
      <div
        style={{
          fontWeight: "bold",
        }}
      >
        Agents
      </div>
      {loading ? (
        <Spin />
      ) : (
        <List
          dataSource={agents.filter((a) => a.isVisible != false)}
          renderItem={(agent) => (
            <div>
              <Button
                disabled={disabled}
                onClick={() => handleAgentSelect(agent)}
                style={{ padding: 0, border: "1px solid #f0f0f0" }}
              >
                <div
                  style={{
                    width: 30,
                    height: 30,
                    borderRadius: 4,
                    backgroundColor: "#f0f0f0",
                    backgroundImage: "url(" + agent.avatar + ")",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    marginRight: 10,
                  }}
                ></div>
                <div
                  style={{
                    paddingRight: 10,
                  }}
                >
                  {agent.name}
                </div>
              </Button>
            </div>
          )}
        />
      )}
    </div>
  );

  return (
    <>
      {team && (team.openaiApiKey || team.anthropicApiKey) && (
        <Popover content={agentsContent} trigger="click" placement="top">
          <Button
            disabled={disabled}
            type=""
            style={{
              padding: "4px 12px",
            }}
            loading={openAIProcessing}
          >
            <RobotOutlined />
          </Button>
        </Popover>
      )}
    </>
  );
};

export default AgentButton;
