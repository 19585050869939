// src/components/ImportCSVCard.js

import React from "react";
import { useNavigate } from "react-router-dom";

const ImportCSVCard = () => {
  const navigate = useNavigate();

  const teamId = localStorage.getItem("teamId");

  return (
    <div
      style={{
        width: 140,
        height: 140,
        backgroundColor: "#fff",
        borderRadius: 8,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 14,
        fontWeight: "bold",
        color: "black",
        cursor: "pointer",
        textAlign: "center",
        boxShadow: "0px 0px 10px 0px #00000013",
      }}
      onClick={() => {
        navigate("/import-csv");
      }}
    >
      <div>
        <div
          style={{
            fontSize: 40,
            marginBottom: 10,
          }}
        >
          📄
        </div>
        Import CSV
      </div>
    </div>
  );
};

export default ImportCSVCard;
