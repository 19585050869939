import React, { useState, useEffect } from "react";
import { Upload, Button, Select, message, Form, Table } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import Papa from "papaparse";
import _ from "lodash";
import { useRecoilState } from "recoil";
import { teamIdState } from "../state";

const { Option } = Select;

const ImportCSV = () => {
  const [file, setFile] = useState(null);
  const [objectTypes, setObjectTypes] = useState([]);
  const [selectedObjectType, setSelectedObjectType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [previewData, setPreviewData] = useState([]);
  const [teamId, setTeamId] = useRecoilState(teamIdState);

  useEffect(() => {
    // Fetch object types
    axios
      .get(`${process.env.REACT_APP_API_URL}/object-types?teamId=${teamId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setObjectTypes(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching object types:", error);
      });
  }, [teamId]);

  const handleUpload = ({ file }) => {
    setFile(file);
    parseCSV(file);
  };

  const parseCSV = (file) => {
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        const preview = results.data.slice(0, 3); // Get first 3 rows
        // set columns name to snake_case
        preview.forEach((row) => {
          Object.keys(row).forEach((key) => {
            const newKey = _.snakeCase(key);
            if (key !== newKey) {
              Object.defineProperty(
                row,
                newKey,
                Object.getOwnPropertyDescriptor(row, key)
              );
              delete row[key];
            }
          });
        });

        setPreviewData(preview);
      },
      error: (error) => {
        console.error("Error parsing CSV:", error);
      },
    });
  };

  const handleImport = () => {
    if (!file || !selectedObjectType) {
      message.error("Please select a file and an object type.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("objectTypeId", selectedObjectType);

    setLoading(true);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/import?teamId=${teamId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        message.success("CSV file imported successfully.");
        setFile(null);
        setSelectedObjectType(null);
        setPreviewData([]);
      })
      .catch((error) => {
        message.error("Error importing CSV file.");
        console.error("Error importing CSV:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = objectTypes
    .find((type) => type.id === selectedObjectType)
    ?.fields.map((field) => ({
      title: field.label,
      dataIndex: field.name,
      key: field.name,
    }));

  return (
    <div
      style={{
        paddingLeft: 24,
      }}
    >
      <Form layout="vertical">
        <Form.Item
          label="Select Object Type"
          style={{
            maxWidth: 300,
          }}
        >
          <Select
            value={selectedObjectType}
            onChange={(value) => setSelectedObjectType(value)}
          >
            {objectTypes.map((type) => (
              <Option key={type.id} value={type.id}>
                {type.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Upload CSV File">
          <Upload
            beforeUpload={() => false}
            onChange={handleUpload}
            accept=".csv"
          >
            <Button icon={<UploadOutlined />}>Select File</Button>
          </Upload>
        </Form.Item>
        <div>The fields in the CSV file will be imported as follows:</div>
        {previewData.length > 0 && (
          <Table
            columns={columns}
            dataSource={previewData}
            rowKey={() => Math.random()} // Generate unique key for each row
            pagination={false}
            style={{ marginBottom: 16 }}
          />
        )}
        <Button
          type="primary"
          onClick={handleImport}
          loading={loading}
          disabled={!file || !selectedObjectType}
        >
          Import
        </Button>
      </Form>
    </div>
  );
};

export default ImportCSV;
