import React, { useState, useEffect } from "react";
import {
  List,
  Button,
  Modal,
  Form,
  Input,
  Select,
  Badge,
  Avatar,
  message,
} from "antd";
import axios from "axios";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import _ from "lodash";
import { EditFilled } from "@ant-design/icons";

const { Option } = Select;

const TaskBoard = ({
  thingId,
  agents,
  users,
  updated,
  tasks,
  handleOnDragEnd,
  onTaskEdit,
  onTaskSelect,
  selectedTaskId,
}) => {
  const getStatusBadge = (status) => {
    let color;
    if (status === "todo") color = "red";
    else if (status === "doing") color = "orange";
    else if (status === "done") color = "green";
    return <Badge color={color} />;
  };

  const groupTasksByStatus = (status) => {
    return tasks.filter((task) => task.status === status);
  };

  function assignedToUser(task) {
    if (task.assignedTo.startsWith("user:")) {
      return users.find((user) => `user:${user.userId}` === task.assignedTo);
    } else {
      return agents.find(
        (agent) => `agent:${agent.agentId}` === task.assignedTo
      );
    }
  }

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "20px",
        }}
      >
        {["todo", "doing", "done"].map((status) => (
          <Droppable key={status} droppableId={status}>
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{
                  width: "30%",
                  minHeight: "500px",
                  backgroundColor: "#f0f0f0",
                  padding: "10px",
                  borderRadius: "8px",
                }}
              >
                <h3>{status.toUpperCase()}</h3>
                {groupTasksByStatus(status).map((task, index) => (
                  <Draggable
                    key={task.taskId}
                    draggableId={task.taskId.toString()}
                    index={index}
                    id={task.taskId}
                  >
                    {(provided) => (
                      <div
                        onClick={() => {
                          if (selectedTaskId === task.taskId) {
                            onTaskSelect(null);
                          } else {
                            onTaskSelect(task);
                          }
                        }}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          cursor: "pointer",
                          backgroundColor:
                            task.status === "todo"
                              ? "#fff5f5"
                              : task.status === "doing"
                              ? "#fff3e0"
                              : "#e8f5e9",
                          padding: 8,
                          borderRadius: 12,
                          marginBottom: 8,
                          position: "relative",
                          ...provided.draggableProps.style,
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            top: 4,
                            right: 4,
                            border: "1px solid #ccc",
                            padding: "2px 4px",
                            borderRadius: 4,
                            boxShadow: "0 0 2px #ccc",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            onTaskEdit(task);
                          }}
                        >
                          <EditFilled />
                        </div>
                        <List.Item.Meta
                          title={task.title}
                          description={
                            <div>
                              <div
                                style={{
                                  fontSize: 12,
                                  fontWeight: 600,
                                  marginTop: -8,
                                  marginBottom: 8,
                                }}
                              >
                                {getStatusBadge(task.status)}{" "}
                                {task.humanReadableId}
                              </div>

                              <div
                                style={{
                                  fontWeight: 900,
                                  paddingRight: 8,
                                  fontSize: 12,
                                  color: "#555",
                                }}
                              >
                                <Avatar
                                  src={
                                    assignedToUser(task)?.picture ||
                                    assignedToUser(task)?.avatar
                                  }
                                  style={{
                                    marginRight: 8,
                                    backgroundColor: "#fff",
                                  }}
                                  size={24}
                                />
                                {assignedToUser(task)?.name}
                              </div>
                            </div>
                          }
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        ))}
      </div>
    </DragDropContext>
  );
};

export default TaskBoard;
