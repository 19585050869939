import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  Spin,
  message,
  Input,
  DatePicker,
  Popconfirm,
  Select,
} from "antd";
import { PlusOutlined, SaveOutlined, DeleteOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

const { Option } = Select;

const recurrenceOptions = [
  { label: "None", value: "none" },
  { label: "Hourly", value: "hourly" },
  { label: "Daily", value: "daily" },
  { label: "Weekly", value: "weekly" },
  { label: "Monthly", value: "monthly" },
];

const ScheduledTasksPage = () => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [agents, setAgents] = useState([]);
  const [editingTaskId, setEditingTaskId] = useState(null);
  const [newTask, setNewTask] = useState({
    prompt: "",
    executionTime: null,
    recurrencePattern: "none",
    agentId: null,
  });
  const navigate = useNavigate();
  const teamId = localStorage.getItem("teamId");

  useEffect(() => {
    fetchScheduledTasks();
    fetchAgents(); // Fetch available agents on component load
  }, []);

  const fetchScheduledTasks = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/scheduled-tasks?teamId=${teamId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setTasks(response.data.tasks);
    } catch (error) {
      message.error("Error fetching scheduled tasks");
      console.error(error);
    }
    setLoading(false);
  };

  const fetchAgents = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/teams/${teamId}/agents?teamId=${teamId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setAgents(response.data.agents);
    } catch (error) {
      message.error("Error fetching agents");
      console.error(error);
    }
  };

  const handleSaveTask = async (taskId, updatedTask) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/scheduled-tasks/${taskId}?teamId=${teamId}`,
        updatedTask,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      message.success("Task updated successfully");
      fetchScheduledTasks(); // Refresh the table
      setEditingTaskId(null); // Exit edit mode
    } catch (error) {
      message.error("Error updating task");
      console.error(error);
    }
  };

  const handleDeleteTask = async (taskId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/scheduled-tasks/${taskId}?teamId=${teamId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      message.success("Task deleted successfully");
      fetchScheduledTasks(); // Refresh the table
    } catch (error) {
      message.error("Error deleting task");
      console.error(error);
    }
  };

  const handleCreateNewTask = async () => {
    if (!newTask.prompt || !newTask.executionTime) {
      message.error("Please fill in all fields");
      return;
    }

    try {
      const taskData = {
        teamId,
        ...newTask,
        executionTime: newTask.executionTime.toISOString(),
      };
      await axios.post(
        `${process.env.REACT_APP_API_URL}/scheduled-tasks?teamId=${teamId}`,
        taskData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      message.success("Task created successfully");
      fetchScheduledTasks(); // Refresh the table
      setNewTask({
        prompt: "",
        executionTime: null,
        recurrencePattern: "none",
        agentId: null,
      }); // Reset form
    } catch (error) {
      message.error("Error creating task");
      console.error(error);
    }
  };

  const columns = [
    {
      title: "Prompt",
      dataIndex: "prompt",
      key: "prompt",
      render: (text, record) =>
        editingTaskId === record.taskId ? (
          <Input
            value={text}
            onChange={(e) =>
              setTasks((prev) =>
                prev.map((task) =>
                  task.taskId === record.taskId
                    ? { ...task, prompt: e.target.value }
                    : task
                )
              )
            }
          />
        ) : (
          text
        ),
    },
    {
      title: "Execution Time",
      dataIndex: "executionTime",
      key: "executionTime",
      render: (text, record) =>
        editingTaskId === record.taskId ? (
          <DatePicker
            showTime
            value={moment(text)}
            onChange={(date) =>
              setTasks((prev) =>
                prev.map((task) =>
                  task.taskId === record.taskId
                    ? { ...task, executionTime: date.toISOString() }
                    : task
                )
              )
            }
          />
        ) : (
          moment(text).format("YYYY-MM-DD HH:mm:ss")
        ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => text.charAt(0).toUpperCase() + text.slice(1),
    },
    {
      title: "Next Run",
      dataIndex: "nextExecutionTime",
      key: "nextExecutionTime",
      render: (text, record) =>
        editingTaskId === record.taskId ? (
          <DatePicker
            showTime
            value={moment(text)}
            onChange={(date) =>
              setTasks((prev) =>
                prev.map((task) =>
                  task.taskId === record.taskId
                    ? { ...task, nextExecutionTime: date.toISOString() }
                    : task
                )
              )
            }
          />
        ) : (
          moment(text).format("YYYY-MM-DD HH:mm:ss")
        ),
    },
    {
      title: "Recurrence",
      dataIndex: "recurrencePattern",
      key: "recurrencePattern",
      render: (text, record) =>
        editingTaskId === record.taskId ? (
          <Select
            value={text}
            onChange={(value) =>
              setTasks((prev) =>
                prev.map((task) =>
                  task.taskId === record.taskId
                    ? { ...task, recurrencePattern: value }
                    : task
                )
              )
            }
            style={{ width: "100%" }}
          >
            {recurrenceOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        ) : (
          text.charAt(0).toUpperCase() + text.slice(1)
        ),
    },
    {
      title: "Agent",
      dataIndex: "agentId",
      key: "agentId",
      render: (text, record) =>
        editingTaskId === record.taskId ? (
          <Select
            value={text}
            onChange={(value) =>
              setTasks((prev) =>
                prev.map((task) =>
                  task.taskId === record.taskId
                    ? { ...task, agentId: value }
                    : task
                )
              )
            }
            style={{ width: "100%" }}
          >
            {agents.map((agent) => (
              <Option key={agent.agentId} value={agent.agentId}>
                {agent.name}
              </Option>
            ))}
          </Select>
        ) : (
          agents.find((agent) => agent.agentId === text)?.name || "None"
        ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, task) =>
        editingTaskId === task.taskId ? (
          <>
            <Button
              icon={<SaveOutlined />}
              onClick={() => handleSaveTask(task.taskId, task)}
            >
              Save
            </Button>
            <Button onClick={() => setEditingTaskId(null)}>Cancel</Button>
          </>
        ) : (
          <>
            <Button
              onClick={() => setEditingTaskId(task.taskId)}
              disabled={editingTaskId !== null}
            >
              Edit
            </Button>
            <Popconfirm
              title="Are you sure you want to delete this task?"
              onConfirm={() => handleDeleteTask(task.taskId)}
            >
              <Button danger icon={<DeleteOutlined />}>
                Delete
              </Button>
            </Popconfirm>
          </>
        ),
    },
  ];

  return (
    <div>
      <h1>Scheduled Tasks</h1>
      <Button
        type="primary"
        onClick={() => {
          axios.post(
            `${process.env.REACT_APP_API_URL}/scheduled-tasks/run?teamId=${teamId}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          message.success("Triggered Runner");
        }}
      >
        Trigger Runner(for debuging)
      </Button>

      <div style={{ marginBottom: 16 }}>
        <Input
          placeholder="New task prompt"
          value={newTask.prompt}
          onChange={(e) => setNewTask({ ...newTask, prompt: e.target.value })}
          style={{ width: "40%", marginRight: 8 }}
        />
        <DatePicker
          showTime
          placeholder="Execution Time"
          onChange={(date) => setNewTask({ ...newTask, executionTime: date })}
          style={{ marginRight: 8 }}
        />
        <Select
          value={newTask.recurrencePattern}
          onChange={(value) =>
            setNewTask({ ...newTask, recurrencePattern: value })
          }
          style={{ width: "20%", marginRight: 8 }}
        >
          {recurrenceOptions.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
        <Select
          value={newTask.agentId}
          onChange={(value) => setNewTask({ ...newTask, agentId: value })}
          placeholder="Select Agent"
          style={{ width: "20%", marginRight: 8 }}
        >
          {agents &&
            agents.map((agent) => (
              <Option key={agent.agentId} value={agent.agentId}>
                {agent.name}
              </Option>
            ))}
        </Select>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={handleCreateNewTask}
        >
          Add New Task
        </Button>
      </div>

      {loading ? (
        <Spin />
      ) : (
        <Table columns={columns} dataSource={tasks} rowKey="taskId" />
      )}
    </div>
  );
};

export default ScheduledTasksPage;
