import React, { useState, useRef } from "react";
import { Form, Input, Button, Select, message, Alert, Tag } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import axios from "axios";

const { Option } = Select;

const EmailBuilder = ({ objectType, onClose, teamMembers }) => {
  const [loading, setLoading] = useState(false);
  const [contactCount, setContactCount] = useState(0);
  const [body, setBody] = useState(""); // State for the rich text editor content
  const quillRef = useRef(null); // Reference for the Quill editor
  const [form] = Form.useForm();
  const [subject, setSubject] = useState("");
  const [filterValue, setFilterValue] = useState("");

  const handleFilterChange = async (value) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL +
          `/contacts/count?objectTypeId=${
            objectType.id
          }&filterName=${value}&teamId=${localStorage.getItem("teamId")}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setContactCount(response.data.count);
    } catch (error) {
      message.error("Failed to retrieve contact count");
      setContactCount(0);
    }
  };

  const handleSendEmails = async (values) => {
    setLoading(true);
    try {
      await axios.post(
        process.env.REACT_APP_API_URL +
          "/sendgrid/send-bulk-emails?teamId=" +
          localStorage.getItem("teamId"),
        {
          objectTypeId: objectType.id,
          filterName: values.filter,
          subject: values.subject,
          body, // Use the state for the body content
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      message.success("Emails sent successfully!");
      onClose();
    } catch (error) {
      message.error("Failed to send emails");
    } finally {
      setLoading(false);
    }
  };

  const insertFieldAtCursor = (fieldName) => {
    const quill = quillRef.current.getEditor();
    const cursorPosition = quill.getSelection()?.index; // Safely get the cursor position

    if (cursorPosition !== null && cursorPosition !== undefined) {
      quill.insertText(cursorPosition, `{{${fieldName}}}`);
      quill.setSelection(cursorPosition + `{{${fieldName}}}`.length);
    } else {
      // If the editor is not focused or there's no selection, focus the editor and insert at the end
      quill.focus();
      const length = quill.getLength(); // Get the length of the content in the editor
      quill.insertText(length - 1, `{{${fieldName}}}`);
      quill.setSelection(length + `{{${fieldName}}}`.length - 1);
    }
  };

  return (
    <Form layout="vertical" onFinish={handleSendEmails} form={form}>
      <Form.Item
        label="Subject"
        name="subject"
        rules={[{ required: true, message: "Please enter the email subject" }]}
      >
        <Input placeholder="Enter the email subject" value={subject} />
      </Form.Item>

      <Form.Item
        label="Body"
        name="body"
        rules={[{ required: true, message: "Please enter the email body" }]}
      >
        <ReactQuill
          theme="snow"
          value={body}
          onChange={setBody}
          ref={quillRef} // Attach the ref to Quill editor
          style={{
            height: "300px",
            border: "1px solid #d9d9d9",
            borderRadius: "8px",
          }}
          placeholder="Enter the email body. Use {{field_name}} to insert data from the object."
        />
      </Form.Item>

      <div style={{ marginBottom: 16 }}>
        {objectType &&
          objectType.fields &&
          objectType.fields.map((field) => (
            <Tag
              key={field.name}
              color="blue"
              style={{ cursor: "pointer" }}
              onClick={() => insertFieldAtCursor(field.name)}
            >
              {field.label || field.name}
            </Tag>
          ))}
      </div>

      <Form.Item
        label="Filter"
        name="filter"
        rules={[{ required: true, message: "Please select a filter" }]}
      >
        <Select
          placeholder="Select a filter"
          value={filterValue}
          onChange={handleFilterChange}
        >
          {objectType &&
            objectType.filters &&
            objectType.filters.map((filter, index) => (
              <Option key={index} value={filter.name}>
                {filter.name}
              </Option>
            ))}
        </Select>
      </Form.Item>

      {contactCount > 0 && (
        <Alert
          message={`This email will be sent to ${contactCount} contact(s).`}
          type="info"
          showIcon
          style={{ marginBottom: 16 }}
        />
      )}

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={() => {
            form.submit();
          }}
        >
          Send Emails
        </Button>
        <Button
          style={{
            marginLeft: 8,
          }}
          type="secondary"
          onClick={() => {
            setBody("");
            setSubject("");
            setFilterValue("");
            // reset the form
            form.resetFields();

            onClose();
          }}
        >
          Cancel
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EmailBuilder;
