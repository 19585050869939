import React, { useEffect, useState } from "react";
import { Card, Modal, Button, Input, Select, notification } from "antd";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import { DeleteOutlined } from "@ant-design/icons";
import { Progress } from "antd";
import _ from "lodash";
import { Select as AntSelect } from "antd"; // Import Ant Design Select for user assignment
import { useRecoilState } from "recoil";
import { teamMembersState } from "../state";
const { Option } = Select;

function GrowthLevers({ growthLevers, setGrowthLevers, teamId, teamMembers }) {
  const [rockModalVisible, setRockModalVisible] = useState(false);
  const [leverModalVisible, setLeverModalVisible] = useState(false);
  const [isEditingLever, setIsEditingLever] = useState(false);

  const [selectedLever, setSelectedLever] = useState(null);
  const [rockTitle, setRockTitle] = useState("");
  const [rockStatus, setRockStatus] = useState("todo");
  const [rockNotes, setRockNotes] = useState("");
  const [newLeverTitle, setNewLeverTitle] = useState("");
  const [newLeverNotes, setNewLeverNotes] = useState("");
  const [selectedLeverStatus, setSelectedLeverStatus] = useState("testing");
  const [editingRockIndex, setEditingRockIndex] = useState(null);
  const [assignedUser, setAssignedUser] = useState(""); // New state for assigned user

  const openLeverModal = (lever = null, status = "testing") => {
    if (lever) {
      // Editing an existing lever
      setIsEditingLever(true);
      setSelectedLever(lever);
      setNewLeverTitle(lever.title);
      setNewLeverNotes(lever.notes || "");
      setSelectedLeverStatus(lever.status);
      setAssignedUser(lever.assignedUser || ""); // Set assigned user for editing
    } else {
      // Adding a new lever
      setIsEditingLever(false);
      setSelectedLeverStatus(status);
      setNewLeverTitle("");
      setNewLeverNotes("");
      setAssignedUser(""); // Clear assigned user for new lever
    }
    setLeverModalVisible(true);
  };

  const openRockModal = (lever, rock = null, index = null) => {
    if (!lever) {
      notification.error({
        message: "Error",
        description: "No lever selected.",
      });
      return;
    }

    setSelectedLever(lever); // Ensure selectedLever is set

    if (rock) {
      // Editing an existing rock
      setRockTitle(rock.title);
      setRockStatus(rock.status);
      setRockNotes(rock.notes || "");
      setEditingRockIndex(index);
      setAssignedUser(rock.assignedUser || ""); // Set assigned user for editing
    } else {
      // Adding a new rock
      setRockTitle("");
      setRockStatus("todo");
      setRockNotes("");
      setEditingRockIndex(null);
      setAssignedUser(""); // Clear assigned user for new rock
    }

    setRockModalVisible(true);
  };

  const addOrEditRock = async () => {
    if (!selectedLever) {
      notification.error({
        message: "Error",
        description: "No lever selected for adding or editing a rock.",
      });
      return;
    }

    const updatedLevers = [...growthLevers];
    const leverIndex = updatedLevers.findIndex(
      (lever) => lever.id === selectedLever.id
    );

    if (leverIndex === -1) {
      notification.error({
        message: "Error",
        description: "Unable to find the selected lever.",
      });
      return;
    }

    if (editingRockIndex !== null) {
      // Editing an existing rock
      updatedLevers[leverIndex].rocks[editingRockIndex] = {
        title: rockTitle,
        status: rockStatus,
        notes: rockNotes,
        assignedUser, // Add assigned user to the rock
      };
    } else {
      // Adding a new rock
      updatedLevers[leverIndex].rocks.push({
        title: rockTitle,
        status: rockStatus,
        notes: rockNotes,
        assignedUser, // Add assigned user to the new rock
      });
    }

    setGrowthLevers(updatedLevers);

    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/teams/${teamId}/growth-levers?teamId=${teamId}`,
        { growthLevers: updatedLevers },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      notification.success({
        message:
          editingRockIndex !== null
            ? "Rock updated successfully"
            : "Rock added successfully",
      });
    } catch (error) {
      notification.error({
        message: "Error saving rock",
        description: error.message,
      });
    } finally {
      setRockModalVisible(false);
      setRockTitle("");
      setRockStatus("todo");
      setRockNotes("");
      setAssignedUser(""); // Clear assigned user after saving
    }
  };

  const removeRock = async (rockIndex) => {
    if (!selectedLever) {
      notification.error({
        message: "Error",
        description: "No lever selected for removing a rock.",
      });
      setRockModalVisible(false);
      return;
    }

    const updatedLevers = [...growthLevers];
    const leverIndex = updatedLevers.findIndex(
      (lever) => lever.id === selectedLever.id
    );

    if (leverIndex !== -1) {
      updatedLevers[leverIndex].rocks.splice(rockIndex, 1);

      setGrowthLevers(updatedLevers);

      try {
        await axios.put(
          `${process.env.REACT_APP_API_URL}/teams/${teamId}/growth-levers?teamId=${teamId}`,
          { growthLevers: updatedLevers },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        notification.success({ message: "Rock removed successfully" });
      } catch (error) {
        notification.error({
          message: "Error removing rock",
          description: error.message,
        });
      }
    } else {
      notification.error({
        message: "Error",
        description: "Unable to find the selected lever for removing a rock.",
      });
    }
  };

  const saveLever = async () => {
    const updatedLevers = [...growthLevers];

    if (isEditingLever && selectedLever) {
      const leverIndex = updatedLevers.findIndex(
        (lever) => lever.id === selectedLever.id
      );

      if (leverIndex !== -1) {
        updatedLevers[leverIndex].title = newLeverTitle;
        updatedLevers[leverIndex].notes = newLeverNotes;
        updatedLevers[leverIndex].status = selectedLeverStatus;
        updatedLevers[leverIndex].assignedUser = assignedUser; // Add assigned user to the lever
      }
    } else {
      updatedLevers.push({
        title: newLeverTitle,
        notes: newLeverNotes,
        rocks: [],
        status: selectedLeverStatus,
        assignedUser, // Add assigned user to the new lever
        id: Math.random().toString(36).substr(2, 9),
      });
    }

    setGrowthLevers(updatedLevers);

    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/teams/${teamId}/growth-levers?teamId=${teamId}`,
        { growthLevers: updatedLevers },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      notification.success({
        message: isEditingLever
          ? "Growth Lever updated successfully"
          : "Growth Lever added successfully",
      });
    } catch (error) {
      notification.error({
        message: isEditingLever
          ? "Error updating Growth Lever"
          : "Error adding Growth Lever",
        description: error.message,
      });
    } finally {
      setLeverModalVisible(false);
      setNewLeverTitle("");
      setNewLeverNotes("");
      setAssignedUser(""); // Clear assigned user after saving
    }
  };

  function removeLever(leverId) {
    const updatedLevers = growthLevers.filter((lever) => lever.id !== leverId);
    setGrowthLevers(updatedLevers);

    axios
      .put(
        `${process.env.REACT_APP_API_URL}/teams/${teamId}/growth-levers?teamId=${teamId}`,
        { growthLevers: updatedLevers },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        setLeverModalVisible(false);
        notification.success({ message: "Growth Lever removed successfully" });
      })
      .catch((error) => {
        notification.error({
          message: "Error removing Growth Lever",
          description: error.message,
        });
      });
  }

  function rockProgress(rocks) {
    let score = 0;
    let length = rocks.length * 2;

    rocks.forEach((rock) => {
      if (rock.status === "in-progress") {
        score += 1;
      }
      if (rock.status === "done") {
        score += 2;
      }
    });
    return Math.round(rocks.length > 0 ? (score / length) * 100 : 0);
  }

  return (
    <div>
      <div style={{ display: "flex", gap: 14, overflow: "auto" }}>
        {_.orderBy(growthLevers, ["order"], ["asc"])
          // Sort by title
          .map((lever) => (
            <div key={lever.id} style={{ flex: "1 1 0", width: 300 }}>
              <div
                style={{
                  minWidth: 300,
                  backgroundColor: "white",
                  borderRadius: 12,
                  padding: 0,
                  marginBottom: 12,
                  width: 300,
                  marginTop: 12,
                }}
              >
                <Card
                  title={lever.title}
                  style={{ marginBottom: 8 }}
                  onClick={() => openLeverModal(lever)}
                >
                  <div
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: 8,
                      marginBottom: 8,
                      fontWeight: 900,
                      backgroundColor:
                        lever.status == "testing"
                          ? "rgb(44,44,44)"
                          : "rgb(22,119,255)",
                      borderRadius: 12,
                      padding: "2px 8px",
                      color: "white",
                      width: "fit-content",
                      position: "absolute",
                      top: -6,
                      right: -4,
                    }}
                  >
                    {lever.status.charAt(0).toUpperCase() +
                      lever.status.slice(1)}
                  </div>
                  <div
                    style={{
                      marginBottom: 8,
                      marginTop: -18,
                    }}
                  >
                    <Progress percent={rockProgress(lever.rocks)} />
                  </div>
                  <div
                    style={{
                      marginBottom: 8,
                    }}
                  >
                    {lever.rocks.map((rock, idx) => (
                      <div
                        style={{
                          display: "flex",
                          marginBottom: 8,
                        }}
                      >
                        <div
                          style={{
                            marginRight: 8,
                            flex: 1,
                            textDecoration:
                              rock.status === "done" ? "line-through" : "none",
                            alignContent: "center",
                            color: rock.status === "done" ? "#ccc" : "black",
                            padding: "1px 8px",
                            borderRadius: 8,
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            openRockModal(lever, rock, idx);
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <div
                              style={{
                                borderRadius: 8,
                                width: 4,
                                marginRight: 8,
                                marginTop: 3,
                                backgroundColor:
                                  rock.status === "in-progress"
                                    ? "rgb(22,119,255)"
                                    : rock.status === "done"
                                    ? "green"
                                    : "#2c2c2c",
                              }}
                            ></div>
                            <div
                              style={{
                                flex: 1,
                                fontWeight:
                                  rock.status === "in-progress"
                                    ? "bold"
                                    : "normal",
                              }}
                            >
                              {rock.title}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <Button
                    type="dashed"
                    onClick={(e) => {
                      e.stopPropagation();
                      openRockModal(lever);
                    }}
                    block
                  >
                    + Rock
                  </Button>
                </Card>
              </div>
            </div>
          ))}
        <Button
          style={{
            minWidth: 300,
            marginTop: 16,
          }}
          type="dashed"
          onClick={() => openLeverModal(null)}
          block
        >
          + Lever
        </Button>
      </div>

      {/* Modal for Adding/Editing Rocks */}
      <Modal
        title={editingRockIndex !== null ? "Edit Rock" : "Add a Rock"}
        visible={rockModalVisible}
        onOk={addOrEditRock}
        onCancel={() => setRockModalVisible(false)}
      >
        <Input
          placeholder="Rock Title"
          value={rockTitle}
          onChange={(e) => setRockTitle(e.target.value)}
          style={{ marginBottom: 8 }}
        />
        <Select
          value={rockStatus}
          onChange={(value) => setRockStatus(value)}
          style={{ marginBottom: 8, width: "100%" }}
        >
          <Option value="todo">To Do</Option>
          <Option value="in-progress">In Progress</Option>
          <Option value="done">Done</Option>
        </Select>
        <TextArea
          placeholder="Notes"
          value={rockNotes}
          onChange={(e) => setRockNotes(e.target.value)}
          rows={4}
        />
        <div style={{ marginTop: 18 }}>
          <AntSelect
            placeholder="Assign User"
            value={assignedUser}
            onChange={(value) => setAssignedUser(value)}
            style={{ marginBottom: 18, width: "100%" }}
          >
            {/* Replace with actual user options */}
            {teamMembers &&
              teamMembers.map((member) => (
                <Option key={member.id} value={member.userId}>
                  {member.name}
                </Option>
              ))}
          </AntSelect>
        </div>
        <div
          style={{
            marginTop: 16,
          }}
        >
          <Button danger onClick={() => removeRock(editingRockIndex)}>
            Remove Rock
          </Button>
        </div>
      </Modal>

      {/* Modal for Adding/Editing Growth Lever */}
      <Modal
        title={isEditingLever ? "Edit Growth Lever" : "Add Growth Lever"}
        visible={leverModalVisible}
        onOk={saveLever}
        onCancel={() => setLeverModalVisible(false)}
      >
        <Input
          placeholder="Growth Lever Title"
          value={newLeverTitle}
          onChange={(e) => setNewLeverTitle(e.target.value)}
          style={{ marginBottom: 8 }}
        />
        <TextArea
          placeholder="Growth Lever Notes"
          value={newLeverNotes}
          onChange={(e) => setNewLeverNotes(e.target.value)}
          rows={4}
        />
        <Select
          value={selectedLeverStatus}
          onChange={(value) => setSelectedLeverStatus(value)}
          style={{ marginTop: 8, width: "100%" }}
        >
          <Option value="testing">Testing</Option>
          <Option value="executing">Executing</Option>
          <Option value="evaluating">Evaluating</Option>
        </Select>
        <div style={{ marginTop: 18 }}>
          <AntSelect
            placeholder="Assign User"
            value={assignedUser}
            onChange={(value) => setAssignedUser(value)}
            style={{ marginTop: 18, width: "100%" }}
          >
            {teamMembers &&
              teamMembers.map((member) => (
                <Option key={member.id} value={member.userId}>
                  {member.name}
                </Option>
              ))}
          </AntSelect>
        </div>
        <div style={{ marginTop: 16 }}>
          {isEditingLever && (
            <Button
              danger
              onClick={() => removeLever(selectedLever.id)}
              style={{ float: "left" }}
            >
              Remove Growth Lever
            </Button>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default GrowthLevers;
