import React, { useState } from "react";
import { Modal, Button } from "antd";
import DuplicateFinder from "./DuplicateFinder"; // Import the DuplicateFinder component

const DuplicateMatcherCard = ({ teamId }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleCardClick = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div
        style={{
          width: 140,
          height: 140,
          backgroundColor: "#fff",
          borderRadius: 8,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
          fontWeight: "bold",
          color: "black",
          cursor: "pointer",
          textAlign: "center",
          boxShadow: "0px 0px 10px 0px #00000013",
        }}
        onClick={handleCardClick}
      >
        <div>
          <div
            style={{
              fontSize: 40,
              marginBottom: 10,
            }}
          >
            🔍
          </div>
          Duplicate Matcher
        </div>
      </div>

      <Modal
        title="Find Duplicate Emails"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={800} // Adjust width for a better view
      >
        <DuplicateFinder teamId={teamId} />{" "}
        {/* Pass the teamId to the DuplicateFinder */}
      </Modal>
    </>
  );
};

export default DuplicateMatcherCard;
