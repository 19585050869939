import React, { useState } from "react";
import { Input, Select, Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AvatarPicker from "../components/AvatarPicker";

const { Option } = Select;

const defaultWorkflowConfig = {
  tools: [
    { name: "Weather Tool", description: "Provides weather info", schema: {} },
  ],
  nodes: [{ name: "Agent Node", action: "callModel" }],
  edges: [{ from: "__start__", to: "Agent Node" }],
  graphState: {
    messages: { reducer: (x, y) => x.concat(y) },
  },
};

const CreateAgentPage = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [modelType, setModelType] = useState("claude-2");
  const [avatar, setAvatar] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const teamId = localStorage.getItem("teamId");

  const handleCreateAgent = async () => {
    if (!name || !modelType) {
      message.error("Please fill in all required fields.");
      return;
    }
    setLoading(true);
    try {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/agent?teamId=${teamId}`,
          {
            name,
            description,
            modelType,
            teamId,
            avatar,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          message.success("Agent created successfully!");
          setLoading(false);
          navigate("/agents/manage/" + response.data.data.agentId);
        });
    } catch (error) {
      message.error("Error creating agent.");
      console.error(error);
    }
  };

  return (
    <div
      style={{
        maxWidth: 600,
        margin: "0 auto",
      }}
    >
      <h1>Create New Agent</h1>
      <AvatarPicker
        value={avatar}
        onAvatarSelect={(value) => setAvatar(value)}
      />

      <Input
        placeholder="Agent Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        style={{ marginBottom: 16 }}
      />

      <Input
        placeholder="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        style={{ marginBottom: 16 }}
      />
      <Select
        placeholder="Select Model"
        style={{ width: "100%", marginBottom: 16 }}
        onChange={(value) => setModelType(value)}
      >
        <>
          <Option value="gpt-4">GPT-4</Option>
          <Option value="gpt-4o">GPT-4o</Option>
          <Option value="gpt-4o-mini">GPT-4o-mini</Option>
          <Option value="gpt-3.5-turbo">GPT-3.5 Turbo</Option>
          <Option value="gpt-3.5">GPT-3.5</Option>
          <Option value="gpt-3">GPT-3</Option>
        </>

        <>
          <Option value="claude-instant">Claude Instant</Option>
          <Option value="openhermes">OpenHermes</Option>
        </>
      </Select>

      <Button loading={loading} type="primary" onClick={handleCreateAgent}>
        Create Agent
      </Button>
    </div>
  );
};

export default CreateAgentPage;
