import { Badge, Button, List } from "antd";
import React, { useState } from "react";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import Markdown from "react-markdown";

function ActivityItem({
  log,
  handleEditActivity,
  handleDeleteActivity,
  user,
  agent,
}) {
  const [showEdit, setShowEdit] = useState(false);

  let picture = user?.picture || agent?.avatar || "";

  return (
    <div
      style={{ position: "relative", width: "100%" }}
      onMouseEnter={() => {
        setShowEdit(true);
      }}
      onMouseLeave={() => {
        setShowEdit(false);
      }}
    >
      <List.Item>
        <div style={{ display: "flex", gap: 10, width: "100%" }}>
          <div
            style={{
              marginTop: 6,
              width: 40,
              height: 40,
              borderRadius: 8,
              backgroundColor: "#f0f0f0",
              backgroundImage: "url(" + picture + ")",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
          <div
            style={{
              flex: 1,
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <div
                  style={{
                    fontSize: 14,
                    fontWeight: "bold",
                    flex: 1,
                  }}
                >
                  {log.type && log.type === "email" && "✉️ "}
                  {user && user.name
                    ? user.name
                    : agent && agent.name
                    ? agent.name
                    : log.title}
                </div>
                {log.taskId && (
                  <div
                    style={{
                      fontSize: 10,
                      fontWeight: 900,
                      padding: "2px 6px",
                      borderRadius: 6,
                      backgroundColor: "#f5f5f5",
                      width: "fit-content",
                    }}
                  >
                    {log.taskHumanReadableId}
                  </div>
                )}
                <div
                  style={{
                    fontSize: 10,
                    fonrWeight: 900,
                    padding: "2px 6px",
                    borderRadius: 6,
                    backgroundColor: "#f5f5f5",
                    width: "fit-content",
                  }}
                >
                  {moment(log.timestamp).fromNow()}
                </div>
              </div>
              {log.type !== "message" &&
                log.type !== "agent-request" &&
                log.type !== "ai_response" && (
                  <div
                    style={{
                      fontSize: 12,
                    }}
                  >
                    {log.title}
                  </div>
                )}
            </div>
            {/* <div
              style={{
                flex: 1,
                width: "100%",
              }}
              dangerouslySetInnerHTML={{ __html: log.body }}
            ></div> */}
            <Markdown>{log.body}</Markdown>
          </div>
        </div>
        {showEdit && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 10px",
              gap: "10px",
              border: "1px solid #f0f0f0",
              borderRadius: "8px",
              padding: 6,
              position: "absolute",
              right: 6,
              top: -6,
              backgroundColor: "#ffffff",
            }}
          >
            <Button
              icon={<EditOutlined />}
              onClick={() => handleEditActivity(log)}
            />

            <Button
              icon={<DeleteOutlined />}
              onClick={() => handleDeleteActivity(log.logId)}
            />
          </div>
        )}
      </List.Item>
    </div>
  );
}

export default ActivityItem;
