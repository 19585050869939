import React, { useState } from "react";
import { Modal, Input, Button, Select, message } from "antd";
import { RobotOutlined } from "@ant-design/icons";
import axios from "axios";
import AutomationCard from "../components/AutomationCard";

const { Option } = Select;

const AnthropicConfigCard = ({ team }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const [model, setModel] = useState("claude-2"); // Default model

  const teamId = localStorage.getItem("teamId");

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setApiKey("");
    setModel("claude-2"); // Reset model
  };

  const handleSave = async () => {
    if (!apiKey) {
      message.error("Please enter an Anthropic API key.");
      return;
    }

    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/teams/${teamId}/anthropic-key`,
        { apiKey, model },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      message.success("Anthropic API key and model saved successfully!");
      setIsModalVisible(false);
      setApiKey("");
      setModel("claude-2");
    } catch (error) {
      console.error("Error saving Anthropic API key and model:", error);
      message.error("Failed to save Anthropic API key and model.");
    }
  };

  return (
    <>
      {team && (
        <>
          <AutomationCard
            title={
              team.anthropicApiKey ? "Update Anthropic" : "Setup Anthropic"
            }
            icon={<RobotOutlined style={{ fontSize: 40 }} />}
            onClick={showModal}
          />

          <Modal
            title={
              team.anthropicApiKey
                ? "Update Anthropic API Key and Model"
                : "Setup Anthropic API Key and Model"
            }
            visible={isModalVisible}
            onCancel={handleCancel}
            footer={[
              <Button key="cancel" onClick={handleCancel}>
                Cancel
              </Button>,
              <Button key="save" type="primary" onClick={handleSave}>
                Save
              </Button>,
            ]}
          >
            <Input.Password
              placeholder="Enter Anthropic API Key"
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
              style={{ marginBottom: 16 }}
            />

            <Select
              defaultValue={model}
              onChange={(value) => setModel(value)}
              style={{ width: "100%" }}
            >
              <Option value="claude-2">Claude 2</Option>
              <Option value="claude-instant">Claude Instant</Option>
              <Option value="claude-next">Claude Next</Option>
            </Select>
          </Modal>
        </>
      )}
    </>
  );
};

export default AnthropicConfigCard;
