import React, { useState } from "react";
import { Button, Table, message } from "antd";
import axios from "axios";
import { render } from "@testing-library/react";

const DuplicateFinder = ({ teamId }) => {
  const [duplicates, setDuplicates] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchDuplicates = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/teams/${teamId}/duplicate-emails`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.duplicates && response.data.duplicates.length > 0) {
        setDuplicates(response.data.duplicates);
        message.success("Duplicate emails found.");
      } else {
        message.info("No duplicates found.");
      }
    } catch (error) {
      console.error("Error fetching duplicates:", error);
      message.error("Failed to fetch duplicate emails.");
    } finally {
      setLoading(false);
    }
  };

  const mergeDuplicates = async (dup1, dup2) => {
    let newDuplicates = duplicates.filter((x) => x[0].email !== dup1.email);
    setDuplicates(newDuplicates);

    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/teams/${teamId}/merge-objects?teamId=${teamId}`,
        { object1Id: dup1.id, object2Id: dup2.id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.success) {
        message.success("Duplicates merged successfully.");
        // remove duplicates from the state
      }
    } catch (error) {
      console.error("Error merging duplicates:", error);
      message.error("Failed to merge duplicates.");
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Object ID",
      dataIndex: "id",
      key: "id",
      render: (id) => id.slice(0, 4),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },

    {
      title: "Type",
      dataIndex: "objectTypeName",
      key: "objectTypeName",
    },
    {
      title: "Link",
      dataIndex: "id",
      key: "link",
      render: (id, record) => (
        <a
          href={`/${record.objectTypeName}/${id}`}
          target="_blank"
          rel="noreferrer"
        >
          View
        </a>
      ),
    },
  ];

  return (
    <div>
      <Button type="primary" onClick={fetchDuplicates} loading={loading}>
        Find Duplicate Emails
      </Button>

      {duplicates.length > 0 &&
        duplicates.map((dups) => (
          <div>
            <Table
              columns={columns}
              dataSource={dups}
              rowKey="id"
              pagination={false}
              style={{ marginTop: 0 }}
            />{" "}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  padding: 4,
                  fontWeight: "bold",
                }}
              >
                Merge &nbsp;
              </div>
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  mergeDuplicates(dups[0], dups[1]);
                }}
                style={{ marginBottom: 34, marginTop: 4, marginRight: 8 }}
              >
                {dups[0].id.slice(0, 4)} ← {dups[1].id.slice(0, 4)}
              </Button>
              <div
                style={{
                  padding: 4,
                  fontWeight: "bold",
                }}
              >
                or &nbsp;
              </div>
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  mergeDuplicates(dups[1], dups[0]);
                }}
                style={{ marginBottom: 34, marginTop: 4, marginRight: 8 }}
              >
                {dups[0].id.slice(0, 4)} → {dups[1].id.slice(0, 4)}
              </Button>
            </div>
          </div>
        ))}
    </div>
  );
};

export default DuplicateFinder;
