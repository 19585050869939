import React, { useState } from "react";
import { Modal, Input, Button, message, Select } from "antd";
import axios from "axios";

const { Option } = Select;

const ConfigureOpenAICard = ({ team }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const [model, setModel] = useState("gpt-4o-mini"); // Default model

  const teamId = localStorage.getItem("teamId");

  const handleOpenAIClick = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    if (!apiKey) {
      message.error("Please enter an API key.");
      return;
    }

    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/teams/${teamId}/openai-key?teamId=${teamId}`,
        { apiKey, model }, // Send the selected model and API key
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      message.success("API key and model saved successfully!");
      setIsModalVisible(false);
      setApiKey("");
      setModel("gpt-4o-mini"); // Reset the model selection
    } catch (error) {
      console.error("Error saving API key and model:", error);
      message.error("Failed to save API key and model.");
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setApiKey("");
    setModel("gpt-4o-mini"); // Reset the model selection
  };

  return (
    <>
      <div
        style={{
          width: 140,
          height: 140,
          backgroundColor: "#fff",
          borderRadius: 8,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
          fontWeight: "bold",
          color: "black",
          cursor: "pointer",
          textAlign: "center",
          boxShadow: "0px 0px 10px 0px #00000013",
        }}
        onClick={handleOpenAIClick}
      >
        <div>
          <div
            style={{
              fontSize: 40,
              marginBottom: 10,
            }}
          >
            🤖
          </div>
          {team.openaiApiKey ? "Update" : "Setup"} <br /> OpenAI Key
        </div>
      </div>

      <Modal
        title={
          team.openaiApiKey
            ? "Update OpenAI API Key and Model"
            : "Set OpenAI API Key and Model"
        }
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Input.Password
          placeholder="Enter OpenAI API Key"
          value={apiKey}
          onChange={(e) => setApiKey(e.target.value)}
          style={{ marginBottom: 16 }}
        />

        <Select
          defaultValue={model}
          onChange={(value) => setModel(value)}
          style={{ width: "100%" }}
        >
          <Option value="gpt-4o-mini">GPT-4o Mini ($0.15/1M)</Option>
          <Option value="gpt-4o-2024-08-06">
            GPT-4o 2024-08-06 ($2.50/1M)
          </Option>
          <Option value="gpt-3.5-turbo">GPT-3.5 Turbo ($3.00/1M)</Option>
          <Option value="gpt-4o">GPT-4o ($5.00/1M) </Option>
          <Option value="gpt-4-turbo">GPT-4-turbo ($10.00/1M) </Option>
          <Option value="gpt-4-32k">GPT-4 32k ($60.00/1M)</Option>
        </Select>
      </Modal>
    </>
  );
};

export default ConfigureOpenAICard;
