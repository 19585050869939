import React, { useState, useEffect } from "react";
import { Form, Input, Button, notification, Select, List, Card } from "antd";
import axios from "axios";
import { TeamOutlined, AlertFilled } from "@ant-design/icons";

const { Option } = Select;

const CreateTeam = ({ onTeamCreated, onTeamJoined }) => {
  const [loading, setLoading] = useState(false);
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState("");
  const [invites, setInvites] = useState([]);
  const [inviteOnly, setInviteOnly] = useState(false);

  useEffect(() => {
    // Fetch teams the user is already a part of
    axios
      .get(process.env.REACT_APP_API_URL + "/teams", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setTeams(response.data);
      })
      .catch((error) => {
        console.error(error.response.data.message);
        if (error.response.data.message === "Unauthorized Invite Only") {
          setInviteOnly(true);
        } else {
          notification.error({
            message: "Error fetching teams",
            description: error.message,
          });
        }
      });

    // Fetch pending invitations
    axios
      .get(process.env.REACT_APP_API_URL + "/teams/invites", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setInvites(response.data.invites);
      })
      .catch((error) => {});
  }, []);

  const handleCreateTeam = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/teams",
        values,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      notification.success({ message: "Team created successfully!" });
      onTeamCreated(response.data.teamId);
    } catch (error) {
      notification.error({
        message: "Error creating team",
        description: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleJoinTeam = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/teams/join",
        values,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      notification.success({ message: "Joined team successfully!" });
      // onTeamJoined(response.data.teamId);
      localStorage.setItem("teamId", values.teamId);
      window.location.href = "/";
    } catch (error) {
      notification.error({
        message: "Error joining team",
        description: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSelectTeam = (teamId) => {
    setSelectedTeam(teamId);
    localStorage.setItem("teamId", teamId);
    window.location.href = "/";
  };

  return (
    <div
      style={{
        display: "flex",

        flexDirection: "column",
        padding: "20px",
      }}
    >
      {inviteOnly ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px",
            width: 300,
            margin: "auto",
            textAlign: "center",
            border: "1px solid #eaeaea",
            borderRadius: 8,
            backgroundColor: "white",
          }}
        >
          <AlertFilled style={{ fontSize: "44px", color: "red" }} />
          <h1>Sorry Invite Only</h1>
          <p>
            We are currently in invite only mode.
            <br /> <br />
            Please wait for an invite from a team member.
          </p>
        </div>
      ) : (
        <>
          <Form
            onFinish={handleCreateTeam}
            layout="vertical"
            style={{ marginBottom: 20 }}
          >
            <Form.Item
              name="name"
              label="Team Name"
              rules={[
                { required: true, message: "Please enter the team name" },
              ]}
            >
              <Input placeholder="Enter team name" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Create Team
              </Button>
            </Form.Item>
          </Form>
          {teams.length > 0 && <h2>Your Teams</h2>}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
            }}
          >
            {teams.length > 0 &&
              teams.map((team) => (
                <Card
                  style={{
                    width: 200,
                    marginBottom: "10px",
                  }}
                  onClick={() => handleSelectTeam(team.teamId)}
                >
                  <div
                    style={{
                      flex: 1,
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "fit-content",
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginBottom: "10px",
                      }}
                    >
                      <TeamOutlined style={{ fontSize: "44px" }} />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      {team.name}
                    </div>
                  </div>
                </Card>
              ))}
          </div>
          {invites.length > 0 && (
            <>
              <h2>Pending Invites</h2>
              <List
                itemLayout="horizontal"
                dataSource={invites}
                renderItem={(invite) => (
                  <List.Item>
                    <List.Item.Meta
                      title={invite.name}
                      description="You have been invited to join this team"
                    />
                    <Button
                      type="primary"
                      onClick={() => handleJoinTeam({ teamId: invite.teamId })}
                    >
                      Accept Invite
                    </Button>
                  </List.Item>
                )}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default CreateTeam;
